import * as React from "react";

const SvgBigPlus: React.FunctionComponent<{}> = props =>
	<svg width="38" height="38" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="30.5" cy="30.5" r="30.5" fill="var(--color-main)" />
		<line x1="30.5" y1="21" x2="30.5" y2="40" stroke="var(--bg-main)" strokeWidth="3" />
		<line x1="21" y1="30.5" x2="40" y2="30.5" stroke="var(--bg-main)" strokeWidth="3" />
	</svg>



export default SvgBigPlus;