import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import { niceNumber, niceSignedNumber } from '/src/utils/numbers';
import ActivitiesNotifications from './ActivitiesNotifications'
import DesktopNavigationBar from './DesktopNavigationBar'
import { Slide, ToastContainer } from "react-toastify/dist";
import MobileNavigationBar from "./MobileNavigationBar";
import DashboardHeader from "./DashboardHeader";
import AccountsService, { Account } from "/src/services/accounts";
import WalletsService from "/src/services/wallets";
import StrategyService from "/src/services/strategies";
import AuthenticationService from "../services/authentication";
import { ThemeContext } from "./ThemeProvider";

interface Props {
	tab: number,
	maxWidth: any,
	history: any,
	hideHeader?: boolean,
}

const PageContainer: React.FunctionComponent<Props> = (props) => {
	//const [balance, setBalance] = React.useState<number>(undefined);
	const [invested, setInvested] = React.useState<number>(undefined);
	//const [account, setAccount] = React.useState<Account>(undefined);

	/*React.useEffect(() => {
		async function load() {
			let [walletBalance, strategies, account] = await Promise.all([
				WalletsService.getMyBalance(),
				StrategyService.getMyStrategies(),
				AccountsService.get()
			]);

			//setAccount(account)
			//setBalance(walletBalance.available)
			//setInvested(strategies.reduce((a, b) => a + (b.investmentStatement ? b.investmentStatement.stats.equity : 0), 0))
		}
		if (!props.hideHeader) {
			load();
		}
	}, []);*/

	return <ActivitiesNotifications onInvestedChanged={invested => { setInvested(invested) }}>
		<Column style={{ paddingLeft: isDesktop ? 133 : 0 /*space under the navigation bar*/ }}>
			{/*props.hideHeader !== true*/false && <DashboardHeader
				//hasAccount={account === undefined ? undefined : account === null ? false : true}
				//balance={balance}
				//invested={invested}
				hasAccount={false} balance={0} invested={0} />}
			<Row mainAxis="center">
				<Column style={{ minHeight: "100vh", maxWidth: props.maxWidth, flex: 1, position: "relative" }}>
					{props.children}

					<ToastContainer
						limit={4}
						transition={Slide}
						position="bottom-center"
						autoClose={5000}
						hideProgressBar
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover />
				</Column>
			</Row>
		</Column>
		{!AuthenticationService.hasToken() && (isDesktop
			? <DesktopNavigationBar tab={props.tab} />
			: <MobileNavigationBar tab={props.tab} />)}
	</ActivitiesNotifications>
}

export default PageContainer;