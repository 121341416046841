import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import { withRouter } from 'react-router-dom'
import PageContainer from '../../components/PageContainer'
import TabMenu, { Tab } from "/src/components/TabMenu";
import GeneralTab from "./GeneralTab";
import LegalInfoTab from "./LegalInfoTab";
import MyBanksTab from "./MyBanksTab";
import SettingsTab from "./SettingsTab";

interface Props {
	history: any,
	tab: number
}

interface State {
	nickname: string,
	email: string,
	bio: string,
}


class EditProfilePage extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = { nickname: undefined, email: undefined, bio: undefined };
	}

	render() {
		document.title = "Profile " + String.fromCharCode(183) + " Cicada";

		return <PageContainer maxWidth={800} history={this.props.history} tab={2} hideHeader={true}>
			<Row mainAxis="center">
				<Column style={{ maxWidth: 800, flex: 1, position: "relative", minWidth: 350 }}>
					<TabMenu defaultTab={this.props.tab} width={130}>
						<Tab nameKey="PROFILE" url="/profile/general">
							<GeneralTab history={this.props.history} />
						</Tab>
						<Tab nameKey="SETTINGS" url="/profile/settings">
							<SettingsTab history={this.props.history} />
						</Tab>
						{/*<Tab nameKey="LEGAL INFO" url="/profile/legal">
							<LegalInfoTab />
						</Tab>
						<Tab nameKey="MY BANKS" url="/profile/banks">
							<MyBanksTab />
						</Tab>*/}
					</TabMenu>
				</Column>
			</Row >
		</PageContainer>
	}
}


export default withRouter(EditProfilePage);