import * as React from "react";

// keeps cursor in place even when handleChange changes the value
const ControlledInput = (props) => {
	const { value, onChange, ...rest } = props;
	const [cursor, setCursor] = React.useState(null);
	const ref = React.useRef(null);
 
	React.useEffect(() => {
	   const input = ref.current;
	   if (input) input.setSelectionRange(cursor, cursor);
	}, [ref, cursor, value]);
 
	const handleChange = (e) => {
	   setCursor(e.target.selectionStart);
	   onChange && onChange(e);
	};
 
	return <input ref={ref} value={value} onChange={handleChange} {...rest} />;
 };
 
 export default ControlledInput;