import rp from 'request-promise-native';
import { PublicUserModel } from '/src/services/users'
import { serverUrl } from '/src/services/common'
import { Result } from '/src/utils/result';
import { DeviceTokenWrapper, EntranceCodeRequest, JwtWrapper } from '/generated/cicada-client';
import { generateString } from '/src/utils/random'

console.log({ serverUrl })

let jwtObject: JwtWrapper = JSON.parse(localStorage.getItem('jwtObject'));
let userId = localStorage.getItem('userId');
let token = localStorage.getItem('token');

let deviceId: string = localStorage.getItem('deviceId');
if (deviceId === null) {
	deviceId = generateString(10);
	localStorage.setItem('deviceId', deviceId)
}

export enum AuthStatus {
	Guest,
	Authorized,
	Failed
}

export interface EntranceResponse {
	deviceToken: DeviceTokenWrapper,
	wasSentToEmail: boolean,
	requestId: string
}

const AuthenticationService = {
	storeToken: (response: DeviceTokenWrapper) => {
		console.log({STORING: response})
		localStorage.setItem('userId', response.userId);
		localStorage.setItem('token', response.token);
		localStorage.setItem('jwtObject', JSON.stringify(response.jwt));
		userId = response.userId;
		token = response.token;
		jwtObject = response.jwt;
	},
	signIn: async (entranceCodeRequest: EntranceCodeRequest): Promise<EntranceResponse> => {
		const response: EntranceResponse = await rp({
			method: 'POST',
			json: true,
			uri: serverUrl + '/auth/signIn',
			body: entranceCodeRequest
		});
		if (response.deviceToken) {
			localStorage.setItem('userId', response.deviceToken.userId);
			localStorage.setItem('token', response.deviceToken.token);
			localStorage.setItem('jwtObject', JSON.stringify(response.deviceToken.jwt));
			userId = response.deviceToken.userId;
			token = response.deviceToken.token;
			jwtObject = response.deviceToken.jwt;
		}
		return response;
	},
	changeEmail: async (email): Promise<Result<String>> => {
		const response: Result<String> = await rp({
			method: 'POST',
			json: true,
			uri: serverUrl + '/auth/changeEmail',
			body: {
				email: email
			},
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},
	verifyCode: async (requestId: string, code: string): Promise<Result<DeviceTokenWrapper>> => {
		try {
			const response: Result<DeviceTokenWrapper> = await rp({
				method: 'POST',
				json: true,
				uri: serverUrl + '/auth/verifyCode',
				body: {
					requestId: requestId,
					code: code
				}
			});
			if (response.entity && response.entity.jwt) {
				localStorage.setItem('userId', response.entity.userId);
				localStorage.setItem('token', response.entity.token);
				localStorage.setItem('jwtObject', JSON.stringify(response.entity.jwt));
				userId = response.entity.userId;
				token = response.entity.token;
				jwtObject = response.entity.jwt;
			}
			return response;
		}
		catch (err) {
			return null;
		}
	},
	hasToken: () => {
		if (token === undefined || token === null || token === "") {
			return true;
		}
		return false;
	},
	isGuest: () => {
		if (jwtObject.roles.includes("Guest")) {
			return true;
		}
		return false;
	},
	currentCountryCode: () => {
		return jwtObject ? jwtObject.countryCode : undefined;
	},
	getJWT: async (): Promise<JwtWrapper> => {
		try {
			const response = await rp({
				method: 'POST',
				json: true,
				uri: serverUrl + '/auth/getjwt',
				body: { userId, token }
			});
			localStorage.setItem('jwtObject', JSON.stringify(response));
			jwtObject = response;
			return response;
		}
		catch (err) {
			if (err.error && err.error.status == 401) {
				console.log("Device token is not valid anymore")
				localStorage.removeItem("currentUser");
				localStorage.removeItem("userId");
				localStorage.removeItem("token");
				jwtObject = undefined;
				userId = undefined;
				token = undefined;
			}
			return null;
		}
	},
	logIn: (user) => {
		localStorage.setItem('jwtObject', JSON.stringify(user));
		jwtObject = user;
	},
	logOut: async () => {
		try {
			await rp({
				method: 'POST',
				json: true,
				uri: serverUrl + '/auth/logout',
				headers: await AuthenticationService.getAuthHeader(),
				body: { userId, token }
			});
		}
		catch (err) {
		}
		localStorage.removeItem('jwtObject');
		localStorage.removeItem('userId');
		localStorage.removeItem('token');
		jwtObject = undefined;
		userId = undefined;
		token = undefined;
	},
	getDeviceId: (): string => {
		return deviceId;
	},
	isJwtOk: async (): Promise<AuthStatus> => {
		if (jwtObject && (jwtObject.expiresAt > (Date.now() + 10000)) && jwtObject.jwt) {
			return AuthStatus.Authorized;
		} else {
			if (token) {
				if (await AuthenticationService.getJWT()) {
					return AuthStatus.Authorized;
				}
				else {
					return AuthStatus.Failed;
				}
			}
		}
		return AuthStatus.Guest;
	},
	getAuthHeader: async () => {
		if (jwtObject && (jwtObject.expiresAt > (Date.now() + 10000)) && jwtObject.jwt) {
			return { Authorization: `Bearer ${jwtObject.jwt}` };
		} else {
			if (token) {
				const response = await AuthenticationService.getJWT();
				if (response === null) {
					window.location.href = '/entrance';
				}
				return { Authorization: `Bearer ${jwtObject.jwt}` };
			}
			else {
				window.location.href = '/entrance';
				return {};
			}
		}
	},
	getAuthHeaderValue: async () => {
		if (jwtObject && (jwtObject.expiresAt > (Date.now() + 2000)) && jwtObject.jwt) {
			return jwtObject.jwt;
		} else {
			if (token) {
				const response = await AuthenticationService.getJWT();
				if (response === null) {
					window.location.href = '/entrance';
				}
				return jwtObject.jwt;
			}
			else {
				window.location.href = '/entrance';
				return "";
			}
		}
	},
	getCurrentUserId: () => {
		if (jwtObject && jwtObject.userId) {
			return jwtObject.userId
		} else {
			return undefined;
		}
	},
	getSseCode: async (): Promise<String> => {
		const response: String = await rp({
			method: 'POST',
			json: true,
			uri: serverUrl + '/auth/sse-code',
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	}
}

export default AuthenticationService;