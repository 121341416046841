import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import { withRouter } from 'react-router-dom'
import PageContainer from "/src/components/PageContainer";

interface Props {
	history: any
}

const DisclosuresPage: React.FunctionComponent<Props> = (props) => {
	document.title = "Wallet " + String.fromCharCode(183) + " Cicada";

	return <PageContainer maxWidth={800} history={props.history} tab={-1} hideHeader={true}>
		<Row mainAxis="center">
			<Column className="font16" style={{ padding: "0px 4px", maxWidth: 800, flex: 1, position: "relative", minWidth: 350 }}>

				<h2 className="mtop50">Disclosures of Cicada</h2>
				<a className="mtop10" href="https://content.cicada.cloud/our-privacy-policy">Privacy Notice</a>

				<h2 className="mtop50">Disclosures of Alpaca</h2>
				<a className="mtop10" href="https://files.alpaca.markets/disclosures/library/UseAndRisk.pdf">Use and Risk</a>
				<a className="mtop10" href="https://files.alpaca.markets/disclosures/library/TermsAndConditions.pdf">Terms and Conditions</a>
				<a className="mtop10" href="https://files.alpaca.markets/disclosures/library/AlpacaSecuritiesPrivacyNotice.pdf">Privacy Notice</a>
				<a className="mtop10" href="https://files.alpaca.markets/disclosures/library/PFOF.pdf">PFOF</a>
				<a className="mtop10" href="https://files.alpaca.markets/disclosures/library/MarginDiscStmt.pdf">Margin Disclosure Statement</a>
				<a className="mtop10" href="https://files.alpaca.markets/disclosures/library/ExtHrsRisk.pdf">Extended Hours Trading Risk</a>
				<a className="mtop10" href="https://files.alpaca.markets/disclosures/library/SecuritiesBCPSummary.pdf">Business Continuity Plan Summary</a>
				<a className="mtop10" href="https://files.alpaca.markets/disclosures/library/FormCRS.pdf">Form CRS</a>

				<h2 className="mtop50">Disclosures of Alpaca Crypto</h2>
				<a className="mtop10" href="https://files.alpaca.markets/disclosures/library/CryptoRiskDisclosures.pdf">Alpaca Crypto Risk Disclosure</a>
				<a className="mtop10" href="https://files.alpaca.markets/disclosures/library/PrivacyNotice.pdf">Privacy Notice</a>
				<a className="mtop10" href="https://files.alpaca.markets/disclosures/library/CryptoBCPSummary.pdf">Alpaca Crypto Business Continuity Plan Summary</a>
				<a className="mtop10" href="https://files.alpaca.markets/disclosures/library/Alpaca+Crypto+Custodial+Account+Disclosure+Statement.pdf">Alpaca Crypto Custodial Account Disclosure Statement</a>
				<a className="mtop10" href="https://files.alpaca.markets/disclosures/library/FTXFinancialInformationPrivacyNotice.pdf">FTX Financial Information Privacy Notice</a>
				<a className="mtop10" href="https://files.alpaca.markets/disclosures/library/TermsAndConditions.pdf">Alpaca Terms & Conditions</a>
				<a className="mtop10" href="https://files.alpaca.markets/disclosures/library/MarketingCampaignTC.pdf">Alpaca Crypto Rewards Program Terms & Conditions</a>

			</Column>
		</Row >
	</PageContainer>
}


export default withRouter(DisclosuresPage);