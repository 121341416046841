import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import PositionsHeader from './PositionsHeader'
import candlesticks from '/src/assets/candlesticks.svg';
import indicator from '/src/assets/indicator.svg';
import dollar from '/src/assets/dollar.svg';
import { FormattedMessage } from "react-intl";
import PositionWidget from "./PositionWidget";
import Loader from "/src/components/Loader";
import PositionRow from "./PositionRow";
import { Position, PositionStatus } from "/generated/cicada-client";

interface Props {
	positions: Position[],
	morePositions: boolean,
	onTradeSelected: (trade: Position) => void,
	isDemoDeals: boolean
}

const Positions: React.FunctionComponent<Props> = (p) => {
	const openDeals = p.positions && p.positions.filter(d => d.status == PositionStatus.Opening || d.status == PositionStatus.Open);
	const closedDeals = p.positions && p.positions.filter(d => d.status != PositionStatus.Opening && d.status != PositionStatus.Open);

	return <Column style={{ width: "100%" }}>
		<Row crossAxis="center" className="font14 bold mbottom5"
			style={{ height: 25, marginTop: 50 }}>
			{p.isDemoDeals ? <FormattedMessage id="strategy.demo_positions" />
				: <FormattedMessage id="strategy.positions" />}
		</Row>

		{openDeals && openDeals.length === 0 &&
			<Row mainAxis="center" crossAxis="center" style={{ height: 50 }} className="font14">no open positions now</Row>}

		{openDeals && openDeals.map((d, i) => {
			return <PositionRow key={d.id} position={d} isFirst={i == 0} onSelected={p.onTradeSelected} />
		})}

		<Row  crossAxis="center" className="font14 bold mbottom5"
			style={{ height: 25, marginTop: 20 }}>
			{p.isDemoDeals ? <FormattedMessage id="strategy.demo_history" />
				: <FormattedMessage id="strategy.history" />}
		</Row>

		{closedDeals && closedDeals.map((d, i) => {
			return <PositionRow key={d.id} position={d} isFirst={i == 0} onSelected={p.onTradeSelected} />
		})}

		{p.morePositions && <Loader />}
	</Column>
}



export default Positions;