import * as React from "react";
import { Redirect, Route } from "react-router";
import AuthenticationService, {AuthStatus} from '/src/services/authentication'

export enum AuthRequirement {
	Guest,
	User,
	Any
}

interface Props {
	type: AuthRequirement,
	path: string,
	component: any
}

interface State {
	authStatus: AuthStatus
}

class AuthRoute extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = { authStatus: undefined };
	}

	async componentDidMount() {
		const authStatus = await AuthenticationService.isJwtOk();
		this.setState({ authStatus: authStatus })
	}

	render() {
		if (this.state.authStatus === undefined) {
			return <div></div>
		}
		if (this.props.type === AuthRequirement.Guest && this.state.authStatus == AuthStatus.Authorized) {
			return <Redirect to="/dashboard" />
		}
		if (this.props.type === AuthRequirement.User && this.state.authStatus == AuthStatus.Guest) {

			return <Redirect to="/entrance" />
		}
		if (this.props.type === AuthRequirement.Any && this.state.authStatus == AuthStatus.Failed) {
			return <Redirect to="/entrance" />
		}

		return <Route {...this.props} />
	}
}
export default AuthRoute;