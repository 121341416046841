import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import StrategyThumbnailChart from './StrategyThumbnailChart'
import { niceNumber, niceSignedNumber } from '/src/utils/numbers';
import { Link } from "react-router-dom";
import { StrategyResponse } from '/src/services/strategies'
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';

interface Props {
	data: StrategyResponse
	ownStrategy: boolean
}

const EmptyStrategy: React.FunctionComponent<Props> = props =>
	<Link to={{ pathname: "/strategy/" + props.data.strategy.id + "/rules", state: props.data }}>
		<Column className="up-border1" style={{ height: "14.4rem" }}>
			<Row crossAxis="baseline" style={{ marginTop: "0.8rem" }}>
				<div className="font18 segoe ">{props.data.strategy.name.toUpperCase()}</div>
				<div className="flex1"></div>
			</Row>
			<Row crossAxis="baseline">
				<div className="font13">{props.data.author.nickname}</div>
				<div className="flex1"></div>
			</Row>
			<Row className="flex1">
				<Column style={{ width: "13rem" }}>
					<div className="flex1 top3"></div>
					<Row>{props.data.strategy.tags && props.data.strategy.tags.map((t, i) => {
						return <div key={props.data.strategy.id + t + i} className="font14 segoe italic lightgray right3" style={{ padding: "0.2rem" }}>#{t}</div>;
					})}</Row>
				</Column>
				<Row mainAxis="center" crossAxis="center" className="font13 corner-gradient"
					style={{ flex: 2, marginTop: "1.3rem", height: "6.6rem", textAlign: "center" }}>

					<FormattedMessage id="strategy.has_not_been_tested" />

				</Row>

			</Row>
		</Column >
	</Link>



export default EmptyStrategy;