import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import StrategyService, { ChartTimeItem, StrategyState, StrategyStatement, StrategyInfo, SignalDirection, StrategySse, TestPhase, TestStatus, CandleArray } from '/src/services/strategies'
import { IndicatorsCache } from '/src/services/indicatorsCache'
import { InstrumentsCache } from '/src/services/instrumentsCache'
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import { PublicUserModel } from "/src/services/users";
import AuthenticationService from '/src/services/authentication';
import WalletsService, { Transaction } from '/src/services/wallets'
import { toast } from "react-toastify/dist";
import { guessValuesPrecision, niceNumber, niceSignedNumber } from "/src/utils/numbers";
import ActivitiesNotifications from "/src/components/ActivitiesNotifications";
import { IndicatorToken, IToken, LinesToTokens, StrategyTab, TokensToLines, TokenType, TradeRuleToken } from "./EditorModels";
import StrategyEditor from "./StrategyEditor";
import { Position, PositionStats, PositionStatus } from "/generated/cicada-client";

import Api, { ErrorCode } from "/generated/cicada-client";
import { serverUrl } from '/src/services/common'
const api = new Api(serverUrl, undefined, async () => { return await AuthenticationService.getAuthHeaderValue(); });

interface Props extends RouteComponentProps {
	intl: any,
	history: any;
	strategyId: string,
	tab: StrategyTab
}

interface State {
	author: PublicUserModel,
	strategy: StrategyInfo,
	demoStatement?: StrategyStatement,
	investmentStatement?: StrategyStatement,
	tokens: IToken[],
	charts: ChartTimeItem[],
	positions: Position[],
	morePositions: boolean,
	hasTradeRules: boolean,
	hasInstruments: boolean,
	backtestStatus: TestStatus,
	transactions: Transaction[],
	dealStats: PositionStats
}


class StrategyPage extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			author: undefined,
			strategy: undefined,
			tokens: [],
			charts: [],
			positions: [],
			morePositions: false,
			hasTradeRules: false,
			hasInstruments: false,
			backtestStatus: undefined,
			transactions: [],
			dealStats: undefined,
		}
	}

	timer: number

	preparePositionsForCharts = (charts: ChartTimeItem[], positions: Position[]) => {
		if (!positions) {
			return;
		}
		const positionsOfRule = positions.filter(d => d.ruleId !== undefined); //todo real condition filter by ruleid
		let previousRecord: ChartTimeItem = undefined;
		let previousTime = 0;

		if (positionsOfRule.length > 0 && charts.length > 0) {
			charts.forEach((currentRecord) => {
				positionsOfRule.forEach(deal => {
					if (previousRecord && new Date(deal.openTime).valueOf() >= previousTime && new Date(deal.openTime).valueOf() < currentRecord.timestamp && (deal.status != PositionStatus.Opening && deal.status != PositionStatus.Rejected)) {
						(previousRecord.openDeals = previousRecord.openDeals || []).push(deal);
					}
					if (previousRecord && new Date(deal.openingTime).valueOf() >= previousTime && new Date(deal.openingTime).valueOf() < currentRecord.timestamp && (deal.status === PositionStatus.Opening || deal.status === PositionStatus.Rejected)) {
						(previousRecord.openDeals = previousRecord.openDeals || []).push(deal);
					}
					if (previousRecord && new Date(deal.closeTime).valueOf() >= previousTime && new Date(deal.closeTime).valueOf() < currentRecord.timestamp) {
						(previousRecord.closeDeals = previousRecord.closeDeals || []).push(deal);
					}
				});
				previousTime = currentRecord.timestamp;
				previousRecord = currentRecord;
				//previousRecord.push({});
			});
		}
	}

	async componentDidMount() {
		const givenKey = new URLSearchParams(this.props.location.search).get("key");

		const now = new Date().getTime();

		let [strategyInfo, dealsStats, transactions, code, values, _1, _2] = await Promise.all([
			StrategyService.getStrategyInfo(this.props.strategyId),
			api.trading.getBucketStats(this.props.strategyId, "demo"),
			WalletsService.findTransactions(now + 10000, 100, this.props.strategyId),
			StrategyService.getStrategyCode(this.props.strategyId, givenKey),
			StrategyService.getHistory(this.props.strategyId, "0"),
			IndicatorsCache.initialize(),
			InstrumentsCache.initialize()
		]);
		const ownStrategy = strategyInfo && strategyInfo.strategy && AuthenticationService.getCurrentUserId() === strategyInfo.author.id;
		const dealsOwner = strategyInfo && strategyInfo.investmentStatement ? AuthenticationService.getCurrentUserId() : "demo";
		let [currentStats, closedDeals,] = await Promise.all([
			api.trading.findOpenDeals(this.props.strategyId, dealsOwner),
			api.trading.findClosedDeals(this.props.strategyId, dealsOwner, new Date().getTime() + 1000, 20),
		])
		const allDeals = currentStats.openDeals.concat(closedDeals);

		values && this.preparePositionsForCharts(values, allDeals);


		const precisions = this.guessPrecisions(values);


		transactions.sort((a, b) => new Date(a.time).valueOf() - new Date(b.time).valueOf());

		const tokens = code !== undefined ? LinesToTokens(code.codeLines) : [];

		tokens.forEach(t => { if (precisions[t.dataIndex]) { t.precision = precisions[t.dataIndex] } });

		this.setState({
			transactions: transactions,
			positions: allDeals,
			morePositions: closedDeals.length === 20,
			charts: values,
			author: strategyInfo.author,
			strategy: strategyInfo.strategy,
			demoStatement: strategyInfo.demoStatement,
			investmentStatement: strategyInfo.investmentStatement,
			dealStats: dealsStats,
			tokens: tokens,
			hasTradeRules: (code !== undefined ? tokens.some(t => t.type == TokenType.TradeRule) : false),
			hasInstruments: (code !== undefined ? tokens.some(t => t.type == TokenType.Instrument) : false),
		})

		ownStrategy && this.resubscribeSse();
	}

	stopSse = () => {
		if (this.sseHandler) {
			this.sseHandler.close();
			this.sseHandler = undefined;
		}
	}

	appendChart = (new_charts: ChartTimeItem[], new_record: ChartTimeItem): void => {

		const last_existing = new_charts[new_charts.length - 1].timestamp;

		if (last_existing < new_record.timestamp) {
			const empty: ChartTimeItem = { timestamp: new_record.timestamp, values: [], openDeals: undefined, closeDeals: undefined };
			new_charts.push(empty);
		}
		else if (last_existing > new_record.timestamp) {
			//too old update
			console.log(new_record.timestamp + " < " + last_existing + "!!!");
		}

		const current = new_charts[new_charts.length - 1];

		new_record.values.forEach((ind, index) => {
			if (ind.length < 5) {
				current.values[index] = ind;
			}
			else {

				for (let cc = new_charts.length - 1; cc >= 0; cc--) {
					if (new_charts[cc].timestamp === ind[4]) {
						new_charts[cc].values[index] = ind;
						break;
					}
				}
				current.values[index] = [];
			}
		})

	}

	resubscribeSse = async () => {
		this.stopSse();
		this.sseHandler = await StrategyService.getStrategyUpdates(this.props.strategyId, new URLSearchParams(this.props.location.search).get("key"), async (s) => {

			const event: StrategySse = JSON.parse(s);
			if (event.history) {
				let new_charts = this.state.charts.slice();
				event.history.forEach(element => {
					this.appendChart(new_charts, element);
				});
				this.setState({ charts: new_charts });
			}
			if (event.detailedEquity) {
				const newPositions = this.state.positions.slice();
				event.detailedEquity.profitUpdates.forEach(pp => {
					const existingPositionIndex = newPositions.findIndex(p => p.id === pp.id);
					if (existingPositionIndex >= 0) {
						newPositions[existingPositionIndex].closePrice = pp.closePrice;
						newPositions[existingPositionIndex].profit = pp.profit;
					}
				})
				this.setState({ positions: newPositions })
			}
			if (event.demoPosition) {
				const position = event.demoPosition;
				const strategyInfo = await StrategyService.getStrategyInfo(position.strategyId);

				if (!strategyInfo.investmentStatement) { //demo trades shown now
					const newPositions = this.state.positions.slice();
					const existingPositionIndex = newPositions.findIndex(p => p.id === position.id);
					if (existingPositionIndex >= 0) {
						newPositions[existingPositionIndex] = position;
					}
					else {
						newPositions.unshift(position);
					}
					this.setState({ positions: newPositions })
				}

				if (position.status == PositionStatus.Open) {
					const text = this.props.intl.formatMessage({ id: "notification.demoPositionOpened" },
						{ strategyName: strategyInfo.strategy.name, volume: niceNumber(position.volume, 3), symbol: position.symbol, price: position.openPrice });
					toast(text, { theme: "dark" })
				}
				if (position.status == PositionStatus.Closed) {
					const text = this.props.intl.formatMessage({ id: "notification.demoPositionClosed" },
						{
							strategyName: strategyInfo.strategy.name, volume: niceNumber(position.volume, 3),
							symbol: position.symbol, price: position.closePrice, profit: niceSignedNumber(position.profit, 2), currency: position.instrumentCurrency
						});
					toast(text, { theme: "dark" })
				}
			}
			if (event.testStatus) {

				if (event.testStatus.phase == TestPhase.Finished) {
					let [strategyInfo, values, currentStats, closedPositions, dealStats, _] = await Promise.all([
						StrategyService.getStrategyInfo(this.props.strategyId),
						StrategyService.getHistory(this.props.strategyId, "0"),
						api.trading.findOpenDeals(this.props.strategyId, "demo"),
						api.trading.findClosedDeals(this.props.strategyId, "demo", new Date().getTime() + 1000, 20),
						api.trading.getBucketStats(this.props.strategyId, "demo"),
						InstrumentsCache.initialize()
					]);

					const allPositions = currentStats.openDeals.concat(closedPositions);
					this.preparePositionsForCharts(values, allPositions);
					this.setState({
						backtestStatus: undefined,
						positions: allPositions,
						morePositions: closedPositions.length === 20,
						charts: values,
						author: strategyInfo.author,
						strategy: strategyInfo.strategy,
						dealStats: dealStats,
						demoStatement: strategyInfo.demoStatement,
						investmentStatement: strategyInfo.investmentStatement
					})
					this.resubscribeSse();
					return;
				}

				this.setState({ backtestStatus: event.testStatus });
			}
		});
	}

	sseHandler: EventSource

	componentWillUnmount() {
		this.stopSse();
		clearTimeout(this.timer)
	}

	guessPrecisions = (data: ChartTimeItem[]): number[] => {
		if (!data || data.length == 0) {
			return [];
		}
		const number_of_indicators = data[0].values.length;
		const result = [];

		for (let i = 0; i < number_of_indicators; i++) {
			let probe_values = [];
			for (let step = 0; step < 3; step++) {
				const bar = data[data.length - 1 - step];
				if (bar) {
					probe_values = probe_values.concat(bar.values[i]);
				}
			}
			const precision = guessValuesPrecision(probe_values);
			result.push(precision);
		}
		//console.log({precisions: result})
		return result;
	}

	runCode = async (tokens: IToken[]) => {
		this.stopSse();
		if (tokens.length > 0) {

			this.setState({
				charts: [],
				positions: []
			});

			const { id, values, deals, equity } = await StrategyService.expressTest(this.props.strategyId, TokensToLines(tokens));

			const precisions = this.guessPrecisions(values);
			tokens.forEach(t => { if (precisions[t.dataIndex]) { t.precision = precisions[t.dataIndex] } });

			this.preparePositionsForCharts(values, deals);
			this.setState({
				strategy: { ...this.state.strategy, state: StrategyState.Created, updatedAt: new Date().toISOString() },
				charts: values,
				positions: deals,
				hasTradeRules: tokens.some(t => t.type == TokenType.TradeRule),
				hasInstruments: tokens.some(t => t.type == TokenType.Instrument),
			});

			this.resubscribeSse();
		}
		else {
			this.setState({
				strategy: { ...this.state.strategy, state: StrategyState.Created, updatedAt: new Date().toISOString() },
				charts: [],
				positions: [],
				hasTradeRules: false,
				hasInstruments: false,
			});
		}
	}

	onAdded = async (token: IToken) => {
		const new_tokens = this.state.tokens.slice();
		new_tokens.push(token);
		this.setState({ tokens: new_tokens })

		await this.runCode(new_tokens); //dataIndex and position will be updated here without setstate?
	}

	onUpdated = async (token: IToken) => {
		const new_tokens = this.state.tokens.slice();

		const old_token = new_tokens[token.position];
		if (old_token.name != token.name) {
			//rename all references
			for (var i = token.position + 1; i < new_tokens.length; i++) {
				const next_token = new_tokens[i];
				if (next_token.type === TokenType.Indicator) {
					const next_indicator = next_token as IndicatorToken;
					next_indicator.parameters.forEach((p, i) => {
						if (p === old_token.name) {
							next_indicator.parameters[i] = token.name;
						}
					})
				}
				if (next_token.type === TokenType.TradeRule) {
					const next_indicator = next_token as TradeRuleToken;
					next_indicator.openConditions.forEach((c) => {
						c.parameters.forEach((p, i) => {
							if (p === old_token.name) {
								c.parameters[i] = token.name;
							}
						})
					})
					next_indicator.closeConditions.forEach((c) => {
						c.parameters.forEach((p, i) => {
							if (p === old_token.name) {
								c.parameters[i] = token.name;
							}
						})
					})
				}
			}
		}

		new_tokens.splice(token.position, 1, token);



		this.setState({ tokens: new_tokens }) // delete specific chart values to show updating
		await this.runCode(new_tokens);
	}

	deleteLine = async (token: IToken) => {
		const new_tokens = this.state.tokens.slice();
		new_tokens.splice(token.position, 1);

		this.setState({
			tokens: new_tokens, charts: [], positions: [],
			hasTradeRules: new_tokens.some(t => t.type == TokenType.TradeRule),
			hasInstruments: new_tokens.some(t => t.type == TokenType.Instrument),
		})

		await this.runCode(new_tokens);
	}

	onMovedUp = async (token: IToken) => {
		const new_tokens = this.state.tokens.slice();

		new_tokens[token.position] = new_tokens[token.position - 1];
		new_tokens[token.position - 1] = token;

		this.setState({ tokens: new_tokens })
		await this.runCode(new_tokens); //dataIndex and position will be updated here without setstate?
	}

	onMovedDown = async (token: IToken) => {
		const new_tokens = this.state.tokens.slice();

		new_tokens[token.position] = new_tokens[token.position + 1];
		new_tokens[token.position + 1] = token;

		this.setState({ tokens: new_tokens })
		await this.runCode(new_tokens); //dataIndex and position will be updated here without setstate?
	}

	onStrategyNameChanged = async (name: string) => {
		this.setState((prevState: State) => {
			let prevStateCopy: State = Object.assign({}, prevState);
			prevStateCopy.strategy.name = name;
			return prevStateCopy;
		})

		await StrategyService.update(this.props.strategyId, { name: name });
	}

	onStrategyDescriptionChanged = async (description: string) => {
		this.setState((prevState: State) => {
			let prevStateCopy: State = Object.assign({}, prevState);
			prevStateCopy.strategy.description = description;
			return prevStateCopy;
		})

		await StrategyService.update(this.props.strategyId, { description: description });
	}

	onBacktest = async () => {
		const testStatus = await StrategyService.backtest(this.props.strategyId, TokensToLines(this.state.tokens));
		const newStrategy = { ...this.state.strategy };
		newStrategy.state = StrategyState.Backtesting;
		this.setState({ strategy: newStrategy, backtestStatus: testStatus });
	}

	onSignalForced = async (ruleIndex: number, direction: SignalDirection) => {
		const forceResult = await StrategyService.forceSignal(this.props.strategyId, { ruleId: ruleIndex, direction: direction });
		console.log({ forceResult })
	}

	onInvest = async (amount: number) => {
		const now = new Date().getTime();
		await StrategyService.allocate(this.props.strategyId, amount);
		let [transactions, strategyInfo, currentStats, closedDeals] = await Promise.all([
			WalletsService.findTransactions(now + 10000, 100, this.props.strategyId),
			StrategyService.getStrategyInfo(this.props.strategyId),
			api.trading.findOpenDeals(this.props.strategyId, AuthenticationService.getCurrentUserId()),
			api.trading.findClosedDeals(this.props.strategyId, AuthenticationService.getCurrentUserId(), new Date().getTime() + 1000, 20)
		]);
		const allDeals = currentStats.openDeals.concat(closedDeals);

		transactions.sort((a, b) => new Date(a.time).valueOf() - new Date(b.time).valueOf());

		this.setState({ transactions: transactions, strategy: strategyInfo.strategy, investmentStatement: strategyInfo.investmentStatement, positions: allDeals });
	}

	onStopInvest = async () => {
		await StrategyService.exitAllocation(this.props.strategyId);
		let [strategyInfo, currentStats, closedDeals] = await Promise.all([
			StrategyService.getStrategyInfo(this.props.strategyId),
			api.trading.findOpenDeals(this.props.strategyId, "demo"),
			api.trading.findClosedDeals(this.props.strategyId, "demo", new Date().getTime() + 1000, 20),
		]);
		const allDeals = currentStats.openDeals.concat(closedDeals);

		this.setState({ strategy: strategyInfo.strategy, investmentStatement: strategyInfo.investmentStatement, positions: allDeals });
	}

	onLoadMorePositions = async () => {
		if (this.state.positions.length == 0) {
			return; //why no positions before loading more?
		}
		const oldEarliest = this.state.positions[this.state.positions.length - 1];

		const dealsOwner = this.state.investmentStatement ? AuthenticationService.getCurrentUserId() : "demo";
		const morePositions = await api.trading.findClosedDeals(this.props.strategyId, dealsOwner, new Date(oldEarliest.openingTime).getTime(), 20)

		if (morePositions.length == 0) {
			return; //why there is no old_earliest in the result?
		}

		const earliestPositionInResult = morePositions.findIndex(p => p.id == oldEarliest.id);
		if (earliestPositionInResult < 0) {
			return; //why there is no old_earliest in the result?
		}

		const mergedPositions = this.state.positions.concat(morePositions.slice(earliestPositionInResult + 1));
		this.setState({ positions: mergedPositions, morePositions: morePositions.length == 20 })
	}

	onStrategyDelete = async () => {
		await StrategyService.delete(this.props.strategyId);
		this.props.history.replace("/dashboard")
	}

	render() {
		document.title = (this.state.strategy ? this.state.strategy.name : "Editor") + " " + String.fromCharCode(183) + " Cicada";
		const ownStrategy = this.state.strategy && AuthenticationService.getCurrentUserId() === this.state.author.id;

		return <ActivitiesNotifications onPositionUpdate={p => {
			const newPositions = this.state.positions.slice();
			const existingIndex = newPositions.findIndex(n => n.id == p.id);
			if (existingIndex === -1) {
				newPositions.unshift(p);
			}
			else {
				newPositions.splice(existingIndex, 1, p);
			}
			//todo: also update positions in charts
			this.setState({ positions: newPositions })
		}}>
			<StrategyEditor
				ownStrategy={ownStrategy}
				transactions={this.state.transactions}
				onInvest={this.onInvest}
				initialTab={this.props.tab}
				author={this.state.author}
				strategy={this.state.strategy}
				backtestStatus={this.state.backtestStatus}
				demoStatement={this.state.demoStatement}
				investmentStatement={this.state.investmentStatement}
				onStrategyNameChanged={this.onStrategyNameChanged}
				onStrategyDescriptionChanged={this.onStrategyDescriptionChanged}
				hasTradeRules={this.state.hasTradeRules}
				hasInstruments={this.state.hasInstruments}
				history={this.props.history}
				tokens={this.state.tokens}
				charts={this.state.charts}
				positions={this.state.positions}
				morePositions={this.state.morePositions}
				dealsStats={this.state.dealStats}
				onSignalForced={this.onSignalForced}
				tokenCallbacks={{
					onAdded: this.onAdded,
					onUpdated: this.onUpdated,
					onDeleted: this.deleteLine,
					onMovedUp: this.onMovedUp,
					onMovedDown: this.onMovedDown
				}}
				onBacktest={this.onBacktest}
				onStopInvest={this.onStopInvest}
				onStrategyDelete={this.onStrategyDelete}
				onLoadMorePositions={this.onLoadMorePositions}
			/>
		</ActivitiesNotifications>
	}
}

/*EditorPage.childContextTypes = {
	metaSubscribe: PropTypes.func,
	triggerMetaEvent: PropTypes.func,
};*/


export default withRouter(injectIntl(StrategyPage));