import * as React from "react";
import { ThemeContext } from "./components/ThemeProvider";

export const ThemedApps: React.FunctionComponent<{}> = props => {

	const [theme, setTheme] = React.useState<string>(document.documentElement.getAttribute('color-theme'));

	const onThemeChange = (newTheme: string) => {
		setTheme(newTheme);
	};

	const cssVarGetter = (varName: string): string => {
		const result = getComputedStyle(document.documentElement).getPropertyValue(varName);
		return result.length === 4 ? "#" + result[1] + result[1] + result[2] + result[2] + result[3] + result[3] : result;
	}

	return <ThemeContext.Provider value={{ name: theme, setTheme: onThemeChange, cssVar: cssVarGetter }}>
		{props.children}
	</ThemeContext.Provider>
}