import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { Parameter, LineModel } from "../EditorModels";
import { ParameterType } from '/src/services/strategies'
import { Listbox, Transition } from "/node_modules/@headlessui/react/dist/index";
import { FormattedMessage } from "react-intl";
import { HiChevronDown } from "react-icons/hi2";

interface Props {
	value: Parameter,
	onValueChange: (value: Parameter) => void,
	options: Parameter[],
	localizationPrefix?: string
}

interface State {
	value: Parameter,
	expanded: boolean
}

class ParameterInput extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			value: props.value,
			expanded: false
		};
		this.wrapperRef = React.createRef();
	}

	wrapperRef;

	handleClickOutside = (e) => {
		if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
			this.setState({ expanded: false })
		}
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	componentWillReceiveProps(nextProps: Props) {
		if (JSON.stringify(nextProps.options) != JSON.stringify(this.props.options) || nextProps.value != this.props.value) {
			this.setState({ value: nextProps.value, expanded: false });
		}
	}

	render() {
		return <div ref={this.wrapperRef} className="column" style={{ position: "relative" }}>

			<Listbox value={this.state.value} onChange={v => this.props.onValueChange(v)}>
				<Listbox.Button className="pointer semibold border-alt" style={{ textAlign: "left", borderRadius: 8, padding: 4 }}>
					<Row crossAxis="center" mainAxis="space-between">
						<Row className="pleft5 pright5 font16">{(Array.isArray(this.props.value) && this.props.value[0] === "Const") ? this.props.value[1] :
							this.props.localizationPrefix ?
								<FormattedMessage id={this.props.localizationPrefix + this.props.value} />
								: this.props.value
						}</Row>
						<HiChevronDown style={{ height: 10, width: 10 }} className="mright5" />
					</Row>
				</Listbox.Button>

				<Listbox.Options>
					<Column className="bor10" style={{ position: "absolute", zIndex: 10, width: 140, right: 0 }}>
						{this.props.options.map((o) =>
							<Listbox.Option
								className="pointer bg-button pleft15 hover-bg-main"
								style={{ height: 40, lineHeight: "40px" }}
								key={o.toString()}
								value={o}
								disabled={false}
							>
								{this.props.localizationPrefix ? <FormattedMessage id={this.props.localizationPrefix + o} /> : o}
							</Listbox.Option>
						)}
					</Column>
				</Listbox.Options>
			</Listbox>
		</div>
	}
}

export default ParameterInput;