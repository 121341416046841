import * as React from "react";
import { App } from "./App";
import './css/index.css'
import './css/paddings.css'
import './css/bottom-sheet.css'
import './css/date-picker.css'
import './css/calendar.css'

import { BrowserRouter as Router } from "react-router-dom";
import { IntlProvider } from "react-intl";
import messages from './messages';
import { MainRouting } from './MainRouting'
import ScrollToTop from './utils/ScrollToTop'

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { ThemeContext } from "./components/ThemeProvider";
import { ThemedApps } from "./ThemedApp";

const language = navigator.language.split(/[-_]/)[0];  // language without region code


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = window.location.href.indexOf("cicada.cloud") >= 0 ? {
	apiKey: "AIzaSyB6IoO4bZkserqq8xRk7un3_OljV3a6cNk",
	authDomain: "cicada-24a23.firebaseapp.com",
	projectId: "cicada-24a23",
	storageBucket: "cicada-24a23.appspot.com",
	messagingSenderId: "701465946772",
	appId: "1:701465946772:web:760dc9d82cc0bf2852a281",
	measurementId: "G-MEK0ZVC2YT"
} : {
	apiKey: "AIzaSyCjxAzid76YZUdDJ33_uHYckHwFQNqxP3E",
	authDomain: "cicadatest-afdb9.firebaseapp.com",
	projectId: "cicadatest-afdb9",
	storageBucket: "cicadatest-afdb9.appspot.com",
	messagingSenderId: "315241026099",
	appId: "1:315241026099:web:540f1edd2cb31bbd7b4946",
	measurementId: "G-H56ST3PB0L"
}

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export interface IMainProps {
	app: App;
}


export const Main: React.FunctionComponent<IMainProps> = props => {
	return (
		<IntlProvider locale={language} messages={messages[language]}>
			<ThemedApps>
				<Router>
					<ScrollToTop />
					<MainRouting />
				</Router>
			</ThemedApps>
		</IntlProvider>)
}