import * as React from "react";
import { ChartCanvas, Chart, series } from "react-stockcharts";
import { AreaSeries, LineSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { discontinuousTimeScaleProviderBuilder } from "react-stockcharts/lib/scale";
import { first, last } from "react-stockcharts/lib/utils";
import { fitWidth } from "react-stockcharts/lib/helper";
import { CrossHairCursor, EdgeIndicator, CurrentCoordinate, MouseCoordinateX, MouseCoordinateY } from "react-stockcharts/lib/coordinates";

export interface TimeValueInvested {
	timestamp: number,
	value: number,
	invested: number
}

class InvestmentResultChart extends React.Component<{ type: any, width: any, height: any, ratio: any, data: TimeValueInvested[] }> {
	render() {
		const { type, width, ratio, height } = this.props;
		const xScaleProvider = discontinuousTimeScaleProviderBuilder()
			.inputDateAccessor(d => new Date(d.timestamp));
		const {
			data,
			xScale,
			xAccessor,
			displayXAccessor,
		} = xScaleProvider(this.props.data);

		const start = xAccessor(last(data));
		const end = xAccessor(first(data));

		const xExtents = [start, end];
		const minmax = this.props.data.reduce((a, b) => { return { min: Math.min(a.min, b.value, b.invested), max: Math.max(a.max, b.value, b.invested) } }, { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER });
		const diff = (minmax.max - minmax.min);
		const padding = diff !== 0 ? diff * 0.05 : 1;

		return <div style={{ height: height }}>

			<ChartCanvas metaId={1} ratio={ratio} width={width} height={height}
				type="svg"
				zoomEvent={false}
				useCrossHairStyleCursor={false}
				disableInteraction={true}
				margin={{ left: 0, right: 50, top: 0, bottom: 20 }}
				seriesName="MSFT"
				xScale={xScale}
				xAccessor={xAccessor}
				displayXAccessor={displayXAccessor}
				xExtents={xExtents}
				data={data}>
				<Chart id={0} yExtents={[minmax.min - padding, minmax.max + padding]} padding={2}>
					<XAxis
						zoomEnabled={false}
						axisAt="bottom"
						orient="bottom"
						fontFamily="Open Sans"
						fontSize={13}
						ticks={4} innerTickSize={-1000} tickStrokeOpacity={0.1}
						stroke="#979797"
						tickStroke="#555555" />
					<YAxis
						zoomEnabled={false}
						axisAt="right"
						orient="right"
						// tickInterval={5}
						// tickValues={[40, 60]}
						ticks={3} innerTickSize={-1000} tickStrokeOpacity={0.1}
						fontFamily="Open Sans"
						fontSize={13}
						stroke="#979797"
						tickStroke="#555555"
					/>
					<AreaSeries
						yAccessor={d => d.value}
						strokeWidth={3}
						stroke="#505865"
						fill="#9EA6B2"
					/>
					<LineSeries
						yAccessor={d => d.invested}
						strokeWidth={1}
						stroke="#505865"
						style={{ strokeDasharray: "3 3" }}
					/>
				</Chart>
			</ChartCanvas>


		</div>
	}
}


const InvestmentResultChartSized = fitWidth(InvestmentResultChart);
export default InvestmentResultChartSized;