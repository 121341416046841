import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { withRouter } from 'react-router-dom'
import PageContainer from '../components/PageContainer'
import { FormattedMessage, FormattedDate } from 'react-intl';
import { Link } from "react-router-dom";
import Back from '/src/components/Back'
import { Slide, toast, ToastContainer } from "react-toastify/dist";
import Loader from "/src/components/Loader";
import Button from "/src/components/Button";
import Api from "/generated/cicada-client";
import AuthenticationService from "../services/authentication";
import { serverUrl } from '/src/services/common'

const api = new Api(serverUrl, undefined, async () => { return await AuthenticationService.getAuthHeaderValue(); });

interface Props {
	history: any
}

interface State {
	amount: string,
}

class DepositPage extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { amount: "" };
	}

	async componentDidMount() {
	}
	render() {
		document.title = "Wallet " + String.fromCharCode(183) + " Cicada";

		return <PageContainer maxWidth={700} history={this.props.history} tab={1}>

			<Column crossAxis="center" className="top50 font16">
			<FormattedMessage id="entrance.enter_email" />
			</Column>

			<ToastContainer
				limit={4}
				transition={Slide}
				position="bottom-center"
				autoClose={5000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover />
		</PageContainer>
	}
}


export default withRouter(DepositPage);