import rp from 'request-promise-native';
import { serverUrl } from '/src/services/common'
import AuthenticationService from '/src/services/authentication';
import ReconnectingEventSource from "/src/utils/ReconnectingEventSource";
import { Position } from '/generated/cicada-client';

export enum AllocationStatus {
	Active = 'Active',
	Exiting = 'Exiting',
	Stopped = 'Stopped'
}

export type RejectionReason = "None" | "NotEnoughFunds" | "MinVolume" | "CloseOnly" | "BuyOnly" | "TradingDisabled" | "NotAllowed"

export interface Ticker {
	exchange: string,
	symbol: string
}

export interface RelativeProfitBucket {
	bucket: number,
	count: number
}

export interface AllocationStats {
	balance: number,
	profit: number,
	equity: number,
	margin: number,
	marginLevel: number,
	netAllocated: number,
	firstAllocation: number
	status: AllocationStatus
}

export interface CurrentStats {
	openDeals: Position[],
	allocationStats: AllocationStats
}
