import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { DataSource, EditingCallbacks, IToken, InstrumentToken, MovingCallbacks, Parameter, Timeframe } from "../EditorModels";
import ParameterInput from "../Panel/ParameterInput";

interface Props {
	instrument: InstrumentToken,
	editingCallbacks: EditingCallbacks,
	movingCallbacks: MovingCallbacks,
}

const InstrumentEditingLine: React.FunctionComponent<Props> = props => {


	return <Row crossAxis="center" style={{ flexWrap: "wrap" }} mainAxis="flex-end">
		<Row crossAxis="center" className="mleft5">
			<button className="pointer semibold bg-button font16 pleft9 pright9"
				onClick={() => props.editingCallbacks.onInstrumentEdit(props.instrument)}
				style={{ textAlign: "left", borderRadius: 8, height: 27 }}>
				{props.instrument.symbol}
			</button>

			{/*InstrumentsCache.getInstrument(props.instrument.symbol).description.toUpperCase()*/}
		</Row>
		<Row crossAxis="center" className="mleft5">
			<ParameterInput
				localizationPrefix="editor.datasource."
				options={Object.keys(DataSource).map(k => DataSource[k])}
				value={props.instrument.source}
				onValueChange={(v: DataSource) => {
					const updatedInstrument: InstrumentToken = { ...props.instrument };
					updatedInstrument.source = v;
					props.editingCallbacks.onInstrumentUpdated(updatedInstrument);
				}} />
		</Row>
		<Row crossAxis="center" className="mleft5">
			<ParameterInput
				localizationPrefix="editor.timeframe."
				options={Object.keys(Timeframe).map(k => Timeframe[k])}
				value={props.instrument.timeframe}
				onValueChange={(v: Timeframe) => {
					const updatedInstrument: InstrumentToken = { ...props.instrument };
					updatedInstrument.timeframe = v;
					props.editingCallbacks.onInstrumentUpdated(updatedInstrument);
				}} />
		</Row>
	</Row>
}

export default InstrumentEditingLine;