import * as React from "react";
import { ValidatedField } from "../../pages/profile/LegalSections/AllLegalData";
import { Column, Row } from '/src/components/Layout'

interface Props {
	value: ValidatedField<string>,
	onValueChange: (value: string) => void,
	label: string,
	className?: string,
	disabled?: boolean
}



const FileInput: React.FunctionComponent<Props> = ({ value, onValueChange, label, className, disabled }) => {

	const handleChange = (event) => {
		let reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		reader.onload = function () {
			console.log((reader.result as string).substring(0, 50) + "...");
			onValueChange(reader.result as string);
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}

	return <Column className={className} >
		<Row>
			<label htmlFor="photo_back">{label}</label>
			<input className="mleft20" type="file" id="photo_back" name="filename" accept="application/pdf, image/png, image/jpeg" onChange={handleChange} />
		</Row>
		{!disabled && !value.hideError && value.error && <Row mainAxis="flex-end" className="font11 italic semibold error-blink">{value.error}</Row>}
	</Column>
}


export default FileInput;