import * as React from "react";
import { initField, setField } from "./LegalSections/AllLegalData";
import Button from "/src/components/Button";
import TextInput from "/src/components/CreateAccountPage/TextInput";
import { Column, Row } from '/src/components/Layout'
import AuthenticationService from "/src/services/authentication";
import UsersService from "/src/services/users";
import { Result } from "/src/utils/result";

interface Props {
	history: any
}

const GeneralTab: React.FunctionComponent<Props> = (props) => {
	const [email, setEmail] = React.useState(initField());
	const [initialEmail, setInitialEmail] = React.useState(undefined);
	const [changedToEmail, setChangedToEmail] = React.useState<Result<String>>(undefined);
	const [nickname, setNickname] = React.useState(initField());
	const [bio, setBio] = React.useState(initField());

	React.useEffect(() => {
		async function load() {
			const currentUserId = AuthenticationService.getCurrentUserId();
			let [userInfo] = await Promise.all([UsersService.getUserInfo(currentUserId)]);

			setInitialEmail(userInfo.email);
			setEmail(setField(userInfo.email));
			setNickname(setField(userInfo.nickname));
			setBio(setField(""));
		}
		if (email.value === undefined) {
			load();
		}
	}, []);

	return <Column className="top50">
		<Row mainAxis="center">
			<Column className="mtop20 flex1" style={{ maxWidth: 400 }}>
				<Row>
					{email !== undefined && <TextInput label="Email" value={email} onValueChange={value => {
						setChangedToEmail(undefined);
						setEmail(setField(value))
					}} />}
				</Row>
				{
					changedToEmail !== undefined ?
						changedToEmail.error ?
							<Row>{changedToEmail.error}</Row> :
							<Row>An approval request has been sent to {changedToEmail.entity}</Row> :
						email.value !== initialEmail && <Row>
							<Button label="CANCEL" onClick={async () => {
								setEmail(setField(initialEmail))
							}} />
							<Button className="font11" label="CHANGE EMAIL" onClick={async () => {
								const result = await AuthenticationService.changeEmail(email.value);
								setChangedToEmail(result);
							}} />
						</Row>
				}
				{nickname !== undefined && <TextInput label="Nickname" className="mtop50" value={nickname} onValueChange={value => { setNickname(setField(value)) }} />}
				{bio !== undefined && <TextInput label="Bio" className="mtop10" value={bio} onValueChange={value => { setBio(setField(value)) }} />}
			</Column>
		</Row>
		<Row mainAxis="center" className="font18 bold mtop50">ACTIONS</Row>
		<Row mainAxis="center" >
			<Column mainAxis="center" crossAxis="center" className="pointer segoe bold top10 border"
				onClick={async (e) => {
					await AuthenticationService.logOut();
					props.history.replace("/entrance");
				}}
				style={{ borderRadius: 4, fontSize: 14, height: 50, width: 186 }}>
				<div>SIGN OUT</div>
				<div className="font11">{initialEmail}</div>
			</Column>
		</Row>
	</Column>
}

export default GeneralTab;