import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import { LineModel, StrategyTab } from "./EditorModels";
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';

interface Props {
	tab: StrategyTab,
	onTabChanged: (newTab: StrategyTab) => void
}

const Tabs: React.FunctionComponent<Props> = props =>
	isDesktop ?
		<Row className="mtop40 segoe font18 semibold" style={{ position: "relative" }}>
			<Row className="pointer" style={{ borderRadius: 10, zIndex: 2, width: 144, height: 44 }}
				onClick={() => props.onTabChanged(StrategyTab.Stats)} mainAxis="center" crossAxis="center">
				<FormattedMessage id="tabs.strategy" />
			</Row>
			<Row className="pointer" style={{ marginLeft: 20, borderRadius: 10, zIndex: 2, width: 144, height: 44 }}
				onClick={() => props.onTabChanged(StrategyTab.Rules)} mainAxis="center" crossAxis="center">
				<FormattedMessage id="tabs.rules" />
			</Row>
			<Row className="pointer" style={{ marginLeft: 20, borderRadius: 10, zIndex: 2, width: 144, height: 44 }}
				onClick={() => props.onTabChanged(StrategyTab.Positions)} mainAxis="center" crossAxis="center">
				<FormattedMessage id="tabs.positions" />
			</Row>
			<div className="bg-button"  style={{
				borderRadius: 10,
				position: "absolute",
				left: props.tab == StrategyTab.Positions ? 328 : props.tab == StrategyTab.Rules ? 164 : 0,
				transition: "left 0.4s ease",
				width: 144,
				height: 44
			}}></div>
		</Row>
		:
		<Row className="mtop20 segoe font18 semibold" style={{ position: "relative" }}>
			<Row className="pointer" style={{ borderRadius: 2, zIndex: 2, width: "50%", height: 44 }}
				onClick={() => props.onTabChanged(StrategyTab.Stats)} mainAxis="center" crossAxis="center">
				<FormattedMessage id="tabs.strategy" />
			</Row>
			<Row className="pointer" style={{ borderRadius: 2, zIndex: 2, width: "50%", height: 44 }}
				onClick={() => props.onTabChanged(StrategyTab.Rules)} mainAxis="center" crossAxis="center">
				<FormattedMessage id="tabs.rules" />
			</Row>
			<div className="bg-button" style={{
				borderRadius: 8,
				position: "absolute",
				left: props.tab == StrategyTab.Rules ? "50%" : 0,
				transition: "left 0.4s ease",
				width: "50%",
				height: 44
			}}></div>
		</Row>



export default Tabs;