import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import SvgMail from "/src/components/svg/SvgMail";
import SvgMailUnsubscribe from "/src/components/svg/SvgMailUnsubscribe";
import Loader from "/src/components/Loader";

interface Props {
	onClick: () => Promise<void>,
	isSubscribed: boolean
}

interface State {
}

const SubscribeButton: React.FunctionComponent<Props> = (props) => {
	const [pressed, setPressed] = React.useState<Boolean>(false);

	return !props.isSubscribed ?
		<Row mainAxis="center" crossAxis="center" className="mtop40 mbottom40" style={{ zIndex: 7 }}>
			<Row mainAxis="center" crossAxis="center" className="bg-inverted color-inverted font16 bold pointer"
				style={{ width: 220, height: 60, borderRadius: 30 }}
				onClick={async () => {
					setPressed(true);
					await props.onClick();
					setPressed(false);
				}}>
				{pressed && <Loader />}
				{!pressed && <SvgMail />}
				{!pressed && <div className="mleft15">
					<FormattedMessage id="strategy.subscribe" />
				</div>}
			</Row>
		</Row>
		: <Row mainAxis="center" crossAxis="center" className="mtop40 mbottom40" style={{ zIndex: 7 }}>
			<Row mainAxis="center" crossAxis="center" className="border bg-main color-main font16 bold pointer"
				style={{ width: 220, height: 60, borderRadius: 30 }}
				onClick={async () => {
					setPressed(true);
					await props.onClick();
					setPressed(false);
				}}>
				{pressed && <Loader />}
				{!pressed && <SvgMailUnsubscribe />}
				{!pressed && <div className="mleft15">
					<FormattedMessage id="strategy.unsubscribe" />
				</div>}
			</Row>
		</Row>
}


export default SubscribeButton;