import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedDate } from 'react-intl';
import tab_strategies from '/src/assets/tab_strategies.svg';
import tab_wallet from '/src/assets/tab_wallet.svg';
import tab_profile from '/src/assets/tab_profile.svg';
import tab_strategies_inactive from '/src/assets/tab_strategies_inactive.svg';
import tab_wallet_inactive from '/src/assets/tab_wallet_inactive.svg';
import tab_profile_inactive from '/src/assets/tab_profile_inactive.svg';
import AuthenticationService from "../services/authentication";

interface Props {
	tab: number,
}

interface State {
}

class MobileNavigationTabs extends React.Component<Props, State>  {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const props = this.props;
		return <Row mainAxis="space-between" className="bg-button" style={{ borderTop: "1px solid #C3C8D1", height: 75, position: "fixed", bottom: 0, width: "100%", zIndex: 7 }}>
			<Link className="flex1" replace to={{ pathname: "/dashboard", state: undefined }}>
				<Column mainAxis="center" crossAxis="center" className={"font14 segoe tab pointer anim " + (props.tab === 0 ? " " : "inactivetab")} style={{ height: 75 }}>
					<img src={props.tab === 0 ? tab_strategies : tab_strategies_inactive} style={{ width: 38, height: 38 }} />
					<FormattedMessage id="home.tab.strategies" />
				</Column>
			</Link>
			{!AuthenticationService.isGuest() && <Link className="flex1" replace to={{ pathname: "/wallet/", state: undefined }}>
				<Column mainAxis="center" crossAxis="center" className={"font14 segoe tab pointer anim " + (props.tab === 1 ? " " : "inactivetab")} style={{ height: 75 }}>
					<img src={props.tab === 1 ? tab_wallet : tab_wallet_inactive} style={{ width: 38, height: 38 }} />
					<FormattedMessage id="home.tab.wallet" />
				</Column>
			</Link>}
			{!AuthenticationService.isGuest() && <Link className="flex1" replace to={{ pathname: "/profile/general", state: undefined }}>
				<Column mainAxis="center" crossAxis="center" className={"font14 segoe tab pointer anim " + (props.tab === 2 ? " " : "inactivetab")} style={{ height: 75 }}>
					<img src={props.tab === 2 ? tab_profile : tab_profile_inactive} style={{ width: 38, height: 38 }} />
					<FormattedMessage id="home.tab.profile" />
				</Column>
			</Link>}
			{AuthenticationService.isGuest() && <Link className="flex1" replace to={{ pathname: "/sign-up", state: undefined }}>
				<Column mainAxis="center" crossAxis="center" className={"font14 segoe tab pointer anim " + (props.tab === 3 ? " " : "inactivetab")} style={{ height: 75 }}>
					<img src={props.tab === 3 ? tab_profile : tab_profile_inactive} style={{ width: 38, height: 38 }} />
					<FormattedMessage id="home.tab.sign_up" />
				</Column>
			</Link>}
		</Row>
	}
}

export default MobileNavigationTabs;