import InstrumentsService, { InstrumentModel } from '/src/services/instruments'

interface InstrumentsCacheMap {
	[key: string]: InstrumentModel
}

let instrumentsMap: InstrumentsCacheMap;
let allInstruments: InstrumentModel[];

export interface InstrumentGroup {
	name: string,
	instruments: InstrumentModel[]
}

export const InstrumentsCache = {
	initialize: async () => {
		if (instrumentsMap === undefined) {
			allInstruments = await InstrumentsService.searchInstruments();
			instrumentsMap = allInstruments.reduce((a, c) => { a[c.symbol] = c; return a; }, {});
		}
	},

	getInstrument: (name: string): InstrumentModel => {
		return instrumentsMap[name];
	},

	searchInstruments: (search: string): InstrumentGroup[] => {

		const upperSearchText = search.toUpperCase();
		const bySymbolInBeggining = allInstruments.filter(i => i.symbol.toUpperCase().indexOf(upperSearchText) === 0);
		const bySymbolInMiddle = allInstruments.filter(i => i.symbol.toUpperCase().indexOf(upperSearchText) > 0);
		const byDescription = allInstruments.filter(i => i.symbol.toUpperCase().indexOf(upperSearchText) === -1 && i.description.toUpperCase().indexOf(upperSearchText) !== -1);
		const found = bySymbolInBeggining.concat(bySymbolInMiddle, byDescription);
		
		var grouped = found.reduce((group, ins) => {
			(group[ins.family] = group[ins.family] || []).push(ins);
			return group;
		}, {})
		return Object.keys(grouped).map(groupName => {
			return {
				name: groupName,
				instruments: grouped[groupName]
			}
		});
	}
}