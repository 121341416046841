import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { IndicatorsCache } from "/src/services/indicatorsCache";
import { ChartMode, EditingCallbacks, IToken, IndicatorToken, MovingCallbacks, Parameter } from "../EditorModels";
import ParameterInput from "../Panel/ParameterInput";
import NumberInput from "../Panel/NumberInput";
import AutoResizingInput from "./AutoResizingInput";
import TokenMenu from "./TokenMenu";
import { HiArrowDown, HiArrowUp, HiEye, HiTrash } from "/node_modules/react-icons/hi2/index";
import ChartTypeMenu from "./ChartTypeMenu";

interface Props {
	indicator: IndicatorToken,
	options: IToken[],
	editingCallbacks: EditingCallbacks,
	movingCallbacks: MovingCallbacks,
}

const BooleanEditingLine: React.FunctionComponent<Props> = props => {
	const indicatorData = IndicatorsCache.getIndicator(props.indicator.functionName);
	return <Row crossAxis="center" style={{ flexWrap: "wrap", rowGap: 4 }} className="pleft9" mainAxis="flex-start">
			{props.indicator.parameters.map((p, i) =>
				<Row crossAxis="center" className="mleft5">
					{indicatorData.parameters[i].prefix && <Row mainAxis="flex-end" crossAxis="center"
						className={"semibold flex1 mright5 " + (indicatorData.parameters[i].prefix && indicatorData.parameters[i].prefix.length > 3 ? "font14" : "font20")}>
						{indicatorData.parameters[i].prefix}
					</Row>}

					{indicatorData.parameters[i].type !== "Integer" ?
						<ParameterInput
							options={props.options.map(o => o.name)}
							value={p}
							onValueChange={(value: Parameter) => {
								const updatedInstrument: IndicatorToken = { ...props.indicator };
								updatedInstrument.parameters[i] = value;
								props.editingCallbacks.onIndicatorUpdated(updatedInstrument);
							}} />
						:
						<NumberInput
							value={p}
							onValueChange={(value: Parameter) => {
								const updatedInstrument: IndicatorToken = { ...props.indicator };
								updatedInstrument.parameters[i] = value;
								props.editingCallbacks.onIndicatorUpdated(updatedInstrument);
							}} />}
				</Row>)
			}

		</Row>
}

export default BooleanEditingLine;