import * as React from "react";
import { groupCSS } from "../../../../node_modules/react-select/dist/declarations/src/components/Group";
import CheckBox from "../../../components/CreateAccountPage/CheckBox";
import DateInput from "../../../components/CreateAccountPage/DateInput";
import PhoneInput from "../../../components/CreateAccountPage/PhoneInput";
import SelectInput from "../../../components/CreateAccountPage/SelectInput";
import TextInput from "../../../components/CreateAccountPage/TextInput";
import { Column, isDesktop, Row } from "../../../components/Layout";
import { initField, isFieldHidden, setField } from "./AllLegalData";
import { AccountField, AccountFieldGroup, AccountFieldType } from "/src/services/accounts";
import { all_countries_options, all_usa_states } from "/src/utils/countries";
import help_black from '/src/assets/help_black.svg';
import FileInput from "/src/components/CreateAccountPage/FileInput";

interface Props {
	group?: AccountFieldGroup,
	onChange: (group: any) => void,
	protectNonEditable: boolean,
	values: any
}

const formatLabel = (label: string) => {
	const result = [];
	let cursor = 0;
	let linkStart = label.indexOf("[", cursor);
	while (linkStart > -1) {
		result.push(<span>{label.substring(cursor, linkStart)}</span>)
		let linkEnd = label.indexOf("]", linkStart)
		let urlEnd = label.indexOf(")", linkEnd);
		const link = label.substring(linkStart + 1, linkEnd);
		const url = label.substring(linkEnd + 2, urlEnd);
		result.push(<a href={url} style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer" className="semibold">{link}</a>);
		cursor = urlEnd + 1;
		linkStart = label.indexOf("[", cursor);
	}
	result.push(<span>{label.substring(cursor)}</span>)
	return result;
}

const LegalGeneralEditor: React.FunctionComponent<Props> = ({ group, values, onChange, protectNonEditable }) => {

	const rows: AccountField[][] = [];
	let row: AccountField[] = [];
	let i = 0;
	let groupHasNonBoolean = false;
	let groupDisabledByFlag = false;

	let title = undefined;
	let title_tip = undefined;

	const rowSize = isDesktop ? 3 : 1;

	group.fields.forEach(f => {
		groupHasNonBoolean = groupHasNonBoolean || (f.type !== AccountFieldType.Boolean && f.type !== AccountFieldType.File);

		if (f.disablesEntireSection && values[f.name] && values[f.name].value) {
			groupDisabledByFlag = true;
		}

		if (f.title) title = f.title;
		if (f.titleDescription) title_tip = f.titleDescription;

		if (isFieldHidden(f, values)) {
			return;
		}

		if (i > 0 && i % rowSize === 0 || f.showSeparately || f.type === AccountFieldType.Boolean || f.type === AccountFieldType.Agreement) {
			rows.push(row);
			row = [];
			i = 0;
		}
		row.push(f);
		i++;
	})
	rows.push(row);

	const clearAllGroup = (newValues) => {
		group.fields.forEach(f => {
			newValues[f.name] = initField()
		});
	}

	const hasNotHiddenFields = group.fields.some(f => !isFieldHidden(f, values));

	return !hasNotHiddenFields ? <div></div> : <Column className="mtop70">
		{title && <Row className="mbottom10">
			<div className="bold" title={title_tip}>{title}</div>
			{title_tip && <img className="mleft5" style={{ width: 16 }} src={help_black} alt="help_black" title={title_tip} />}
		</Row>}
		{rows.map((row, rowIndex) => <Row key={"row" + rowIndex} className={rowIndex > 0 ? "mtop10" : ""}>
			{row.map((field, fieldIndex) => {

				const current = values[field.name] || initField();

				const hidden = isFieldHidden(field, values);
				if (hidden) {
					return <div key={field.name}></div>
				}

				const disableField = groupDisabledByFlag || (!field.canBeUpdated && protectNonEditable);

				if (field.type == AccountFieldType.String) {
					return <TextInput key={field.name} disabled={disableField} className={fieldIndex > 0 ? "mleft10" : ""}
						label={field.label} value={current} onValueChange={v => { const n = { ...values }; n[field.name] = setField(v); onChange(n) }} />
				}
				else if (field.type === AccountFieldType.Date) {
					return <DateInput key={field.name} disabled={disableField} className={fieldIndex > 0 ? "mleft10" : ""}
						label={field.label} value={current} onValueChange={v => { const n = { ...values }; n[field.name] = setField(v); onChange(n) }} />
				}
				else if (field.type === AccountFieldType.Select) {
					return <SelectInput key={field.name} disabled={disableField} className={fieldIndex > 0 ? "mleft10" : ""}
						label={field.label} isMulti={false} options={field.options}
						tip={field.fieldDescription}
						value={current} onValueChange={v => { const n = { ...values }; n[field.name] = setField(v); onChange(n) }} />
				}
				else if (field.type === AccountFieldType.MultiSelect) {
					return <SelectInput key={field.name} disabled={disableField} className={fieldIndex > 0 ? "mleft10" : ""}
						label={field.label} isMulti={true} options={field.options}
						tip={field.fieldDescription}
						value={current} onValueChange={v => { const n = { ...values }; n[field.name] = setField(v); onChange(n) }} />
				}
				else if (field.type === AccountFieldType.Phone) {
					return <PhoneInput key={field.name} disabled={disableField} className={fieldIndex > 0 ? "mleft10" : ""}
						label={field.label} isMulti={false}
						value={current} onValueChange={v => { const n = { ...values }; n[field.name] = setField(v); onChange(n) }} />
				}
				else if (field.type === AccountFieldType.Country) {
					return <SelectInput key={field.name} disabled={disableField} className={fieldIndex > 0 ? "mleft10" : ""}
						label={field.label} isMulti={false} options={all_countries_options}
						value={current} onValueChange={v => { const n = { ...values }; n[field.name] = setField(v); onChange(n) }} />
				}
				else if (field.type === AccountFieldType.UsaState) {
					return <SelectInput key={field.name} disabled={disableField} className={fieldIndex > 0 ? "mleft10" : ""}
						label={field.label} isMulti={false} options={all_usa_states}
						value={current} onValueChange={v => { const n = { ...values }; n[field.name] = setField(v); onChange(n) }} />
				}
				else if (field.type === AccountFieldType.Agreement) {
					return <Row key={field.name} className="flex1 mtop10" mainAxis="center">
						<CheckBox disabled={disableField && !field.disablesEntireSection} className="flex1 font14"
							value={current} onValueChange={v => { const n = { ...values }; if (field.disablesEntireSection) clearAllGroup(n); n[field.name] = setField(v); onChange(n) }} >
							<span style={{ whiteSpace: "pre-line", fontSize: 13 }}>{formatLabel(field.label)}</span>
						</CheckBox>
					</Row>
				}
				else if (field.type === AccountFieldType.File) {
					return <Row key={field.name} className="flex1 mtop10 mbottom50" mainAxis="center">
						<FileInput label={field.label} value={current} onValueChange={v => { const n = { ...values }; n[field.name] = setField(v); onChange(n) }} />
					</Row>
				}
				else if (field.type === AccountFieldType.Boolean) {
					if (groupHasNonBoolean) {
						return <Row key={field.name} className="flex1" mainAxis="flex-end">
							<CheckBox disabled={disableField && !field.disablesEntireSection} className="font14"
								value={current} onValueChange={v => { const n = { ...values }; if (field.disablesEntireSection) clearAllGroup(n); n[field.name] = setField(v); onChange(n) }} >
								{field.label}
							</CheckBox>
						</Row>
					}
					else {
						return <Row key={field.name} className="flex1" mainAxis="center"><Row className="flex1" style={{ maxWidth: 600 }}>
							<CheckBox disabled={disableField && !field.disablesEntireSection} className="flex1 font14"
								value={current} onValueChange={v => { const n = { ...values }; if (field.disablesEntireSection) clearAllGroup(n); n[field.name] = setField(v); onChange(n) }} >
								{field.label}
							</CheckBox>
						</Row></Row>
					}
				}
				return undefined;
			})}
		</Row>)
		}

	</Column >
}

export default LegalGeneralEditor;
