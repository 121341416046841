import * as React from "react";
import { isDesktop, Column, Row, isMobile } from '/src/components/Layout'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import PageContainer from "/src/components/PageContainer";
import Loader from "/src/components/Loader";
import AuthenticationService from "/src/services/authentication";

interface Props extends RouteComponentProps {

}

enum VerificationResult {
	None,
	Success,
	Failed
}

const VerifyEmailPage: React.FunctionComponent<Props> = (props) => {
	//document.title = "Wallet " + String.fromCharCode(183) + " Cicada";

	const requestId = new URLSearchParams(props.location.search).get("requestId");
	const code = new URLSearchParams(props.location.search).get("code");
	const [result, setResult] = React.useState<VerificationResult>(VerificationResult.None);

	React.useEffect(() => {
		async function load() {
			const response = await AuthenticationService.verifyCode(requestId, code);
			setResult(!response.error ? VerificationResult.Success : VerificationResult.Failed);
		}
		if (result === VerificationResult.None) {
			load();
		}
	}, []);

	const size = isMobile ? "font20" : "font50";
	return <Column mainAxis="center" crossAxis="center" className={"white " + size} style={{ backgroundColor: "#2C3038", height: "100vh", width: "100vw" }}>
		{result === VerificationResult.None ?
			<Loader /> :
			result === VerificationResult.Failed ?
				<Column>
					<Row>This link is expired.</Row>
					<Row>Try another one.</Row>
				</Column> :
				<Column>
					<Row>The email was successfully verified.</Row>
				</Column>}
	</Column>
}


export default withRouter(VerifyEmailPage);