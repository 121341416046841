import * as React from "react";
import { ChartCanvas, Chart, series } from "react-stockcharts";
import { LineSeries, AreaSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { discontinuousTimeScaleProviderBuilder } from "react-stockcharts/lib/scale";
import { first, last } from "react-stockcharts/lib/utils";
import { fitWidth } from "react-stockcharts/lib/helper";
import { CrossHairCursor, EdgeIndicator, CurrentCoordinate, MouseCoordinateX, MouseCoordinateY } from "react-stockcharts/lib/coordinates";

class StrategyThumbnailChart extends React.Component<{ type: any, width: any, height: any, ratio: any, data: any, color: string, filled: boolean }> {
	render() {
		const { type, width, height, ratio } = this.props;
		const xScaleProvider = discontinuousTimeScaleProviderBuilder()
			.inputDateAccessor(d => new Date(d.time));
		const {
			data,
			xScale,
			xAccessor,
			displayXAccessor,
		} = xScaleProvider(this.props.data);

		const start = xAccessor(last(data));
		const end = xAccessor(first(data));

		const xExtents = [start, end];

		const minmax = this.props.data.reduce((a, b) => { return { min: Math.min(a.min, b.value), max: Math.max(a.max, b.value) } }, { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER });
		const diff = (minmax.max - minmax.min);
		const padding = diff !== 0 ? diff * 0.02 : 1;

		return <div style={{ /*backgroundColor: "#7FBAC2",*/ height: height }}>

			<ChartCanvas metaId={1} ratio={ratio} width={width} height={height}
				type="svg"
				zoomEvent={false}
				margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
				seriesName="MSFT"
				xScale={xScale}
				xAccessor={xAccessor}
				displayXAccessor={displayXAccessor}
				xExtents={xExtents}
				disableInteraction={true}
				data={data}>
				<Chart id={0} yExtents={[minmax.min - padding, minmax.max + padding]}>
					{this.props.filled ? <AreaSeries
						yAccessor={d => d.value}
						strokeWidth={2}
						stroke={this.props.color}
						fill="#E2E9F4"
					/> : <LineSeries
						yAccessor={d => d.value}
						strokeWidth={2}
						stroke={this.props.color}
					/>
					}
				</Chart>
			</ChartCanvas>


		</div>
	}
}

const StrategyThumbnailChartSized = React.memo(fitWidth(StrategyThumbnailChart), (p, n) => //without memo the dashboard page scroll is interrupted badly
	p.data[p.data.length - 1].time === n.data[n.data.length - 1].time && p.color === n.color
);

export default StrategyThumbnailChartSized;