import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import close_cross from '/src/assets/close_cross.svg';
import WalletsService from '/src/services/wallets'
import AuthenticationService from '/src/services/authentication';
import UsersService, { UserModel } from '/src/services/users'

interface Props {
	onInvest: (amount: number) => void,
	onPanelClosed: () => void,
}

const InvestPanel: React.FunctionComponent<Props> = ({ onInvest, onPanelClosed }) => {

	const [amountToInvest, setAmountToInvest] = React.useState<string>("");
	const [currency, setCurrency] = React.useState<string>("");
	const [balance, setBalance] = React.useState<number>(undefined);
	const [isValid, setIsValid] = React.useState<boolean>(false);

	React.useEffect(() => {
		async function fetchMyAPI() {
			const currentUserId = AuthenticationService.getCurrentUserId();
			let [userInfo, balanceResponse] = await Promise.all([
				UsersService.getUserInfo(currentUserId),
				WalletsService.getMyBalance()]);

			setBalance(balanceResponse.available);
			setCurrency(userInfo.currency);
		}
		fetchMyAPI()
	}, [])

	const onChange = (e) => {
		const value = e.target.value;
		const parsed = parseFloat(value);
		const valid = !(isNaN(parsed) || parsed <= 0 || parsed > balance);
		setIsValid(valid);
		if (value == "" || !isNaN(parsed)) {
			setAmountToInvest(value)
		}
	}

	return <Column className="segoe pleft25 pright25" style={{ height: "100vh" }}>
		<Row mainAxis="flex-end" className="mtop10">
			<Row mainAxis="center" crossAxis="center" className="pointer hoverable" style={{ width: 30, height: 30 }}
				onClick={() => onPanelClosed()}>
				<img style={{ width: 16 }} src={close_cross} alt="close_cross" />
			</Row>
		</Row>
		<Row mainAxis="center" className="mtop35 font22 semibold">Invest</Row>
		<Row style={{ marginTop: 20 }} className="backwhite">
			<input
				className="font20 segoe pright10"
				type="number"
				autoFocus={false}
				placeholder="Enter amount to invest"
				style={{ border: "1px solid #CBD7E7", lineHeight: "38px", width: "100%", textAlign: "right" }}
				value={amountToInvest}
				onChange={(event) => onChange(event)}
			/>
		</Row>
		<Column className="mtop24">
			<Row mainAxis="flex-end" className="font16 segoe">Available</Row>
			<Row mainAxis="flex-end" className="font20 segoe">{balance} {currency}</Row>
		</Column>
		<Row mainAxis="center">
			<Row mainAxis="center" crossAxis="center" className={"pointer segoe bold mtop35"}
				onClick={() => isValid && onInvest(parseFloat(amountToInvest))}
				style={{ borderRadius: 4, fontSize: 14, height: 35, width: 186, backgroundColor: isValid ? "#505865" : "#CBD7E7", color: "#fff" }}>
				<div>INVEST</div>
			</Row>
		</Row>
	</Column>
}




export default InvestPanel;