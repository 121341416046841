import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { withRouter } from 'react-router-dom'
import PageContainer from '../../components/PageContainer'
import { FormattedMessage, FormattedDate } from 'react-intl';
import { Link } from "react-router-dom";
import Back from '/src/components/Back'
import { Slide, toast, ToastContainer } from "react-toastify/dist";
import Loader from "/src/components/Loader";
import Button from "/src/components/Button";
import Api, { ErrorCode } from "/generated/cicada-client";
import AuthenticationService from "../../services/authentication";
import { serverUrl } from '/src/services/common'
import EmailInput from "/src/components/EmailInput";
import CodeInput from "/src/components/CodeInput";
const api = new Api(serverUrl, undefined, async () => { return await AuthenticationService.getAuthHeaderValue(); });

interface Props {
	history: any
}

interface State {
	showCodeInput: boolean;
	requestId: string;
	attempt: number;
	wrongCode: boolean;
}


class SignUpPage extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = { showCodeInput: false, requestId: undefined, attempt: 0, wrongCode: undefined };
	}

	async componentDidMount() {
		api.analytics.logAction({ actionName: "SignUpPage", deviceId: AuthenticationService.getDeviceId() });
	}

	onEmailEnter = async (email) => {
		try {
			const result = await api.auth.signUp({
				deviceId: AuthenticationService.getDeviceId(),
				email: email
			});
			if (result.errorCode == ErrorCode.Ok) {
				this.setState({ showCodeInput: true, requestId: result.entity.requestId });
			}
			else {
				toast(result.error, { theme: "dark" })
				this.setState({ attempt: this.state.attempt + 1 })
			}
		}
		catch (e) {
			//this.setState({ isEmailCorrect: false })
		}
	}

	onEmailChange = async () => {
		this.setState({ showCodeInput: false });
	}

	onCodeEnter = async (code) => {
		try {
			this.setState({ wrongCode: false })
			const result = await api.auth.verifyCode({ requestId: this.state.requestId, code: code });
			if (result.errorCode == ErrorCode.Ok) {
				AuthenticationService.storeToken(result.entity)
				this.props.history.replace("/dashboard");
			}
			else {
				this.setState({ wrongCode: true })
				toast(result.error, { theme: "dark" })
			}
		}
		catch (e) {
			//this.setState({ isEmailCorrect: false })
		}
	}

	render() {
		document.title = "Sign up " + String.fromCharCode(183) + " Cicada";

		return <PageContainer maxWidth={800} history={this.props.history} tab={3} hideHeader={true}>
			<Row mainAxis="center" className="">
				<Column className="mtop50" >
					<div style={{ lineHeight: "60px", fontSize: 25, fontWeight: "bold" }}>
						<FormattedMessage id='home.tab.sign_up' />
					</div>
					<Row className="font16 segoe mtop20">
						<FormattedMessage id="entrance.enter_email" />
					</Row>
					<EmailInput attempt={this.state.attempt} onChange={this.onEmailChange} onEmailEnter={this.onEmailEnter} />
					{this.state.showCodeInput && <CodeInput onCodeEnter={this.onCodeEnter} wrongCode={this.state.wrongCode} />}
				</Column>
			</Row >


		</PageContainer>
	}
}


export default withRouter(SignUpPage);