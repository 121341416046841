import * as React from "react";
import { IconProps } from "react-toastify/dist";
import { Column, Row } from '/src/components/Layout'

interface Props {
	value: any,
	onValueChange: (value: boolean) => void,
	className: string,
	disabled?: boolean
}

const CheckBox: React.FunctionComponent<Props> = ({ value, children, className, onValueChange, disabled }) => {
	return <Column className={className}>
		<Row crossAxis="center" mainAxis="space-between">
			<Row className="mright15">{children}</Row>
			<input disabled={disabled} type="checkbox" checked={value.value} style={{ flexShrink: 0 }} onChange={e => onValueChange(e.target.checked)} />
		</Row>
		{value.error && !value.hideError && <Row mainAxis="flex-end" className="font11 italic semibold error-blink mtop5">{value.error}</Row>}
	</Column>
}


export default CheckBox;