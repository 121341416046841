import rp from 'request-promise-native';
import { serverUrl } from '/src/services/common'

export interface InstrumentModel {
	exchange: string,
	symbol: string,
	description: string,
	baseCurrency: string,
	quoteCurrency: string,
	family: string,
	minVolume: number,
	volumeStep: number
}

const InstrumentsService = {
	searchInstruments: async () : Promise<InstrumentModel[]> => {
		const response = await rp({
			method: 'GET',
			json: true,
			uri: serverUrl + '/instruments/search'
		});
		return response;
	}
}

export default InstrumentsService;