import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { niceNumber, niceSignedNumber } from '/src/utils/numbers';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { Parameter } from "../EditorModels";

interface Props {
	value: Parameter,
	onValueChange: (value: Parameter) => void,
}

const NumberInput: React.FunctionComponent<Props> = ({ value, onValueChange }) => {
	const [inputValue, setInputValue] = React.useState(value);

	return <Row className="segoe">
		<input
			className="font16 semibold textRight border-alt"
			placeholder=""
			type="number"
			min="1"
			max="999"
			value={inputValue.toString()}
			style={{ borderRadius: 8, height: 27, lineHeight: "27px", width: 40, paddingRight: 4 }}
			onKeyDown={event => {
				if (event.key === 'Enter') {
					onValueChange(parseFloat(inputValue.toString()));
				}
			}}
			onChange={(event) => {
				setInputValue(event.target.value);
				event.preventDefault();
			}} />
	</Row>
}


export default NumberInput;