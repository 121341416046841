import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import AutoResizingInput from "./AutoResizingInput";
import ChartTypeMenu from "./ChartTypeMenu";
import { HiArrowDown, HiArrowUp, HiTrash } from "/node_modules/react-icons/hi2/index";
import TokenMenu from "./TokenMenu";
import { ChartMode, EditingCallbacks, IndicatorToken, MovingCallbacks } from "../EditorModels";

interface Props {
	isSelected: boolean,
	indicator: IndicatorToken,
	editingCallbacks: EditingCallbacks,
	movingCallbacks: MovingCallbacks,
	value: number
}


const FunctionCodeLine: React.FunctionComponent<Props> = (props) => {

	const onChartModeChanged = (mode: ChartMode) => {
		const updatedInstrument: IndicatorToken = { ...props.indicator };
		if (updatedInstrument.chart != mode) {
			updatedInstrument.chart = mode;
			props.editingCallbacks.onIndicatorUpdated(updatedInstrument);
		}
	}

	const onRenamingFinished = (newName: string) => {
		const updatedIndicator: IndicatorToken = { ...props.indicator };
		if (updatedIndicator.name != newName) {
			updatedIndicator.name = newName;
			props.editingCallbacks.onIndicatorUpdated(updatedIndicator);
		}
	}

	return <Row crossAxis="center" style={{ flexShrink: 0 }}>
		{props.isSelected
			? <AutoResizingInput value={props.indicator.name} onChange={onRenamingFinished} />
			: <div className="font20 semibold">{props.indicator.name}</div>
		}

		{props.isSelected && <Row mainAxis="flex-end" style={{ position: "absolute", right: 4 }} >
			<ChartTypeMenu chart={props.indicator.chart} onChanged={onChartModeChanged} />
			<TokenMenu items={[
				{ icon: <HiArrowUp style={{ height: 17, width: 17 }} />, text: "Move up", action: () => props.movingCallbacks.onMovedUp(props.indicator), isEnabled: props.movingCallbacks.canBeMovedUp },
				{ icon: <HiArrowDown style={{ height: 17, width: 17 }} />, text: "Move down", action: () => props.movingCallbacks.onMovedDown(props.indicator), isEnabled: props.movingCallbacks.canBeMovedDown },
				{ icon: <HiTrash style={{ height: 17, width: 17 }} />, text: "Delete", action: () => props.movingCallbacks.onDeleted(props.indicator), isEnabled: props.movingCallbacks.canBeDeleted },
			]} />
		</Row>}

		<div className="font12 segoe mleft10 textRight" style={{ visibility: props.isSelected ? "hidden" : "visible", minWidth: 40, paddingRight: 14 }}>{props.value !== undefined ? props.value.toFixed(props.indicator.precision) : "\u2022\u2022\u2022\u2022"}</div>
	</Row>
}

export default FunctionCodeLine;