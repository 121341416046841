import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import { signedPercent, niceNumber, niceSignedNumber } from '/src/utils/numbers';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { StrategyStatement, TimeValue } from "/src/services/strategies";
import InvestmentResultChart, { TimeValueInvested } from "./InvestmentResultChart";
import { TransactionModel } from "/src/services/wallets";
import { AllocationStatus } from "/src/services/trading";

interface Props {
	investmentStatement: StrategyStatement,
	transactions: TransactionModel[],
	openTradesNumber: number,
	onInvesting: () => void,
	onStopInvesting: () => void
}

const mergeWithInvestedHistory = (values: TimeValue[], transactions: TransactionModel[]): TimeValueInvested[] => {
	let invested = 0;
	let nextTransactionIndex = 0;
	const result: TimeValueInvested[] = [];

	while (transactions.length > nextTransactionIndex && transactions[nextTransactionIndex].time < values[0].timestamp) {
		invested = -transactions[nextTransactionIndex].amount;
		nextTransactionIndex++;
	}

	values.forEach(v => {
		if (transactions.length > nextTransactionIndex && transactions[nextTransactionIndex].time <= v.timestamp) {
			invested += (-1 * transactions[nextTransactionIndex].amount);
			nextTransactionIndex++;
		}
		result.push({
			timestamp: v.timestamp,
			value: v.value,
			invested: invested
		});
	})
	return result;
}

const InvestmentStats: React.FunctionComponent<Props> = ({ investmentStatement, transactions, openTradesNumber, onInvesting, onStopInvesting }) =>
	isDesktop ?
		<Row mainAxis="center">
			<Column className="flex1 mtop20">

				<Row mainAxis="center">INVESTMENT STATS ({AllocationStatus[investmentStatement.stats.status]})</Row>
				<Row style={{ flexWrap: "wrap" }}>
					<Column className="flex1" style={{ padding: 40 }}>
						<Row mainAxis="space-between" className=""><div className="segoe font14">EQUITY</div>
							<div className="font20 semibold">{niceNumber(investmentStatement.stats.equity, 2)}</div></Row>
						<Row mainAxis="space-between" className="mtop15"><div className="segoe font14">TOTAL INVESTED</div>
							<div className="font20 semibold">{niceNumber(investmentStatement.stats.netAllocated, 2)}</div></Row>
						<Row mainAxis="space-between" className="mtop15"><div className="segoe font14">TOTAL RELATIVE PROFIT</div>
							<div className="font20 semibold">{signedPercent(investmentStatement.stats.equity / investmentStatement.stats.netAllocated, 1)}</div></Row>
						<Row mainAxis="space-between" className="mtop15"><div className="segoe font14">INVESTMENT STARTED</div>
							<div className="font20 semibold"><FormattedDate value={new Date(investmentStatement.stats.firstAllocation)} year="2-digit" month='short' day='numeric' /></div></Row>
					</Column>
					<Column className="flex2" style={{ padding: 40 }}>
						{investmentStatement.equityHistory.values.length > 1 &&
							<InvestmentResultChart data={mergeWithInvestedHistory(investmentStatement.equityHistory.values, transactions)} height={160} />
						}
					</Column>
				</Row>

				<Row mainAxis="center" style={{ flexWrap: "wrap" }}>
					<Column className="flex1" style={{ padding: 40, maxWidth: 350 }}>
						<Row mainAxis="center" style={{ paddingBottom: 20 }}>INVESTMENT HISTORY</Row>
						{
							transactions.map(t =>
								<Row key={t.id} mainAxis="space-between" className="font16 mtop10">
									<div><FormattedDate value={new Date(t.time)} year='2-digit' month='short' day='numeric' hourCycle="h23" hour="2-digit" minute="2-digit" /></div>
									<Row style={{ width: 200 }} mainAxis="space-between">
										<div>{t.amount <= 0 ? "Invested" : "Withdrawn"} </div>
										<div>{-t.amount}</div>
									</Row>
								</Row>)
						}
					</Column>
					<Column className="flex1" style={{ padding: "40px 40px 40px 80px", maxWidth: 350 }}>
						<Row mainAxis="center" style={{ paddingBottom: 20 }}>POSITIONS STATS</Row>
						<Row mainAxis="space-between" className="mtop10"><div className="segoe font14">BALANCE</div>
							<div className="font20 semibold">{niceNumber(investmentStatement.stats.balance, 2)}</div></Row>
						<Row mainAxis="space-between" className="mtop10"><div className="segoe font14">FLOATING PROFIT</div>
							<div className="font20 semibold">{niceNumber(investmentStatement.stats.profit, 2)}</div></Row>
						<Row mainAxis="space-between" className="mtop10"><div className="segoe font14">OPEN TRADES</div>
							<div className="font20 semibold">{openTradesNumber}</div></Row>
						<Row mainAxis="space-between" className="mtop10"><div className="segoe font14">MARGIN</div>
							<div className="font20 semibold">{niceNumber(investmentStatement.stats.margin, 2)}</div></Row>
						<Row mainAxis="space-between" className="mtop10"><div className="segoe font14">MARGIN LEVEL</div>
							<div className="font20 semibold">{niceNumber(investmentStatement.stats.marginLevel * 100, 0)}%</div></Row>
					</Column>
				</Row>

				<Row mainAxis="center">
					<Row mainAxis="center" crossAxis="center" className={"pointer segoe bold"}
						onClick={onInvesting}
						style={{ borderRadius: 28, fontSize: 14, height: 55, width: 186, backgroundColor: "#505865", color: "#fff" }}>
						<div className="pleft10">INVEST MORE</div>
					</Row>
					<Row mainAxis="center" crossAxis="center" className="pointer segoe bold mleft20"
						onClick={onStopInvesting}
						style={{ borderRadius: 28, fontSize: 14, height: 55, width: 186, backgroundColor: "#505865", color: "#fff" }}>
						<div className="pleft10">STOP INVESTMENT</div>
					</Row>
				</Row>

				<Row style={{ width: 1, height: 100 }}></Row>
			</Column>
		</Row>
		:
		<Column className="flex1 mtop20 mleft10 mright10" >

			<Row mainAxis="center">INVESTMENT STATS ({AllocationStatus[investmentStatement.stats.status]})</Row>
			<Column className="mtop20" style={{ padding: 5 }}>
				<Row mainAxis="space-between" className=""><div className="segoe font14">EQUITY</div>
					<div className="font20 semibold">{niceNumber(investmentStatement.stats.equity, 2)}</div></Row>
				<Row mainAxis="space-between" className="mtop15"><div className="segoe font14">TOTAL INVESTED</div>
					<div className="font20 semibold">{niceNumber(investmentStatement.stats.netAllocated, 2)}</div></Row>
				<Row mainAxis="space-between" className="mtop15"><div className="segoe font14">TOTAL RELATIVE PROFIT</div>
					<div className="font20 semibold">{signedPercent(investmentStatement.stats.equity / investmentStatement.stats.netAllocated, 1)}</div></Row>
				<Row mainAxis="space-between" className="mtop15"><div className="segoe font14">INVESTMENT STARTED</div>
					<div className="font20 semibold"><FormattedDate value={new Date(investmentStatement.stats.firstAllocation)} year="2-digit" month='short' day='numeric' /></div></Row>
			</Column>
			<Column className="mtop20" style={{ padding: 5 }}>
				{investmentStatement.equityHistory.values.length > 1 &&
					<InvestmentResultChart data={mergeWithInvestedHistory(investmentStatement.equityHistory.values, transactions)} height={160} />
				}
			</Column>
			<Row mainAxis="center">
				<Column>
					<Row mainAxis="center" crossAxis="center" className="pointer segoe bold mtop10"
						onClick={onInvesting}
						style={{ borderRadius: 28, fontSize: 14, height: 45, width: 186, backgroundColor: "#505865", color: "#fff" }}>
						<div className="pleft10">INVEST MORE</div>
					</Row>
					<Row mainAxis="center" crossAxis="center" className="pointer segoe bold mtop10"
						onClick={onStopInvesting}
						style={{ borderRadius: 28, fontSize: 14, height: 45, width: 186, backgroundColor: "#505865", color: "#fff" }}>
						<div className="pleft10">STOP INVESTMENT</div>
					</Row>
				</Column>
			</Row>
			<Column className="flex1" style={{ padding: 5 }}>
				<Row mainAxis="center" className="mtop20" style={{ paddingBottom: 20 }}>POSITIONS STATS</Row>
				<Row mainAxis="space-between" className="mtop10"><div className="segoe font14">BALANCE</div>
					<div className="font20 semibold">{niceNumber(investmentStatement.stats.balance, 2)}</div></Row>
				<Row mainAxis="space-between" className="mtop10"><div className="segoe font14">FLOATING PROFIT</div>
					<div className="font20 semibold">{niceNumber(investmentStatement.stats.profit, 2)}</div></Row>
				<Row mainAxis="space-between" className="mtop10"><div className="segoe font14">OPEN TRADES</div>
					<div className="font20 semibold">{openTradesNumber}</div></Row>
				<Row mainAxis="space-between" className="mtop10"><div className="segoe font14">MARGIN</div>
					<div className="font20 semibold">{niceNumber(investmentStatement.stats.margin, 2)}</div></Row>
				<Row mainAxis="space-between" className="mtop10"><div className="segoe font14">MARGIN LEVEL</div>
					<div className="font20 semibold">{niceNumber(investmentStatement.stats.marginLevel * 100, 0)}%</div></Row>
			</Column>
			<Column className="flex1" style={{ padding: 5 }}>
				<Row mainAxis="center" className="mtop20" style={{ paddingBottom: 20 }}>INVESTMENT HISTORY</Row>
				{
					transactions.map(t =>
						<Row key={t.id} mainAxis="space-between" className="font16 mtop10">
							<div><FormattedDate value={new Date(t.time)} year='2-digit' month='short' day='numeric' hour12={false} hour="2-digit" minute="2-digit" /></div>
							<Row style={{ width: 200 }} mainAxis="space-between">
								<div>{t.amount <= 0 ? "Invested" : "Withdrawn"} </div>
								<div>{-t.amount}</div>
							</Row>
						</Row>)
				}
			</Column>


			<Row style={{ width: 1, height: 100 }}></Row>
		</Column >


export default InvestmentStats;