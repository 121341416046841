import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import vertical_dots from '/src/assets/vertical_dots.svg';
import vertical_dots_white from '/src/assets/vertical_dots_white.svg';
import { MenuItem } from "./MenuItem";

interface Props {
	items: MenuItem[],
	isWhite: boolean
}

interface State {
}

let previous = null;

const DotsMenu: React.FunctionComponent<Props> = ({ items, isWhite }) => {

	const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

	const listenedRef = React.useCallback(node => {
		const onWheel = (e) => { e.preventDefault(); };
		const onTouchStart = (e) => { e.preventDefault(); setIsExpanded(false); };

		if (previous !== null) {
			previous.removeEventListener('wheel', onWheel);
			previous.removeEventListener('touchstart', onTouchStart);
		}
		if (node != null) {
			node.addEventListener('wheel', onWheel, { passive: false });
			node.addEventListener('touchstart', onTouchStart, { passive: false });
		}
		previous = node;
	}, []);

	return <div style={{ position: "relative" }}>
		<div className="pointer hoverable" style={{ width: 25, height: 25, padding: 10 }} onClick={(e) => setIsExpanded(!isExpanded)} >
			<svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M12 14.4998C12 16.0902 13.2942 17.3845 14.8846 17.3845C16.475 17.3845 17.7692 16.0902 17.7692 14.4998C17.7692 12.9095 16.475 11.6152 14.8846 11.6152C13.2942 11.6152 12 12.9095 12 14.4998Z" fill="var(--color-main)" />
				<path d="M12 24.1156C12 25.706 13.2942 27.0002 14.8846 27.0002C16.475 27.0002 17.7692 25.706 17.7692 24.1156C17.7692 22.5252 16.475 21.231 14.8846 21.231C13.2942 21.231 12 22.5252 12 24.1156Z" fill="var(--color-main)" />
				<path d="M12 4.88462C12 6.475 13.2942 7.76923 14.8846 7.76923C16.475 7.76923 17.7692 6.475 17.7692 4.88462C17.7692 3.29423 16.475 2 14.8846 2C13.2942 2 12 3.29423 12 4.88462Z" fill="var(--color-main)" />
			</svg>
		</div>
		{isExpanded && <Column className="font16 shadow bg-panel white"
			style={{
				zIndex: 10001,
				position: "absolute",
				borderRadius: "5px 0px 5px 5px",
				right: 0, top: 45
			}}>
			{
				items.map(item => <Row key={item.text} className={item.isEnabled ? "pointer white" : "lightgray"}
					mainAxis="center" crossAxis="center"
					style={{ width: 180, height: 50 }} onClick={() => {
						if (item.isEnabled) {
							item.action();
							setIsExpanded(false);
						}
					}}>
					{item.text}
				</Row>)
			}

		</Column>}
		{isExpanded && <div ref={listenedRef}
			style={{
				zIndex: 10000,
				position: "fixed",
				width: "100vw", height: "100vh",
				right: 0, top: 0
			}} onClick={(e) => { setIsExpanded(false); e.stopPropagation(); }} />}
	</div>
}


export default DotsMenu;