import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { niceNumber, niceSignedNumber, shortNumber } from '/src/utils/numbers';
import { FormattedMessage, FormattedDate } from 'react-intl';
import deal_arrow from '/src/assets/deal_arrow.svg';
import { Position, PositionStatus } from "/generated/cicada-client";

interface Props {
	position: Position,
	isFirst: Boolean,
	onSelected: (trade: Position) => void,
}

const getOpenTimeDate = (position: Position) => {
	switch (position.status) {
		case PositionStatus.Opening: return position.openingTime;
		case PositionStatus.Rejected: return position.openingTime;
		default: return position.openTime;
	}
}

const getOpenPriceText = (position: Position) => {
	switch (position.status) {
		case PositionStatus.Opening: return position.openingPrice;
		case PositionStatus.Rejected: return position.openingPrice;
		default: return position.openPrice;
	}
}

const getCloseTimeDate = (position: Position) => {
	switch (position.status) {
		case PositionStatus.Opening: return "Opening";
		case PositionStatus.Open: return "Open";
		case PositionStatus.Closing: return "Closing";
		case PositionStatus.Rejected: return "Rejected";
		default: return <FormattedDate value={new Date(position.closeTime)} month='short' day='numeric' hour="numeric" minute="numeric" second="numeric" hourCycle="h23" />
	}
}

const getClosePriceText = (position: Position) => {
	switch (position.status) {
		case PositionStatus.Opening: return "Opening";
		case PositionStatus.Closing: return "Closing";
		case PositionStatus.Rejected: return "Rejected";
		default: return position.closePrice;
	}
}

const PositionRow: React.FunctionComponent<Props> = ({ position, isFirst, onSelected }) =>
	<Row className="segoe pointer hoverwhite " style={{
		borderBottom: "1px solid #50586550", padding: "13px 0px 13px 0px"
	}} onClick={() => onSelected(position)}>
		<Column className="flex1 font20">
			<Row mainAxis="space-between" crossAxis="baseline" className="">
				<div>{position.volume >= 0 ? "Buy" : "Sell"}&nbsp;{shortNumber(Math.abs(+position.volume))}&nbsp;{position.symbol}</div>
				<Column>
					<Row>
						<div>{getOpenPriceText(position)}</div>
						<img style={{ width: 12, padding: "0px 10px" }} className="pointer" src={deal_arrow} alt="deal_arrow" />
						<div>{getClosePriceText(position)}</div>
					</Row>
					<Row className="font12" mainAxis="space-between">
						<div><FormattedDate value={new Date(getOpenTimeDate(position))} month='short' day='numeric' hour="numeric" minute="numeric" second="numeric" hourCycle="h23" /></div>
						<div>{getCloseTimeDate(position)}</div>
					</Row>
				</Column>
				<Column>
					<div className={" " + ((position.status !== PositionStatus.Closed && position.status !== PositionStatus.Rejected) ? "semibold" : "")}>{niceSignedNumber(position.profit, 2)}&nbsp;{position.instrumentCurrency}</div>
					<Row className="font12">
						<Row>TP {position.tpPercent}%</Row>
						<Row className="mleft10">SL {position.slPercent}%</Row>
					</Row>
				</Column>

			</Row>
		</Column>
	</Row>



export default PositionRow;