import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Column, Row } from '/src/components/Layout'

interface TabProps {
	nameKey: string,
	url: string
}

export const Tab: React.FunctionComponent<TabProps> = (props) => {
	return <div style={{paddingLeft: 10, paddingRight: 10}}>
		{props.children}
	</div>
}

interface Props {
	children: any;
	defaultTab: number,
	width: number
}

const TabMenu: React.FunctionComponent<Props> = (props) => {
	const [currentTab, setCurrentTab] = React.useState(props.defaultTab);

	const onTabChanged = (selected: number) => {
		window.history.replaceState(null, "", props.children[selected].props.url);
		setCurrentTab(selected);
	}

	const margin = 10;

	return <Column>
		<Row style={{ overflowX: "auto", maxWidth: "100vw" }} className="hide-scroll">
			<Row className="mtop40 segoe font18 semibold" style={{ position: "relative" }}>
				{props.children.map(
					(child, i) => (
						<Row key={i} mainAxis="center" crossAxis="center" className="pointer"
							style={{ marginLeft: i === 0 ? 0 : margin, borderRadius: 10, zIndex: 2, width: props.width, height: 44 }}
							onClick={() => onTabChanged(i)}>
							{child.props.nameKey}
						</Row>
					)
				)}
				<div className="bg-button" style={{
					borderRadius: 10,
					position: "absolute",
					left: currentTab * (props.width + margin),
					transition: "left 0.4s ease",
					width: props.width,
					height: 44
				}}></div>
			</Row>
		</Row>
		{props.children[currentTab]}
	</Column>
}

export default TabMenu;