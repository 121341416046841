import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { InstrumentsCache, InstrumentGroup } from '/src/services/instrumentsCache';
import { FormattedMessage, FormattedDate, } from 'react-intl';
import { LineModel, Parameter, InstrumentToken, DataSource, BarField, Timeframe, InstrumentTokenToLineModel, MovingCallbacks } from '../EditorModels'
import edit from '/src/assets/edit.svg';
import ParameterInput from './ParameterInput'
import DotsMenu from "../DotsMenu";

interface Props {
	instrument: InstrumentToken,
	onUpdated: (instrument: InstrumentToken) => void,
	onEdit: (instrument: InstrumentToken) => void,
	movingCallbacks: MovingCallbacks,
}

const InstrumentPanel: React.FunctionComponent<Props> = ({ instrument, onUpdated, onEdit: onInstrumentEdit, movingCallbacks }) => {
	const [customName, setCustomName] = React.useState<string>(instrument.name);

	React.useEffect(() => {
		setCustomName(instrument.name)
	}, [instrument.name])

	const onRenamingFinished = () => {
		const updatedInstrument: InstrumentToken = { ...instrument };
		updatedInstrument.name = customName;
		onUpdated(updatedInstrument);
	}

	return <Column className="segoe">
		<Row crossAxis="flex-end" className="bg-button" style={{ padding: 20 }}>
			<Row className="flex1 mleft10 mright10" style={{ position: "relative" }}>
				<input
					className="font20 semibold"
					type="text"
					placeholder=""
					style={{ borderBottom: "1px solid #CBD7E7", height: 35, width: "100%" }}
					value={customName}
					onChange={(event) => setCustomName(event.target.value)}
					onKeyDown={e => { if (e.key === 'Enter') { onRenamingFinished() } }}
					onBlur={e => onRenamingFinished()}
				/>
			</Row>
			<DotsMenu isWhite={false} items={[
				{ text: "Move up", action: () => movingCallbacks.onMovedUp(instrument), isEnabled: movingCallbacks.canBeMovedUp },
				{ text: "Move down", action: () => movingCallbacks.onMovedDown(instrument), isEnabled: movingCallbacks.canBeMovedDown },
				{ text: "Delete", action: () => movingCallbacks.onDeleted(instrument), isEnabled: movingCallbacks.canBeDeleted },
			]} />
		</Row>

		<Column style={{ padding: 20 }}>
			<Row className="mtop20 font12 mtop50 mbottom10">
				<div className="flex1"></div>
				<Row mainAxis="center" crossAxis="center" className="segoe bold font11 mright10">
					{InstrumentsCache.getInstrument(instrument.symbol).description.toUpperCase()}
				</Row>
				<Row mainAxis="center" crossAxis="center" className="pointer hoverable" style={{ width: 25, height: 25, padding: 4 }}
					onClick={() => onInstrumentEdit(instrument)}>
					<img style={{ width: 28 }} src={edit} alt="edit" />
				</Row>
			</Row>

			<Row className="top10">
				<Row className="font12 flex1" style={{ lineHeight: "3.5rem" }}><FormattedMessage id="picker.datasource.dataSeries" /></Row>
				<Row className="">
					<ParameterInput
						localizationPrefix="editor.datasource."
						options={Object.keys(DataSource).map(k => DataSource[k])}
						value={instrument.source}
						onValueChange={(v: DataSource) => {
							const updatedInstrument: InstrumentToken = { ...instrument };
							updatedInstrument.source = v;
							onUpdated(updatedInstrument);
						}} />
				</Row>
			</Row>
			<Row className="top10">
				<Row className="font12 flex1" style={{ lineHeight: "3.5rem" }}><FormattedMessage id="picker.datasource.timeframe" /></Row>
				<Row className="">
					<ParameterInput
						localizationPrefix="editor.timeframe."
						options={Object.keys(Timeframe).map(k => Timeframe[k])}
						value={instrument.timeframe}
						onValueChange={(v: Timeframe) => {
							const updatedInstrument: InstrumentToken = { ...instrument };
							updatedInstrument.timeframe = v;
							onUpdated(updatedInstrument);
						}} />
				</Row>
			</Row>
		</Column>
	</Column>
}




export default InstrumentPanel;