import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import Positions from './Positions'
import candlesticks from '/src/assets/candlesticks.svg';
import indicator from '/src/assets/indicator.svg';
import dollar from '/src/assets/dollar.svg';
import TradingService, { AllocationStats, Position, PositionStatus } from "/src/services/trading"
import PositionsPanel from "./PositionsPanel";
import InstrumentSearchPanel from "./InstrumentSearchPanel";
import { InstrumentModel } from "/src/services/instruments";
import { LineModel, ISelectable, TradeRuleToken, Direction, PanelButtons, EditingCallbacks, MovingCallbacks, IToken, InstrumentToken, TokenType, IndicatorToken } from '../EditorModels'
import InstrumentPanel from "./InstrumentPanel";
import IndicatorPanel from "./IndicatorPanel";
import TradeRulePanel from "./TradeRulePanel";
import IndicatorSearchPanel from "./IndicatorSearchPanel";
import { IndicatorModel, StrategyState } from '/src/services/strategies'
import TradePanel from "./TradePanel";
import InvestPanel from "./InvestPanel";

export enum PanelType {
	Common,
	Default,
	NewInstrument,
	NewIndicator,
	NewTradeRule,
	Trade,
	Invest,
	Trades
}

interface Props {
	type: PanelType,
	selected: ISelectable,
	deals: Position[],
	stats: AllocationStats,
	instrumentOptions: InstrumentToken[],
	allOptions: IToken[],
	hasTradeRules: boolean,
	hasInstruments: boolean,
	isOwnStrategy: boolean,
	strategyState: StrategyState,
	editingCallbacks: EditingCallbacks,
	movingCallbacks: MovingCallbacks,
	onPanelClosed: () => void,
	onBacktest: () => void,
	onTradeSelected: (trade: Position) => void,
	onInvesting: () => void,
	onStopInvesting: () => void,
	onInvest: (amount: number) => void,
	isInvested: boolean,
	onStrategyDelete: () => void,
}

const MobilePanel: React.FunctionComponent<Props> = ({
	type, selected, deals, stats, instrumentOptions, allOptions, strategyState, onInvest,
	onBacktest, editingCallbacks, movingCallbacks, isOwnStrategy, hasInstruments, onStrategyDelete,
	hasTradeRules, onPanelClosed, onTradeSelected, onInvesting, isInvested, onStopInvesting }) => {

	const selectedType = selected && (selected as IToken).type;
	return <Column style={{ backgroundColor: "#FDFDFE" }}
		onMouseDown={(e) => { /*side panel clicks are not clicks on the plot*/ e.stopPropagation(); }}>

		{type === PanelType.Invest
			&& <InvestPanel onPanelClosed={onPanelClosed} onInvest={onInvest} />}
		{type === PanelType.Trade
			&& <TradePanel trade={selected as Position} onPanelClosed={onPanelClosed} />}
		{type === PanelType.Trades
			&& <div>
				<Positions positions={deals} stats={stats} onTradeSelected={onTradeSelected} isDemoDeals={!isInvested} />
			</div>}
		{type === PanelType.Common
			&& <PositionsPanel
				isOwnStrategy={isOwnStrategy}
				isInvested={isInvested}
				type={PanelButtons.Common}
				onBacktest={onBacktest}
				strategyState={strategyState}
				positions={deals}
				onInstrumentAdding={editingCallbacks.onInstrumentAdding}
				onIndicatorAdding={editingCallbacks.onIndicatorAdding}
				onTradeRuleAdding={editingCallbacks.onTradeRuleAdding}
				hasTradeRules={hasTradeRules}
				hasInstruments={hasInstruments}
				onTradeSelected={onTradeSelected}
				onInvesting={onInvesting}
				onStopInvesting={onStopInvesting}
				onDelete={onStrategyDelete}
				stats={stats} />}
		{type === PanelType.Default && selectedType === undefined
			&& <PositionsPanel
				isOwnStrategy={isOwnStrategy}
				isInvested={isInvested}
				type={PanelButtons.Editing}
				onBacktest={onBacktest}
				strategyState={strategyState}
				positions={deals}
				onInstrumentAdding={editingCallbacks.onInstrumentAdding}
				onIndicatorAdding={editingCallbacks.onIndicatorAdding}
				onTradeRuleAdding={editingCallbacks.onTradeRuleAdding}
				hasTradeRules={hasTradeRules}
				hasInstruments={hasInstruments}
				onTradeSelected={onTradeSelected}
				onInvesting={onInvesting}
				onStopInvesting={onStopInvesting}
				onDelete={onStrategyDelete}
				stats={stats} />}
		{type === PanelType.Default && selectedType === TokenType.Instrument
			&& <InstrumentPanel
				instrument={selected && (selected as InstrumentToken)}
				movingCallbacks={movingCallbacks}
				onUpdated={editingCallbacks.onInstrumentUpdated}
				onEdit={editingCallbacks.onInstrumentEdit}
			/>}
		{type === PanelType.Default && selectedType === TokenType.Indicator
			&& <IndicatorPanel
				indicator={selected && (selected as IndicatorToken)}
				options={allOptions}
				movingCallbacks={movingCallbacks}
				onUpdated={editingCallbacks.onIndicatorUpdated}
				onEdit={editingCallbacks.onIndicatorEdit}
			/>}
		{type === PanelType.Default && selectedType === TokenType.TradeRule
			&& <TradeRulePanel
				tradeRule={selected && (selected as TradeRuleToken)}
				instrumentOptions={instrumentOptions}
				movingCallbacks={movingCallbacks}
				onSignalForced={editingCallbacks.onSignalForced}
				onUpdated={editingCallbacks.onTradeRuleUpdated}
				allOptions={allOptions} />}

		{type === PanelType.NewInstrument
			&& <InstrumentSearchPanel
				options={allOptions}
				onInstrumentAdded={editingCallbacks.onInstrumentAdded}
				onInstrumentUpdated={editingCallbacks.onInstrumentUpdated}
				selected={selected && (selected as InstrumentToken)}
				onClose={onPanelClosed} />}

		{type === PanelType.NewIndicator
			&& <IndicatorSearchPanel
				options={allOptions}
				onIndicatorSelected={editingCallbacks.onIndicatorSelected}
				onIndicatorUpdated={editingCallbacks.onInstrumentUpdated}
				selected={selected && (selected as IndicatorToken)}
				onClose={onPanelClosed} />}

		{type === PanelType.NewTradeRule
			&& <div>trade rule not selected: should not be a case</div>}
	</Column >
}

export default MobilePanel;