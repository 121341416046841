import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import Positions from './Positions'
import candlesticks from '/src/assets/candlesticks.svg';
import indicator from '/src/assets/indicator.svg';
import dollar from '/src/assets/dollar.svg';
import TradingService, { AllocationStats, Position, PositionStatus } from "/src/services/trading"
import { IndicatorsCache } from '/src/services/indicatorsCache';
import { FormattedMessage, FormattedDate, } from 'react-intl';
import search from '/src/assets/search.svg';
import { LineModel, Parameter, IndicatorToken, TradeRuleToken, Direction, FunctionCall, TradeRuleTokenToLineModels, InstrumentToken, IToken, ChartMode, TokenType, MovingCallbacks } from '../EditorModels'
import edit from '/src/assets/edit.svg';
import delete_ from '/src/assets/delete.svg';
import ParameterInput from './ParameterInput'
import ParameterInput from './ParameterInput'
import small_plus from '/src/assets/small_plus.svg';
import DotsMenu from "../DotsMenu";
import NumberInput from "./NumberInput";
import StrategyService, { SignalDirection } from "/src/services/strategies";

interface Props {
	tradeRule: TradeRuleToken,
	onUpdated: (indicator: TradeRuleToken) => void,
	allOptions: IToken[],
	instrumentOptions: InstrumentToken[],
	movingCallbacks: MovingCallbacks,
	onSignalForced: (direction: SignalDirection) => void
}

const createDefaultCondition = (allOptions: IToken[]): IndicatorToken => {
	return {
		functionName: "CrossDown",
		parameters: [allOptions[Math.max(0, allOptions.length - 1)].name, allOptions[Math.max(0, allOptions.length - 2)].name],
		name: undefined,
		position: undefined,
		dataIndex: undefined,
		chart: ChartMode.Own,
		type: TokenType.Indicator
	};
}

const TradeRulePanel: React.FunctionComponent<Props> = ({ tradeRule, instrumentOptions, allOptions, onUpdated, movingCallbacks, onSignalForced }) => {

	return <Column className="segoe">

		<Row crossAxis="center" className="bg-button" style={{ padding: 20 }}>
			<div style={{ width: 28 }}></div>
			<Row mainAxis="center" className="flex1 segoe bold font14 mright10">TRADE RULE</Row>
			<DotsMenu isWhite={false} items={[
				{ text: "Move up", action: () => movingCallbacks.onMovedUp(tradeRule), isEnabled: movingCallbacks.canBeMovedUp },
				{ text: "Move down", action: () => movingCallbacks.onMovedDown(tradeRule), isEnabled: movingCallbacks.canBeMovedDown },
				{ text: "Open now", action: () => {onSignalForced(SignalDirection.Open)}, isEnabled: movingCallbacks.canBeDeleted },
				{ text: "Close now", action: () => {onSignalForced(SignalDirection.Close)}, isEnabled: movingCallbacks.canBeDeleted },
				{ text: "Delete", action: () => movingCallbacks.onDeleted(tradeRule), isEnabled: movingCallbacks.canBeDeleted },
			]} />
		</Row>

		<Column style={{ padding: 20 }}>

			<Row className="top10">
				<Row className="font12 flex1" style={{ lineHeight: "35px" }}><FormattedMessage id="picker.tradeRule.search" /></Row>
				<Row className="">
					<ParameterInput
						type='TradeRule'
						options={instrumentOptions.map(o => o.symbol)}
						value={tradeRule.instrument.symbol.substring(1)}
						onValueChange={(value: Parameter) => {
							const selectedInstruments = instrumentOptions.find(i => i.symbol === value);
							if (!selectedInstruments) {
								return;
							}

							const updatedInstrument: TradeRuleToken = { ...tradeRule };
							updatedInstrument.instrument = {
								symbol: ":" + selectedInstruments.symbol, exchange: selectedInstruments.exchange
							};
							onUpdated(updatedInstrument);
						}} />
				</Row>
			</Row>

			<Row className="top10">
				<Row className="font12 flex1" style={{ lineHeight: "35px" }}><FormattedMessage id="picker.tradeRule.direction" /></Row>
				<ParameterInput
					localizationPrefix="tradeRule.direction."
					options={Object.keys(Direction).map(k => Direction[k])}
					value={tradeRule.direction.toString()}
					onValueChange={(value: Parameter) => {
						const updatedInstrument: TradeRuleToken = { ...tradeRule };
						updatedInstrument.direction = Direction[value.toString()];
						onUpdated(updatedInstrument);
					}} />
			</Row>

			<Row className="top10">
				<Row className="font12 flex1" style={{ lineHeight: "35px" }}><FormattedMessage id="picker.tradeRule.fundsPerTrade" /></Row>
				<Row className="">
					<NumberInput
						value={(tradeRule.fundsPerTrade * 100).toString()}
						onValueChange={(value: Parameter) => {
							const updatedInstrument: TradeRuleToken = { ...tradeRule };
							updatedInstrument.fundsPerTrade = parseFloat(value.toString()) / 100;
							onUpdated(updatedInstrument);
						}} />
				</Row>
			</Row>

			<Row className="top10">
				<Row className="font12 flex1" style={{ lineHeight: "35px" }}><FormattedMessage id="picker.tradeRule.maxOpenTrades" /></Row>
				<Row className="">
					<NumberInput
						value={tradeRule.maxOpenTrades.toString()}
						onValueChange={(value: Parameter) => {
							const updatedInstrument: TradeRuleToken = { ...tradeRule };
							updatedInstrument.maxOpenTrades = parseFloat(value.toString());
							onUpdated(updatedInstrument);
						}} />
				</Row>
			</Row>

			<Row className="font14 bold mtop50 mbottom20" mainAxis="space-between">
				<Row></Row>
				<FormattedMessage id="picker.tradeRule.entryConditions" />
				<Row></Row>
			</Row>
			{tradeRule.openConditions.map((c, ci) =>
				<Row key={"open" + ci} className="mbottom5 bg-main" style={{ flexWrap: "wrap", border: "1px solid #50586550", padding: "10px 10px 5px 10px", borderRadius: 15 }}>
					<div className="mbottom5">
						<ParameterInput
							type='BooleanStream'
							options={allOptions.map(a => a.name)}
							value={c.parameters[0]}
							onValueChange={(value: Parameter) => {
								const updatedCondition = { ...c };
								updatedCondition.parameters[0] = value;

								const updated: TradeRuleToken = { ...tradeRule };
								updated.openConditions.splice(ci, 1, updatedCondition)
								onUpdated(updated);
							}} />
					</div>

					<ParameterInput
						options={IndicatorsCache.searchIndicatorsByGroup(["Events", "Conditions"]).map(i => i.name)}
						value={c.functionName}
						onValueChange={(value: Parameter) => {
							const updatedCondition = { ...c };
							updatedCondition.functionName = value;

							const updated: TradeRuleToken = { ...tradeRule };
							updated.openConditions.splice(ci, 1, updatedCondition)
							onUpdated(updated);
						}} />

					<div className="mbottom5">
						<ParameterInput
							type='BooleanStream'
							options={allOptions.map(a => a.name)}
							value={c.parameters[1]}
							onValueChange={(value: Parameter) => {
								const updatedCondition = { ...c };
								updatedCondition.parameters[1] = value;

								const updated: TradeRuleToken = { ...tradeRule };
								updated.openConditions.splice(ci, 1, updatedCondition)
								onUpdated(updated);
							}} />
					</div>
					<div className="flex1"></div>
					<Row mainAxis="center" crossAxis="center" className="pointer hoverable" style={{ width: 35, height: 35 }}
						onClick={() => {
							const updatedTradeRule: TradeRuleToken = { ...tradeRule };
							updatedTradeRule.openConditions.splice(ci, 1);
							onUpdated(updatedTradeRule);
						}}	>
						<img style={{ width: 20 }} src={delete_} alt="delete" />
					</Row>
				</Row>
			)}
			<Row mainAxis="flex-end" className="top10">
				<img className="pointer"
					style={{ width: "3rem", height: "3rem" }}
					src={small_plus}
					alt="new"
					onClick={e => {
						const updatedTradeRule: TradeRuleToken = { ...tradeRule };
						updatedTradeRule.openConditions.push(createDefaultCondition(allOptions));
						onUpdated(updatedTradeRule);
					}}
				/>
			</Row>


			<Row className="font14 bold mtop50 mbottom20" mainAxis="space-between">
				<Row></Row>
				<FormattedMessage id="picker.tradeRule.exitConditions" />
				<Row></Row>
			</Row>
			{tradeRule.closeConditions.map((c, ci) =>
				<Row key={"close" + ci} className="mbottom5 bg-main" style={{ flexWrap: "wrap", border: "1px solid #50586550", padding: "10px 10px 5px 10px", borderRadius: 15 }}>
					<div className="mbottom5">
						<ParameterInput
							type='BooleanStream'
							options={allOptions.map(a => a.name)}
							value={c.parameters[0]}
							onValueChange={(value: Parameter) => {
								const updatedCondition = { ...c };
								updatedCondition.parameters[0] = value;

								const updated: TradeRuleToken = { ...tradeRule };
								updated.closeConditions.splice(ci, 1, updatedCondition)
								onUpdated(updated);
							}} />
					</div>
					<ParameterInput
						options={IndicatorsCache.searchIndicatorsByGroup(["Events", "Conditions"]).map(i => i.name)}
						value={c.functionName}
						onValueChange={(value: Parameter) => {
							const updatedCondition = { ...c };
							updatedCondition.functionName = value;

							const updated: TradeRuleToken = { ...tradeRule };
							updated.closeConditions.splice(ci, 1, updatedCondition)
							onUpdated(updated);
						}} />
					<div className="mbottom5">
						<ParameterInput
							type='BooleanStream'
							options={allOptions.map(a => a.name)}
							value={c.parameters[1]}
							onValueChange={(value: Parameter) => {
								const updatedCondition = { ...c };
								updatedCondition.parameters[1] = value;

								const updated: TradeRuleToken = { ...tradeRule };
								updated.closeConditions.splice(ci, 1, updatedCondition)
								onUpdated(updated);
							}} />
					</div>
					<div className="flex1"></div>
					<Row mainAxis="center" crossAxis="center" className="pointer hoverable" style={{ width: 35, height: 35 }}
						onClick={() => {
							const updatedInstrument: TradeRuleToken = { ...tradeRule };
							updatedInstrument.closeConditions.splice(ci, 1);
							onUpdated(updatedInstrument);
						}}	>
						<img style={{ width: 20 }} src={delete_} alt="delete" />
					</Row>
				</Row>
			)}

			<Row mainAxis="flex-end" className="top10">
				<img className="pointer"
					style={{ width: "3rem", height: "3rem" }}
					src={small_plus}
					alt="new"
					onClick={e => {
						const updatedInstrument: TradeRuleToken = { ...tradeRule };
						updatedInstrument.closeConditions.push(createDefaultCondition(allOptions));
						onUpdated(updatedInstrument);
					}}
				/>
			</Row>

			<Column className="mtop20 bg-main" style={{ border: "1px solid #50586550", padding: "10px 10px 10px 10px", borderRadius: 15 }}>
				<Row className="">
					<Row className="font12 flex1" style={{ lineHeight: "35px" }}><FormattedMessage id="picker.tradeRule.takeProfitPercent" /></Row>
					<Row className="">
						<NumberInput
							value={tradeRule.takeProfitPercent.toString()}
							onValueChange={(value: Parameter) => {
								const updatedInstrument: TradeRuleToken = { ...tradeRule };
								updatedInstrument.takeProfitPercent = parseFloat(value.toString());
								onUpdated(updatedInstrument);
							}} />
					</Row>
				</Row>

				<Row className="mtop10">
					<Row className="font12 flex1" style={{ lineHeight: "35px" }}><FormattedMessage id="picker.tradeRule.stopLossPercent" /></Row>
					<Row className="">
						<NumberInput
							value={tradeRule.stopLossPercent.toString()}
							onValueChange={(value: Parameter) => {
								const updatedInstrument: TradeRuleToken = { ...tradeRule };
								updatedInstrument.stopLossPercent = parseFloat(value.toString());
								onUpdated(updatedInstrument);
							}} />
					</Row>
				</Row>
			</Column>
		</Column>
	</Column >
}

export default TradeRulePanel;