import * as React from "react";
import { Column, Row } from '/src/components/Layout'

interface Props {
	value: string,
	onChange: (value: string) => void
}

const AutoResizingInput: React.FunctionComponent<Props> = (props) => {
	const [currentValue, setCurrentValue] = React.useState<string>(props.value);
	React.useEffect(() => {
		setCurrentValue(props.value)
	}, [props.value])

	return <div style={{ position: "relative", minHeight: 24 }}>
		<span style={{ whiteSpace: "pre", visibility: "hidden", display: "inline-block" }} className="font20 semibold">{currentValue}</span>
		<input
			size={1}
			type="text"
			style={{ fontFamily: "Montserrat", position: "absolute", top: 0, left: 0, minWidth: 20 }}
			className="font20 semibold"
			value={currentValue}
			onChange={(event) => setCurrentValue(event.target.value)}
			onKeyDown={e => { if (e.key === 'Enter') { props.onChange(currentValue) } }}
			onBlur={e => props.onChange(currentValue)} />
	</div>
}

export default AutoResizingInput;