import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import Back from '/src/components/Back'
import StrategyService, { ChartTimeItem, StrategyInfo, StrategyState } from '/src/services/strategies'
import trades from '/src/assets/trades.svg';
import { PublicUserModel } from '/src/services/users'
import { StrategyTab } from './EditorModels'
import Tabs from "./Tabs";
import DotsMenu from "./DotsMenu";
import ControlledInput from "./ControlledInput";

interface Props {
	tab: StrategyTab,
	history: any,
	author: PublicUserModel,
	currentName: string,
	strategy: StrategyInfo,
	isOwnStrategy: boolean,
	onStrategyNameChanged: (name: string) => void,
	onTabChanged: (newTab: StrategyTab) => void
	onShowTrades: () => void,
	onStrategyDelete: () => void
}

interface State {
	currentName: string
}

class EditorHeader extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			currentName: props.currentName,
		};
	}

	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.strategy.id !== this.props.strategy.id) {
			this.setState({
				currentName: nextProps.currentName,
			});
		}
	}

	render() {
		const props = this.props;
		return isDesktop ?
			<Column>
				<Row crossAxis="center" mainAxis="space-between" style={{ marginTop: 60 }}>
					<Row style={{ width: 100 }}>
						{props.history && <Back onBack={props.history.goBack} />}
					</Row>
					<Column className="flex1" style={{ position: "relative", maxWidth: 700 }}>

						<ControlledInput disabled={!props.isOwnStrategy} style={{ width: "100%" }} className="textCenter bold segoe font42" value={this.state.currentName}
							onChange={e => this.setState({ currentName: e.target.value.toUpperCase() })}
							onBlur={e => { this.props.onStrategyNameChanged(this.state.currentName); }}
							onKeyDown={event => { if (event.key === 'Enter') { this.props.onStrategyNameChanged(this.state.currentName); } }} />
						<div className="bg-inverted" style={{ width: "100%", height: 2, position: "absolute", bottom: 11 }}></div>
					</Column>
					<Row mainAxis="flex-end" style={{ width: 100 }}>
						<DotsMenu isWhite={false} items={[
							{ text: "Delete strategy", action: props.onStrategyDelete, isEnabled: props.isOwnStrategy }
						]} />
					</Row>
				</Row>
				<Tabs tab={props.tab} onTabChanged={props.onTabChanged} />
			</Column>
			:
			<Column>
				<Row crossAxis="center" mainAxis="space-between" style={{ marginTop: 5 }}>
					<Row>
						{props.history && <Back onBack={props.history.goBack} />}
					</Row>
					<Row>
						{/*<img className="pointer" style={{ width: 25, height: 25, padding: 10 }} src={share} alt="share" onClick={(e) => false} />*/}
						<img className="pointer" style={{ width: 25, height: 25, padding: 10 }} src={trades} alt="trades" onClick={(e) => props.onShowTrades()} />
						<DotsMenu isWhite={false} items={[
							{ text: "Delete strategy", action: props.onStrategyDelete, isEnabled: props.isOwnStrategy }
						]} />
					</Row>
				</Row>

				<Row style={{ position: "relative", margin: "10px 40px" }} mainAxis="center">
					<input disabled={!props.isOwnStrategy} style={{ width: "100%", fontSize: 28, lineHeight: "24px" }}
						className="textCenter bold segoe"
						value={this.state.currentName}
						onBlur={e => { this.props.onStrategyNameChanged(this.state.currentName); }}
						onChange={e => this.setState({ currentName: e.target.value.toUpperCase() })}></input>
				</Row>

				<Tabs tab={props.tab} onTabChanged={props.onTabChanged} />
			</Column>
	}
}

export default React.memo(EditorHeader);