export function svg_pather(x, y, s) {
	let result = "";
	let number = "";
	let isX = true;

	for (let i = 0; i < s.length; i++) {
		if (s[i] === 'M') {
			result = result.concat(s[i]);
		}
		else if (s[i] === ' ') {
			const parsed = parseFloat(number);
			if (isX) {
				result = result.concat(parsed + x + s[i]);
			}
			else {
				result = result.concat(parsed + y + s[i]);
			}
			number = "";
			isX = !isX;
		}
		else if (s[i] === 'V') {
			const parsed = parseFloat(number);
			if (isX) {
				result = result.concat(parsed + x + s[i]);
			}
			else {
				result = result.concat(parsed + y + s[i]);
			}
			number = "";
			isX = false;
		}
		else if (s[i] === 'H') {
			const parsed = parseFloat(number);
			if (isX) {
				result = result.concat(parsed + x + s[i]);
			}
			else {
				result = result.concat(parsed + y + s[i]);
			}
			number = "";
			isX = true;
		}
		else if (s[i] === 'C' || s[i] === 'L' || s[i] === 'Z') {
			const parsed = parseFloat(number);
			if (isX) {
				result = result.concat(parsed + x + s[i]);
			}
			else {
				result = result.concat(parsed + y + s[i]);
			}
			number = "";
			isX = true;
		}
		else {
			number = number.concat(s[i]);
		}
	}

	return result;
}