import * as React from "react";
import { ValidatedField } from "../../pages/profile/LegalSections/AllLegalData";
import { Column, Row } from '/src/components/Layout'

interface Props {
	value: ValidatedField<string>,
	onValueChange: (value: string) => void,
	label: string,
	isNumber?: boolean,
	className?: string,
	disabled?: boolean
}

const TextInput: React.FunctionComponent<Props> = ({ value, onValueChange, label, isNumber, className, disabled }) => {
	const [inputValue, setInputValue] = React.useState(value.value || "");

	const propsInitialized = React.useRef(false);
	React.useEffect(() => {
		if (!propsInitialized.current) { propsInitialized.current = true; return; }
		setInputValue(value.value || "")
	}, [value.value]);

	const onFinish = () => {
		onValueChange(inputValue);
	}

	const colors = disabled ? "bc0 c16" : "";

	return <Column className={"flex1 " + className} >
		<div className="font14">{label}</div>
		<input
			size={5}
			disabled={disabled}
			type={isNumber ? "number" : "text"}
			className={"font18 semibold segoe " + colors}
			value={inputValue}
			style={{ height: 35, lineHeight: "35px", paddingLeft: 8, border: "1px solid #dddddd" }}
			onKeyDown={event => { if (event.key === 'Enter') onFinish(); }}
			onBlur={onFinish}
			onChange={(event) => {
				setInputValue(event.target.value);
			}} />
		{!disabled && !value.hideError && value.error && <Row mainAxis="flex-end" className="font11 italic semibold error-blink">{value.error}</Row>}
	</Column>
}


export default TextInput;