import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import AutoResizingInput from "./AutoResizingInput";
import ChartTypeMenu from "./ChartTypeMenu";
import { HiArrowDown, HiArrowUp, HiTrash } from "/node_modules/react-icons/hi2/index";
import TokenMenu from "./TokenMenu";
import { ChartMode, EditingCallbacks, IndicatorToken, MovingCallbacks } from "../EditorModels";
import TokenText from "../TokenText";

interface Props {
	isSelected: boolean,
	condition: IndicatorToken,
	editingCallbacks: EditingCallbacks,
	movingCallbacks: MovingCallbacks,
	value: number
}


const BooleanCodeLine: React.FunctionComponent<Props> = (props) => {
	return <Row style={{ paddingRight: 10 }} crossAxis="center">
		<TokenText key={"codeline_" + 0} token={props.condition} />
		<div className={"mleft8" + (props.value === 1 ? " bg-panel" : " bg-button")}
			style={{ visibility: props.isSelected ? "hidden" : "visible", borderRadius: 8, width: 13, height: 13 }}
		/>

		{props.isSelected && <Row mainAxis="flex-end" style={{ position: "absolute", right: 4 }} >
			<TokenMenu items={[
				{ icon: <HiTrash style={{ height: 17, width: 17 }} />, text: "Delete", action: () => { }, isEnabled: true },
			]} />
		</Row>}
	</Row>
}

export default BooleanCodeLine;