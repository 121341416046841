import * as React from "react";
import { ChartCanvas, Chart, series } from "react-stockcharts";
import { BarSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { fitWidth } from "react-stockcharts/lib/helper";
import { Position, DealStats, RelativeProfitBucket } from "/src/services/trading";
import { scaleLinear } from "d3-scale";
import { niceNumber, signedPercent } from "/src/utils/numbers";
import { LabelAnnotation, Label, Annotate } from "react-stockcharts/lib/annotation";
import { FormattedMessage } from "react-intl";
import { Row } from "/src/components/Layout";
import { ThemeContext } from "/src/components/ThemeProvider";


class TradeBucketsChart extends React.Component<{ type: any, width: any, height: any, ratio: any, buckets: RelativeProfitBucket[] }> {
	render() {
		const { type, width, ratio, height, buckets } = this.props;

		if (!buckets || buckets.length < 1) {
			return <Row mainAxis="center" crossAxis="center" style={{ height: height }}>
				<FormattedMessage id="strategy.not_enough_for_histogram" />
			</Row>
		}

		buckets.sort((a, b) => a.bucket - b.bucket)

		let maxPercent = buckets.reduce<number>((n, s) => Math.max(s.bucket, n), 0);
		let minPercent = buckets.reduce<number>((n, s) => Math.min(s.bucket, n), 0);

		const [yAxisLabelX, yAxisLabelY] = [
			width - 0 - 20,
			(height - 0 - 20) / 2
		];

		const dif = maxPercent - minPercent;
		if (dif < 10) {
			maxPercent += dif / 2;
			minPercent -= dif / 2;
		}

		const barWidth = ((width - 60) / (1 + (maxPercent - minPercent))) * 1;

		return <ThemeContext.Consumer>
			{(theme) => <div style={{ height: height }}>
				<ChartCanvas metaId={1} ratio={ratio} width={width} height={height}
					type="hybrid"
					zoomEvent={false}
					useCrossHairStyleCursor={false}
					margin={{ left: 0, right: 60, top: 0, bottom: 20 }}
					seriesName="MSFT"
					xExtents={[minPercent, maxPercent]}
					data={buckets}
					xAccessor={d => d ? (d.bucket >= 0 ? d.bucket - 0.5 : d.bucket + 0.5) : 0}
					displayXAccessor={d => d.bucket >= 0 ? d.bucket - 0.5 : d.bucket + 0.5}
					xScale={scaleLinear()}
					padding={barWidth / 2}
					disableInteraction={true}>

					<Chart id={0} yExtents={d => [0, d.count * 1.05]}>
						<Label x={yAxisLabelX} y={yAxisLabelY}
							rotate={-90}
							fontFamily="Open Sans"
							fill={theme.cssVar('--color-alt')}
							fontSize={12} text="Number of trades" />

						<XAxis
							zoomEnabled={false}
							axisAt="bottom"
							orient="bottom"
							fontFamily="Open Sans"
							fontSize={13}
							tickFormat={(x) => x.toFixed(0) + "%"}
							ticks={6} innerTickSize={-1000} tickStrokeOpacity={0.1}
							stroke={theme.cssVar('--bg-main')}
							tickStroke={theme.cssVar('--color-alt')} />
						<YAxis
							zoomEnabled={false}
							axisAt="right"
							orient="right"
							// tickInterval={5}
							// tickValues={[40, 60]}
							ticks={5} innerTickSize={-1000} tickStrokeOpacity={0.1}
							fontFamily="Open Sans"
							fontSize={13}
							stroke={theme.cssVar('--bg-main')}
							tickStroke={theme.cssVar('--color-alt')}
						/>

						<BarSeries
							yAccessor={d => d.count}
							strokeWidth={3}
							width={barWidth}
							opacity={0.8}
							fill={d => d.bucket < 0 ? theme.cssVar('--color-alt') : theme.cssVar('--color-main')} />


					</Chart>

				</ChartCanvas>


			</div >}</ThemeContext.Consumer>
	}
}

export default fitWidth(TradeBucketsChart);