import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { ChartMode, DataSource, Direction, EditingCallbacks, IToken, IndicatorToken, InstrumentToken, MovingCallbacks, Parameter, Timeframe, TokenType, TradeRuleToken } from "../EditorModels";
import ParameterInput from "../Panel/ParameterInput";
import NumberInput from "../Panel/NumberInput";
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import { IndicatorsCache } from "/src/services/indicatorsCache";
import { HiMiniPlusCircle, HiTrash } from "/node_modules/react-icons/hi2/index";

interface Props {
	tradeRule: TradeRuleToken,
	allOptions: IToken[],
	instrumentOptions: InstrumentToken[],
	editingCallbacks: EditingCallbacks,
	movingCallbacks: MovingCallbacks,
}

const createDefaultCondition = (allOptions: IToken[]): IndicatorToken => {
	return {
		functionName: "CrossDown",
		parameters: [allOptions[Math.max(0, allOptions.length - 1)].name, allOptions[Math.max(0, allOptions.length - 2)].name],
		name: undefined,
		position: undefined,
		dataIndex: undefined,
		chart: ChartMode.Own,
		type: TokenType.Indicator
	};
}

const TradeRuleEditingLine: React.FunctionComponent<Props> = props => {

	return <Column className="flex1">

		<Row className="font12 bold mtop10 mbottom3" mainAxis="center">
			<FormattedMessage id="picker.tradeRule.entryConditions" />
		</Row>
		{props.tradeRule.openConditions.map((c, ci) =>
			<Row key={"open" + ci} crossAxis="center" className="bg-main" style={{  flexWrap: "wrap", border: "1px solid #50586550", padding: "3px 7px 3px 7px", marginBottom: 1, borderRadius: 15 }}>
				<div>
					<ParameterInput
						options={props.allOptions.map(a => a.name)}
						value={c.parameters[0]}
						onValueChange={(value: Parameter) => {
							const updatedCondition = { ...c };
							updatedCondition.parameters[0] = value;

							const updated: TradeRuleToken = { ...props.tradeRule };
							updated.openConditions.splice(ci, 1, updatedCondition)
							props.editingCallbacks.onTradeRuleUpdated(updated);
						}} />
				</div>
				<div className="mleft4">
					<ParameterInput
						options={IndicatorsCache.searchIndicatorsByGroup(["Events", "Conditions"]).map(i => i.name)}
						value={c.functionName}
						onValueChange={(value: Parameter) => {
							const updatedCondition = { ...c };
							updatedCondition.functionName = value;

							const updated: TradeRuleToken = { ...props.tradeRule };
							updated.openConditions.splice(ci, 1, updatedCondition)
							props.editingCallbacks.onTradeRuleUpdated(updated);
						}} />
				</div>
				<div className="mleft4">
					<ParameterInput
						options={props.allOptions.map(a => a.name)}
						value={c.parameters[1]}
						onValueChange={(value: Parameter) => {
							const updatedCondition = { ...c };
							updatedCondition.parameters[1] = value;

							const updated: TradeRuleToken = { ...props.tradeRule };
							updated.openConditions.splice(ci, 1, updatedCondition)
							props.editingCallbacks.onTradeRuleUpdated(updated);
						}} />
				</div>
				<div className="flex1"></div>
				<Row mainAxis="center" crossAxis="center" className="pointer hoverable" style={{ width: 35, height: 35 }}
					onClick={() => {
						const updatedTradeRule: TradeRuleToken = { ...props.tradeRule };
						updatedTradeRule.openConditions.splice(ci, 1);
						props.editingCallbacks.onTradeRuleUpdated(updatedTradeRule);
					}}	>
					<HiTrash style={{ height: 17, width: 17 }} />
				</Row>
			</Row>
		)}
		<Row mainAxis="center" className="top10">
			<Row className="pointer" onClick={e => {
				const updatedTradeRule: TradeRuleToken = { ...props.tradeRule };
				updatedTradeRule.openConditions.push(createDefaultCondition(props.allOptions));
				props.editingCallbacks.onTradeRuleUpdated(updatedTradeRule);
			}}>
				<HiMiniPlusCircle style={{ height: "2rem", width: "2rem" }} />
			</Row>
		</Row>


		<Row className="font12 mtop10 bold mbottom3" mainAxis="center">
			<FormattedMessage id="picker.tradeRule.exitConditions" />
		</Row>
		{props.tradeRule.closeConditions.map((c, ci) =>
			<Row key={"close" + ci} crossAxis="center" className="mbottom5 bg-main" style={{ flexWrap: "wrap", border: "1px solid #50586550", padding: "10px 10px 5px 10px", borderRadius: 15 }}>
				<div>
					<ParameterInput
						options={props.allOptions.map(a => a.name)}
						value={c.parameters[0]}
						onValueChange={(value: Parameter) => {
							const updatedCondition = { ...c };
							updatedCondition.parameters[0] = value;

							const updated: TradeRuleToken = { ...props.tradeRule };
							updated.closeConditions.splice(ci, 1, updatedCondition)
							props.editingCallbacks.onTradeRuleUpdated(updated);
						}} />
				</div>
				<div className="mleft4">
					<ParameterInput
						options={IndicatorsCache.searchIndicatorsByGroup(["Events", "Conditions"]).map(i => i.name)}
						value={c.functionName}
						onValueChange={(value: Parameter) => {
							const updatedCondition = { ...c };
							updatedCondition.functionName = value;

							const updated: TradeRuleToken = { ...props.tradeRule };
							updated.closeConditions.splice(ci, 1, updatedCondition)
							props.editingCallbacks.onTradeRuleUpdated(updated);
						}} />
				</div>
				<div className="mleft4">
					<ParameterInput
						options={props.allOptions.map(a => a.name)}
						value={c.parameters[1]}
						onValueChange={(value: Parameter) => {
							const updatedCondition = { ...c };
							updatedCondition.parameters[1] = value;

							const updated: TradeRuleToken = { ...props.tradeRule };
							updated.closeConditions.splice(ci, 1, updatedCondition)
							props.editingCallbacks.onTradeRuleUpdated(updated);
						}} />
				</div>
				<div className="flex1"></div>
				<Row mainAxis="center" crossAxis="center" className="pointer hoverable" style={{ width: 35, height: 35 }}
					onClick={() => {
						const updatedInstrument: TradeRuleToken = { ...props.tradeRule };
						updatedInstrument.closeConditions.splice(ci, 1);
						props.editingCallbacks.onTradeRuleUpdated(updatedInstrument);
					}}	>
					<HiTrash style={{ height: 17, width: 17 }} />
				</Row>
			</Row>
		)}

		<Row mainAxis="center" className="top10">
			<Row className="pointer" onClick={e => {
				const updatedInstrument: TradeRuleToken = { ...props.tradeRule };
				updatedInstrument.closeConditions.push(createDefaultCondition(props.allOptions));
				props.editingCallbacks.onTradeRuleUpdated(updatedInstrument);
			}}>
				<HiMiniPlusCircle style={{ height: "2rem", width: "2rem" }} />
			</Row>
		</Row>

		<Row className="">
			<Row className="font12" style={{ lineHeight: "35px" }}><FormattedMessage id="picker.tradeRule.takeProfitPercent" /></Row>
			<Row className="">
				<NumberInput
					value={props.tradeRule.takeProfitPercent.toString()}
					onValueChange={(value: Parameter) => {
						const updatedInstrument: TradeRuleToken = { ...props.tradeRule };
						updatedInstrument.takeProfitPercent = parseFloat(value.toString());
						props.editingCallbacks.onTradeRuleUpdated(updatedInstrument);
					}} />
			</Row>
			<Row className="font12 mleft10" style={{ lineHeight: "35px" }}><FormattedMessage id="picker.tradeRule.stopLossPercent" /></Row>
			<Row className="">
				<NumberInput
					value={props.tradeRule.stopLossPercent.toString()}
					onValueChange={(value: Parameter) => {
						const updatedInstrument: TradeRuleToken = { ...props.tradeRule };
						updatedInstrument.stopLossPercent = parseFloat(value.toString());
						props.editingCallbacks.onTradeRuleUpdated(updatedInstrument);
					}} />
			</Row>
		</Row>
	</Column>
}

export default TradeRuleEditingLine;