import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import AutoResizingInput from "./AutoResizingInput";
import ChartTypeMenu from "./ChartTypeMenu";
import { HiArrowDown, HiArrowUp, HiTrash } from "/node_modules/react-icons/hi2/index";
import TokenMenu from "./TokenMenu";
import { ChartMode, Direction, EditingCallbacks, IndicatorToken, InstrumentToken, MovingCallbacks, Parameter, TradeRuleToken } from "../EditorModels";
import ParameterInput from "../Panel/ParameterInput";
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import NumberInput from "../Panel/NumberInput";

interface Props {
	isSelected: boolean,
	tradeRule: TradeRuleToken,
	instrumentOptions: InstrumentToken[],
	editingCallbacks: EditingCallbacks,
	movingCallbacks: MovingCallbacks
}

const TradeRuleCodeLine: React.FunctionComponent<Props> = (props) => {

	return <Row crossAxis="center" style={{ flexShrink: 0 }}>
		{props.isSelected
			? <Row>
				<ParameterInput
					localizationPrefix="tradeRule.direction."
					options={Object.keys(Direction).map(k => Direction[k])}
					value={props.tradeRule.direction.toString()}
					onValueChange={(value: Parameter) => {
						const updatedInstrument: TradeRuleToken = { ...props.tradeRule };
						updatedInstrument.direction = Direction[value.toString()];
						props.editingCallbacks.onTradeRuleUpdated(updatedInstrument);
					}} />
				<Row className="mleft4">
					<ParameterInput
						options={props.instrumentOptions.map(o => o.symbol)}
						value={props.tradeRule.instrument.symbol.substring(1)}
						onValueChange={(value: Parameter) => {
							const selectedInstruments = props.instrumentOptions.find(i => i.symbol === value);
							if (!selectedInstruments) {
								return;
							}

							const updatedTradeRule: TradeRuleToken = { ...props.tradeRule };
							updatedTradeRule.instrument = {
								symbol: ":" + selectedInstruments.symbol, exchange: selectedInstruments.exchange
							};
							props.editingCallbacks.onTradeRuleUpdated(updatedTradeRule);
						}} />
				</Row>


				<Row className="font12" crossAxis="center">
					<FormattedMessage id="picker.tradeRule.fundsPerTrade" />
					<Row>
						<NumberInput
							value={(props.tradeRule.fundsPerTrade * 100).toString()}
							onValueChange={(value: Parameter) => {
								const updatedInstrument: TradeRuleToken = { ...props.tradeRule };
								updatedInstrument.fundsPerTrade = parseFloat(value.toString()) / 100;
								props.editingCallbacks.onTradeRuleUpdated(updatedInstrument);
							}} />
					</Row>
					%
				</Row>

				<Row className="mleft10" crossAxis="center">
					<Row className="font12"><FormattedMessage id="picker.tradeRule.maxOpenTrades" /></Row>
					<Row className="">
						<NumberInput
							value={props.tradeRule.maxOpenTrades.toString()}
							onValueChange={(value: Parameter) => {
								const updatedInstrument: TradeRuleToken = { ...props.tradeRule };
								updatedInstrument.maxOpenTrades = parseFloat(value.toString());
								props.editingCallbacks.onTradeRuleUpdated(updatedInstrument);
							}} />
					</Row>
				</Row>

			</Row>
			: <div className="font20 semibold mright15">{props.tradeRule.direction.toString()} {props.tradeRule.instrument.symbol.substring(1)}</div>}

		{props.isSelected && <Row mainAxis="flex-end" >
			<TokenMenu items={[
				{ icon: <HiArrowUp style={{ height: 17, width: 17 }} />, text: "Move up", action: () => props.movingCallbacks.onMovedUp(props.tradeRule), isEnabled: props.movingCallbacks.canBeMovedUp },
				{ icon: <HiArrowDown style={{ height: 17, width: 17 }} />, text: "Move down", action: () => props.movingCallbacks.onMovedDown(props.tradeRule), isEnabled: props.movingCallbacks.canBeMovedDown },
				{ icon: <HiTrash style={{ height: 17, width: 17 }} />, text: "Delete", action: () => props.movingCallbacks.onDeleted(props.tradeRule), isEnabled: props.movingCallbacks.canBeDeleted },
			]} />
		</Row>}

	</Row>
}

export default TradeRuleCodeLine;