import * as React from "react";
import { Column, isDesktop, Row } from '/src/components/Layout'
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import candlesticks_white from '/src/assets/candlesticks_white.svg';
import indicator_white from '/src/assets/indicator_white.svg';
import dollar_white from '/src/assets/dollar_white.svg';
import testtube from '/src/assets/testtube.svg';
import { StrategyState } from "/src/services/strategies";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, onClick) {
	React.useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				onClick();
			}
		}
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);
}

interface Props {
	intl: any,
	onInstrumentAdding: () => void,
	onIndicatorAdding: () => void,
	onTradeRuleAdding: () => void,
}

interface State {
}


const PlusButton: React.FunctionComponent<Props> = ({ onInstrumentAdding, onIndicatorAdding, onTradeRuleAdding }) => {

	const wrapperRef = React.useRef(null);
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	useOutsideAlerter(wrapperRef, () => setIsOpen(false));

	return <div className={isDesktop ? "" : "mleft15"} style={{ pointerEvents: "auto" }} ref={wrapperRef}>
		<Row>
			<Row
				crossAxis="center"
				mainAxis="center"
				className={" top3 pointer border "} style={{
					transform: isOpen ? "rotate(45deg)" : "rotate(0deg)",
					transition: "transform 180ms",
					marginRight: 5, height: 35, width: 35, borderRadius: 50
				}} onMouseDown={(e) => { /*do not do unselecting when other line is selected*/ e.stopPropagation(); }}
				onClick={e => { setIsOpen(!isOpen); e.stopPropagation() }}>

				<div className="font25 semibold">+</div>
			</Row>

			{isOpen && <Row
				crossAxis="center"
				mainAxis="center"
				className={" top3 pointer border "} style={{
					marginRight: 5, height: 35, width: 100, borderRadius: 50
				}} onMouseDown={(e) => { /*do not do unselecting when other line is selected*/ e.stopPropagation(); }}
				onClick={e => { setIsOpen(false); onInstrumentAdding(); }}>
				<div className="font11 semibold"><FormattedMessage id="menu.plus_instrument" /></div>
			</Row>}

			{isOpen && <Row
				crossAxis="center"
				mainAxis="center"
				className={"top3 " + (onIndicatorAdding ? "color-main pointer border" : "color-alt border-alt")} style={{
					marginRight: 5, height: 35, width: 100, borderRadius: 50
				}} onMouseDown={(e) => { /*do not do unselecting when other line is selected*/ e.stopPropagation(); }}
				onClick={e => { if (onIndicatorAdding) { setIsOpen(false); onIndicatorAdding(); } }}>
				<div className="font11 semibold"><FormattedMessage id="menu.plus_indicator" /></div>
			</Row>}

			{isOpen && <Row
				crossAxis="center"
				mainAxis="center"
				className={"top3 " + (onTradeRuleAdding ? "pointer color-main border" : "color-alt border-alt")} style={{
					marginRight: 5, height: 35, width: 100, borderRadius: 50
				}} onMouseDown={(e) => { /*do not do unselecting when other line is selected*/ e.stopPropagation(); }}
				onClick={e => { if (onTradeRuleAdding) { setIsOpen(false); onTradeRuleAdding(); } }}>
				<div className="font11 semibold"><FormattedMessage id="menu.plus_trade_rule" /></div>
			</Row>}
		</Row>
	</div>

}


export default injectIntl(PlusButton);