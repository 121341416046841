import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import showcase from '/src/assets/showcase.png';
import SvgLogo from "/src/components/svg/Logo";

interface Props {
}

const LandingContainer: React.FunctionComponent<Props> = (props) =>
	isDesktop ?
		<Column className="" style={{ minHeight: "100vh", minWidth: 900 }}>
			<Row mainAxis="flex-end" crossAxis="center" className="mtop12 mright24">
				<Link to="/entrance/">
					<Row className="pointer nowrap semibold font14 pleft18 pright18 border" crossAxis="center" style={{ height: 36, borderRadius: 40 }}>
						<FormattedMessage id="landing.sign_in" />
					</Row>
				</Link>
			</Row>
			<Row style={{ marginTop: 60 }} className="">
				<Row className="flex1" mainAxis="flex-end" style={{ minWidth: "50%" }}>
					<Column className="flex1 mleft70" mainAxis="center" style={{ maxWidth: 600 }}>
						<a href="/">
							<div style={{ width: 177 }} >
								<SvgLogo />
							</div>
						</a>
						<Column className="mtop50" style={{ height: 400 }}>
							{props.children}
						</Column>
					</Column>
				</Row>
				<Row className="flex1 mtop70 mleft70" mainAxis="flex-end">
					<img src={showcase} style={{ width: 766 }} />
				</Row>
			</Row>
		</Column>
		:
		<Column className="" style={{ minHeight: "100vh" }}>
			<Column className="flex1 mleft30 mright30 mtop70">
				<a href="/">
					<div style={{ width: 177 }} >
						<SvgLogo />
					</div>
				</a>
				<Column className="mtop50" style={{ height: 300 }}>
					{props.children}
				</Column>
			</Column>
		</Column>



export default LandingContainer;