import * as React from "react";
import { initField, setField } from "./LegalSections/AllLegalData";
import Button from "/src/components/Button";
import TextInput from "/src/components/CreateAccountPage/TextInput";
import { Column, Row } from '/src/components/Layout'
import AuthenticationService from "/src/services/authentication";
import UsersService from "/src/services/users";
import { Result } from "/src/utils/result";
import SelectInput from "/src/components/CreateAccountPage/SelectInput";
import { Listbox } from "/node_modules/@headlessui/react/dist/index";

interface Props {
	history: any
}

const languages = [
	{ id: 1, name: 'English', unavailable: false },
]

const SettingsTab: React.FunctionComponent<Props> = (props) => {
	const [selectedPerson, setSelectedPerson] = React.useState(languages[0])

	return <Column className="top50 font18">
		<Row className="mtop10 mbottom10">Language</Row>
		<Listbox value={selectedPerson} onChange={setSelectedPerson}>
			<Listbox.Button className="pointer bold bg-button pleft15" style={{ height: 40, textAlign: "left" }}>{selectedPerson.name}</Listbox.Button>
			<Listbox.Options>
				{languages.map((person) => (
					<Listbox.Option
						className="pointer bg-button pleft15"
						style={{ height: 40, lineHeight: "40px" }}
						key={person.id}
						value={person}
						disabled={person.unavailable}
					>
						{person.name}
					</Listbox.Option>
				))}
			</Listbox.Options>
		</Listbox>
	</Column>
}

export default SettingsTab;