import rp from 'request-promise-native';
import AuthenticationService from './authentication';
import { serverUrl } from '/src/services/common'

export interface PublicUserModel {
	id: string,
	nickname: string
}

export interface UserModel extends PublicUserModel {
	email: string;
	groupId: string;
	currency: string;
	roles: string[];
}

const UsersService = {
	signUp: async (userInfo) => {
		const response = await rp({
			method: 'POST',
			json: true,
			uri: serverUrl + '/users/signup',
			body: userInfo
		});
		return response;
	},
	authenticate: async (credentials) => {
		const response = await rp({
			method: 'POST',
			json: true,
			uri: serverUrl + '/users/authenticate',
			body: credentials
		});
		return response;
	},
	getUserInfo: async (id): Promise<UserModel> => {
		const response = await rp({
			method: 'GET',
			uri: serverUrl + '/users/' + id,
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},
	update: async (id: string, updates: UserModel): Promise<UserModel> => {
		const response = await rp({
			method: 'POST',
			uri: serverUrl + '/users/' + id,
			json: true,
			headers: await AuthenticationService.getAuthHeader(),
			body: updates
		});
		return response;
	},
}

export default UsersService;