import rp from 'request-promise-native';
import AuthenticationService from '/src/services/authentication';
import { AllocationStats, Ticker, Position } from '/src/services/trading';
import { PublicUserModel } from '/src/services/users';
import { serverUrl } from '/src/services/common'
import ReconnectingEventSource from "/src/utils/ReconnectingEventSource";

export enum StrategyState {
	Created = 'Created',
	Backtesting = 'Backtesting',
	Backtested = 'Backtested',
	Ready = 'Ready'
}

export interface EquityUpdate {
	strategyId: string,
	user: string,
	value: number,
	timestamp: number
}

export interface StrategyInfo {
	id: string,
	author: string,
	name: string,
	description: string,
	state: StrategyState,
	backtestId: string,
	createdAt: string,
	updatedAt: string,
	backtestedAt: string,
	tags: string[],
	codeVersion: number,
}

export interface TimeValue {
	time: string,
	value: number
}

export interface EquityHistory {
	values: TimeValue[],
	relativeChange: number
}

export interface StrategyStatement {
	stats: AllocationStats,
	equityHistory: EquityHistory
}

export interface StrategyResponse {
	author: PublicUserModel,
	strategy: StrategyInfo,
	demoStatement: StrategyStatement,
	investmentStatement?: StrategyStatement
}

export interface Signal {
	signalId: string,
	strategyId: string,
	ruleId: string,
	currency: string,
	instrument: Ticker,
	relativeVolume: number,
	price: number,
	time: number
}

export type CandleArray = number[]

export interface ChartTimeItem {
	timestamp: number,
	values: CandleArray[],
	openDeals: Position[],
	closeDeals: Position[]
}

export interface ExpressTestResult {
	id: string,
	values: ChartTimeItem[],
	deals: Position[],
	equity: EquityHistory
}

export type ParameterType = 'BooleanStream' | 'OpenStream' | 'CloseStream' | 'DecimalStream' | "Equity" | "Integer" | 'TradeRule'

export interface ParameterModel {
	name: string,
	prefix: string,
	type: ParameterType
}

export interface IndicatorModel {
	name: string,
	group: string,
	sameSection: boolean,
	type: ParameterType,
	tags: string[],
	description: string,
	parameters: ParameterModel[]
}

export interface StrategyUpdater {
	name?: string,
	description?: string,
	state?: StrategyState
}

export enum SignalDirection {
	Open = 'Open',
	Close = 'Close'
}

export interface ManualSignal {
	ruleId: number;
	direction: SignalDirection;
}

export interface ProfitUpdate {
	id: string;
	closePrice: number;
	profit: number;
}
export interface DetailedEquity {
	equity: number;
	profitUpdates: ProfitUpdate[];
}
export enum TestPhase {
	WaitingInQueue = 'WaitingInQueue',
	PreparingData = 'PreparingData',
	Running = 'Running',
	Finished = 'Finished'
}
export interface TestStatus {
	phase: TestPhase;
	queuePosition: number;
	progressPercent: number;
}
export interface StrategySse {
	history: ChartTimeItem[];
	demoPosition: Position;
	detailedEquity: DetailedEquity;
	testStatus: TestStatus;
}

const StrategyService = {
	getIndicators: async (): Promise<IndicatorModel[]> => {
		const response = await rp({
			method: 'GET',
			uri: serverUrl + '/strategies/indicators',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	create: async () => {
		const response = await rp({
			method: 'POST',
			uri: serverUrl + '/strategies',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	update: async (strategyId: string, updates: StrategyUpdater) => {
		const response = await rp({
			method: 'POST',
			uri: serverUrl + '/strategies/' + strategyId,
			json: true,
			body: updates,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	activateAllocation: async (strategyId: string) => {
		const response = await rp({
			method: 'POST',
			uri: serverUrl + '/strategies/' + strategyId + '/activateAllocation',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	stopAllocation: async (strategyId: string) => {
		const response = await rp({
			method: 'POST',
			uri: serverUrl + '/strategies/' + strategyId + '/stopAllocation',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	exitAllocation: async (strategyId: string) => {
		const response = await rp({
			method: 'POST',
			uri: serverUrl + '/strategies/' + strategyId + '/exitAllocation',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	delete: async (strategyId) => {
		const response = await rp({
			method: 'DELETE',
			uri: serverUrl + '/strategies/' + strategyId,
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	getMyStrategies: async (): Promise<StrategyResponse[]> => {
		const response = await rp({
			method: 'GET',
			uri: serverUrl + '/strategies',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	getRecommendedStrategies: async () => {
		const response = await rp({
			method: 'GET',
			uri: serverUrl + '/strategies/recommended',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	favourite: async (strategyId) => {
		const response = await rp({
			method: 'POST',
			uri: serverUrl + '/strategies/' + strategyId + '/favourite',
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	unfavourite: async (strategyId) => {
		const response = await rp({
			method: 'POST',
			uri: serverUrl + '/strategies/' + strategyId + '/unfavourite',
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	getStrategyInfo: async (id): Promise<StrategyResponse> => {
		const response = await rp({
			method: 'GET',
			uri: serverUrl + '/strategies/' + id,
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	getStrategyInvestmentStats: async (id): Promise<StrategyStatement> => {
		const response = await rp({
			method: 'GET',
			uri: serverUrl + '/strategies/' + id + '/investment',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	subscribeForEquities: (ids: string, demo: boolean, onMessage: (message: string) => void): EventSource => {
		if (ids.length == 0) {
			return null;
		}
		const evtSource = new ReconnectingEventSource(async () => {
			const sseCode = await AuthenticationService.getSseCode();
			return serverUrl + '/strategies/subscribeForEquities/' + ids + "?code=" + sseCode + "&demo=" + demo
		});

		evtSource.onmessage = function (event) {
			onMessage(event.data);
		}
		return evtSource;
	},

	getStrategyUpdates: async (id: string, queryKey: string, onMessage: (message: string) => void): Promise<EventSource> => {
		const evtSource = new ReconnectingEventSource(async () => {
			const sseCode = await AuthenticationService.getSseCode();
			return serverUrl + '/strategies/' + id + "/updates?code=" + sseCode
		});
		evtSource.onmessage = function (event) {
			onMessage(event.data);
		}
		return evtSource;
	},

	getStrategyCode: async (id: string, queryKey: string) => {
		const response = await rp({
			method: 'GET',
			uri: serverUrl + '/strategies/' + id + "/code" + (queryKey !== undefined ? ("?key=" + queryKey) : ""),
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	getHistory: async (strategyId, codeVersion): Promise<ChartTimeItem[]> => {
		const response = await rp({
			method: 'GET',
			uri: serverUrl + '/strategies/' + strategyId + '/history/' + codeVersion,
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	backtest: async (strategyId, codeLines): Promise<TestStatus> => {
		const response = await rp({
			method: 'POST',
			uri: serverUrl + '/strategies/' + strategyId + '/backtest',
			body: codeLines,
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	forceSignal: async (strategyId, manualSignal: ManualSignal): Promise<number> => {
		const response = await rp({
			method: 'POST',
			uri: serverUrl + '/strategies/' + strategyId + '/forceSignal',
			body: manualSignal,
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	expressTest: async (strategyId, codeLines): Promise<ExpressTestResult> => {
		const response = await rp({
			method: 'POST',
			uri: serverUrl + '/strategies/' + strategyId + '/expresstest',
			body: codeLines,
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	continueExpressTest: async (strategyId, expressTestId) => {
		const response = await rp({
			method: 'POST',
			uri: serverUrl + '/strategies/' + strategyId + '/expresstest/' + expressTestId,
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	allocate: async (strategyId, amount) => {
		const response = await rp({
			method: 'POST',
			json: true,
			uri: serverUrl + '/strategies/allocate',
			body: { strategyId, amount },
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	}
}

export default StrategyService;