import { IndicatorModel, ParameterType, SignalDirection } from '/src/services/strategies';
import { Ticker } from '/src/services/trading';

//export type Token = (string | Token)[];
export type Parameter = string | number | FunctionCall
export type FunctionCall = [string, Parameter, Parameter?, Parameter?, Parameter?, Parameter?, Parameter?, Parameter?, Parameter?]

export type RegisteredCallback = (line: LineModel) => void

export enum StrategyTab { Stats = 'Stats', Rules = 'Rules', Investors = 'Investors', Positions = 'Positions' }
export enum PanelButtons { Common = 'Common', Editing = 'Editing' }
export enum ChartMode { Own = 'Own', Shared = 'Shared', Hidden = 'Hidden' }
export enum DataSource { Price = "Price", Bid = "Bid", Ask = "Ask" }
export enum BarField { Open = "Open", High = "High", Low = "Low", Close = "Close" }
export enum Timeframe { m1 = "1m", m5 = "5m", h1 = "1h", d1 = "1d" }
export enum Direction { Buy = "Buy", Sell = "Sell" }

export interface LineModel {
	name: string,
	value: FunctionCall,
	type?: ParameterType,
	index?: number,
	chart?: ChartMode,
}

export interface ISelectable {
}

export enum TokenType { Instrument, Indicator, TradeRule }

export interface IToken extends ISelectable {
	type: TokenType,
	name: string,
	position: number,
	dataIndex: number,
	precision: number
}

export interface InstrumentToken extends IToken {
	symbol: string,
	exchange: string,
	source: DataSource,
	timeframe: Timeframe,
}

export interface IndicatorToken extends IToken {
	functionName: string,
	parameters: Parameter[],
	chart: ChartMode
}

export interface TradeRuleToken extends IToken {
	instrument: Ticker,
	direction: Direction,
	openConditions: IndicatorToken[],
	closeConditions: IndicatorToken[],
	fundsPerTrade: number,
	maxOpenTrades: number,
	takeProfitPercent: number,
	stopLossPercent: number
}

function CreateInstrumentToken(raw: LineModel, position: number, dataIndex: number): InstrumentToken {
	return {
		symbol: raw.value[1][1],
		exchange: raw.value[1][2],
		source: raw.value[1][3].toString() as DataSource,
		timeframe: raw.value[2].toString() as Timeframe,
		name: raw.name,
		position: position,
		dataIndex: dataIndex,
		type: TokenType.Instrument
	};
}

function CreateIndicatorToken(raw: LineModel, position: number, dataIndex: number): IndicatorToken {
	return {
		name: raw.name,
		functionName: raw.value[0],
		chart: raw.chart,
		parameters: raw.value.slice(1).map(rawP => rawP),
		position: position,
		dataIndex: dataIndex,
		type: TokenType.Indicator
	}
}

function CreateTradeRuleToken(raw: LineModel, allOptions: IndicatorToken[], position: number, dataIndex: number): TradeRuleToken {
	const result = {
		instrument: { exchange: raw.value[3].toString(), symbol: raw.value[4].toString() },
		direction: Direction[raw.value[0]],
		openConditions: raw.value[1].slice(1).map(conditionName => allOptions.find(o => o.name == conditionName)),
		closeConditions: raw.value[2].slice(1).map(conditionName => allOptions.find(o => o.name == conditionName)),
		fundsPerTrade: parseFloat(raw.value[5].toString()),
		maxOpenTrades: parseFloat(raw.value[6].toString()),
		takeProfitPercent: parseFloat(raw.value[7].toString()),
		stopLossPercent: parseFloat(raw.value[8].toString()),
		position: position,
		dataIndex: dataIndex,
		type: TokenType.TradeRule,
		name: undefined
	}
	return result;
}


export function InstrumentTokenToLineModel(model: InstrumentToken): LineModel {
	return {
		name: model.name,
		value: ["Bar",
		["Data", model.symbol, model.exchange, model.source.toString()],
		model.timeframe.toString()],
		chart: ChartMode.Own,
		index: model.index
	};
}

export function IndicatorTokenToLineModel(model: IndicatorToken): LineModel {
	return {
		name: model.name,
		value: [model.functionName, ...model.parameters],
		chart: model.chart,
		index: model.index
	};
}

export function TradeRuleTokenToLineModels(model: TradeRuleToken): LineModel[] {

	const entries: LineModel[] = model.openConditions.map(c => {
		return {
			name: "_entry" + Math.floor(Math.random() * 10000000), value: [c.functionName, ...c.parameters], chart: 0, index: model.index, type: 'BooleanStream'
		}
	});
	const exits: LineModel[] = model.closeConditions.map(c => {
		return {
			name: "_entry" + Math.floor(Math.random() * 10000000), value: [c.functionName, ...c.parameters], chart: 0, index: model.index, type: 'BooleanStream'
		}
	});

	const tradeRule: LineModel = {
		name: undefined,
		value: [model.direction,
		["And", ...entries.map(e => e.name)],
		["And", ...exits.map(e => e.name)],
		model.instrument.exchange,
		model.instrument.symbol,
		model.fundsPerTrade,
		model.maxOpenTrades,
		model.takeProfitPercent,
		model.stopLossPercent],
		chart: ChartMode.Own
	};
	const result = entries.concat(exits).concat(tradeRule);
	return result;
}

export interface SectionModel {
	tokens: IToken[],
	type: TokenType
}

export function TokensToLines(tokens: IToken[]): LineModel[] {
	const result: LineModel[] = [];
	tokens.forEach((t, i) => {
		t.position = i;
		if (t.type == TokenType.Instrument) {
			t.dataIndex = result.length;
			result.push(InstrumentTokenToLineModel(t as InstrumentToken));
		}
		if (t.type == TokenType.Indicator) {
			t.dataIndex = result.length;
			result.push(IndicatorTokenToLineModel(t as IndicatorToken));
		}
		if (t.type == TokenType.TradeRule) {
			const rule: TradeRuleToken = t as TradeRuleToken;
			let counter = result.length;
			rule.openConditions.forEach(c => {
				c.dataIndex = counter++;
			});
			rule.closeConditions.forEach(c => {
				c.dataIndex = counter++;
			});
			rule.dataIndex = counter;
			result.push(...TradeRuleTokenToLineModels(rule));
		}
	});
	//console.log({from: tokens, to: result});
	return result;
}

export function LinesToTokens(lines: LineModel[]): IToken[] {
	const result: IToken[] = [];
	let conditions: IndicatorToken[] = [];
	lines.forEach((l, dataIndex) => {
		if (l.value[0] == "Bar") {
			result.push(CreateInstrumentToken(l, result.length, dataIndex));
		}
		else if (l.value[0] == "Buy" || l.value[0] == "Sell") {
			result.push(CreateTradeRuleToken(l, conditions, result.length, dataIndex));
			conditions = [];
		}
		else {
			const indicator = CreateIndicatorToken(l, result.length, dataIndex);
			if (indicator.name.startsWith('_')) {
				conditions.push(indicator);
			}
			else {
				result.push(indicator);
			}
		}
	})
	return result;
}

export interface EditingCallbacks {
	onInstrumentAdding: () => void,
	onInstrumentAdded: (instrument: IToken) => void,
	onInstrumentEdit: (instrument: IToken) => void,
	onInstrumentUpdated: (instrument: IToken) => void,

	onIndicatorAdding: () => void,
	onIndicatorEdit: (indicator: IToken) => void,
	onIndicatorUpdated: (indicator: IToken) => void,
	onIndicatorSelected: (indicator: IToken) => void,

	onTradeRuleAdding: () => void,
	onTradeRuleUpdated: (tradeRule: IToken) => void
}

export interface MovingCallbacks {
	canBeMovedUp: boolean,
	canBeMovedDown: boolean,
	canBeDeleted: boolean,
	onMovedUp: (line: IToken) => void,
	onMovedDown: (line: IToken) => void,
	onDeleted: (line: IToken) => void,
	onInstrumentAdding: () => boolean,
	onIndicatorAdding: () => boolean,
	onTradeRuleAdding: () => boolean
}