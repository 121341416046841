import * as React from "react";
import { Link } from "react-router-dom";
import { Column, Row } from '/src/components/Layout'
import dotsmove from '/src/assets/3-dots-move.svg';

interface Props {
	label: string,
	disabled?: boolean,
	className?: string,
	onClick?: () => any,
	link?: string,
	icon?: any
}

const Button: React.FunctionComponent<Props> = (props) => {
	const [pressed, setPressed] = React.useState<Boolean>(false);

	let cla = "white ";
	if (props.disabled) {
		cla += "bc10 "
	}
	else {
		if (pressed) {
			cla += "bc15 scale98 "
		}
		else {
			cla += "bg-panel hover17 pointer "
		}
	}
	cla += props.className;


	return props.link ? <Link to={props.link}>
		<Row mainAxis="center" crossAxis="center" className={cla} style={{ height: 35, padding: "0px 15px", minWidth: 100, borderRadius: 4 }}>
			{props.icon && <img style={{ width: 22, height: 22, marginRight: 7 }} src={props.icon} alt="" />}
			{props.label}
		</Row>
	</Link> : <Row mainAxis="center" crossAxis="center" className={cla}
		style={{ height: 35, padding: "0px 15px", minWidth: 100, borderRadius: 4 }}
		onClick={async () => {
			if (!props.onClick || props.disabled === true || pressed) {
				return;
			}
			setPressed(true);
			await props.onClick();
			setPressed(false);
		}}
	>
		{pressed ? <img src={dotsmove} /> :
			<Row crossAxis="center">
				{props.icon && <img style={{ width: 22, height: 22, marginRight: 7 }} src={props.icon} alt="" />}
				<div>{props.label}</div>
			</Row>
		}
	</Row>
}

export default Button;