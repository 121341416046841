import rp from 'request-promise-native';
import { serverUrl } from '/src/services/common'
import AuthenticationService from '/src/services/authentication';
import ReconnectingEventSource from "/src/utils/ReconnectingEventSource";
import { Transaction } from "./wallets";
import { Position } from "./trading";
import { Account } from './accounts';
import { UserModel } from './users';

export interface Activity {
	transaction: Transaction;
	position: Position;
	account: Account;
	user: UserModel;
	balance?: number;
	invested?: number;
}

const EventsService = {
	subscribeForActivities: async (onMessage: (message: string) => void): Promise<EventSource> => {
		const evtSource = new ReconnectingEventSource(async () => {
			const sseCode = await AuthenticationService.getSseCode();
			return serverUrl + '/events/activities?code=' + sseCode
		});
		evtSource.onmessage = function (event) {
			onMessage(event.data);
		}
		return evtSource;
	},
}

export default EventsService;