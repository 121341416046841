import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import { ChartTimeItem, StrategyInfo, StrategyState } from '/src/services/strategies'
import { FormattedMessage, FormattedDate } from 'react-intl';
import TradingService, { Position, PositionStatus } from "/src/services/trading"
import { LineModel, SectionModel, ISelectable, IToken, TokenType, IndicatorToken, TradeRuleToken, MovingCallbacks, ChartMode, EditingCallbacks, InstrumentToken } from './EditorModels'
import Section from "./Section";
import TradeSection from "./TradeSection";
import PlusButton from "./PlusButton";
import TimeAgo from 'react-timeago'
import { PanelType } from "./Panel/SidePanel";
import Button from "/src/components/Button";
interface Metasubscription {
	eventType: string,
	subscriberId: number
	metaListener: (data: any) => void
}

interface Props {
	selected: ISelectable,
	tokens: IToken[],
	positions: Position[],
	charts: ChartTimeItem[],
	onSelect: (token: IToken) => void,
	onUnselect: (token: IToken) => void,
	instrumentOptions: InstrumentToken[],
	editable: boolean,
	movingCallbacks: MovingCallbacks,
	strategyInfo: StrategyInfo,
	onBacktest: () => void,
	hasTradeRules: boolean,
	allOptions: IToken[],
	editingCallbacks: EditingCallbacks
}

interface State {
	hoveredItem?: ChartTimeItem
}

class Plot extends React.Component<Props, State> {

	metaSubscriptions: Metasubscription[]

	constructor(props: Props) {
		super(props);
		this.state = {
			hoveredItem: undefined
		};
		this.metaSubscriptions = [];
	}

	componentDidMount() {
		document.addEventListener('keydown', this.handleKey);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKey);
	}

	handleKey = (e: KeyboardEvent) => {
		if (e.altKey && e.code == 'KeyQ') {
			this.props.movingCallbacks.onInstrumentAdding() && e.preventDefault()
		}
		if (e.altKey && e.code == 'KeyW') {
			this.props.movingCallbacks.onIndicatorAdding() && e.preventDefault()
		}
		if (e.altKey && e.code == 'KeyE') {
			this.props.movingCallbacks.onTradeRuleAdding() && e.preventDefault()
		}
		if (e.altKey && e.key == 'Delete') {
			this.props.movingCallbacks.canBeDeleted && this.props.movingCallbacks.onDeleted(this.props.selected as IToken)
		}
		if (e.altKey && e.key == 'ArrowUp') {
			this.props.movingCallbacks.canBeMovedUp && this.props.movingCallbacks.onMovedUp(this.props.selected as IToken)
		}
		if (e.altKey && e.key == 'ArrowDown') {
			this.props.movingCallbacks.canBeMovedDown && this.props.movingCallbacks.onMovedDown(this.props.selected as IToken)
		}
	}

	lineBelongsToSection(token: IToken, section: SectionModel): boolean {
		return ((section !== undefined) && (token.type == TokenType.Indicator) && ((token as IndicatorToken).chart !== ChartMode.Own));
	}

	metaSubscribe = (eventType, subscriberId, metaListener) => {
		this.metaSubscriptions = this.metaSubscriptions.concat({ eventType, subscriberId, metaListener });
	}

	triggerMetaEvent = (eventType, senderId, eventData) => {
		this.metaSubscriptions
			.filter(each => each.eventType === eventType && each.subscriberId !== senderId)
			.forEach(each => { each.metaListener(eventData); });
	}

	onHover = (hoveredItem: ChartTimeItem) => {
		this.setState({ hoveredItem: hoveredItem })
	}

	renderTradeRuleSection = (token: TradeRuleToken, sectionIndex: number) =>
		<TradeSection key={sectionIndex}
			instrumentOptions={this.props.instrumentOptions}
			token={token}
			selected={this.props.selected as IToken}
			chartData={this.props.charts}
			onSelect={this.props.onSelect}
			editable={this.props.editable}
			metaSubscribe={this.metaSubscribe}
			triggerMetaEvent={this.triggerMetaEvent}
			metaId={sectionIndex}
			onHover={this.onHover}
			hoveredItem={this.state.hoveredItem}
			allTokens={this.props.allOptions}
			editingCallbacks={this.props.editingCallbacks}
			movingCallbacks={this.props.movingCallbacks}
		/>

	renderSection = (section: SectionModel, sectionIndex: number) =>
		<Section key={sectionIndex}
			selected={this.props.selected as IToken}
			tokens={section.tokens}
			editable={this.props.editable}
			allTokens={this.props.allOptions}
			chartData={this.props.charts}
			onSelect={this.props.onSelect}
			metaSubscribe={this.metaSubscribe}
			triggerMetaEvent={this.triggerMetaEvent}
			metaId={sectionIndex}
			onHover={this.onHover}
			hoveredItem={this.state.hoveredItem}
			editingCallbacks={this.props.editingCallbacks}
			movingCallbacks={this.props.movingCallbacks}
		/>

	renderTokens = (tokens: IToken[]) => {

		const preparedSections: SectionModel[] = tokens.reduce((sections: SectionModel[], token: IToken, index: number) => {

			if (this.lineBelongsToSection(token, sections[sections.length - 1])) {
				sections[sections.length - 1].tokens.push(token);
			}
			else {
				sections.push({ type: token.type, tokens: [token] })
			}

			return sections;
		}, []);
		const result = [];

		preparedSections.map((section: SectionModel, sectionIndex) => {
			result.push(section.type === TokenType.TradeRule
				? this.renderTradeRuleSection(section.tokens[0] as TradeRuleToken, sectionIndex)
				: this.renderSection(section, sectionIndex));
		});

		return result;
	}

	plotEnd: HTMLDivElement

	scrollToBottom = () => {
		this.plotEnd.scrollIntoView({ behavior: "smooth" });
	}

	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.tokens.length > this.props.tokens.length) {
			this.scrollToBottom();
		}
	}

	render() {

		const columnStyle = isDesktop
			? { flex: 1, position: "relative", marginTop: 30 }
			: { width: "100%", position: "relative", marginTop: 30 };

		return <Row mainAxis="center">
			<Column style={columnStyle} className="editor-width">

				<Row mainAxis="flex-end" crossAxis="center" className="mbottom20">
					<Column className="mright20 font14" crossAxis="flex-end">
						<Button disabled={!this.props.hasTradeRules} label="Run test" onClick={this.props.onBacktest}>Test</Button>
						<Row className="mtop3">
							{this.props.strategyInfo.state === StrategyState.Backtested &&
								<Row><div>Tested&nbsp;</div><TimeAgo date={this.props.strategyInfo.backtestedAt} /></Row>
							}
							{this.props.strategyInfo.state === StrategyState.Created && this.props.tokens.length > 0 &&
								<Row><div>Modified&nbsp;</div><TimeAgo date={this.props.strategyInfo.updatedAt} /></Row>
							}
						</Row>
					</Column>
				</Row>

				{this.renderTokens(this.props.tokens)}

				{this.props.editable &&
					<PlusButton
						onInstrumentAdding={this.props.movingCallbacks.onInstrumentAdding}
						onIndicatorAdding={this.props.tokens.length > 0 ? this.props.movingCallbacks.onIndicatorAdding : undefined}
						onTradeRuleAdding={this.props.tokens.length > 1 ? this.props.movingCallbacks.onTradeRuleAdding : undefined}
					/>
				}



				<div style={{ width: 1, height: 300 }}>{/* it provides room above the scroll destination */}</div>
				<div style={{ width: 1, height: 1 }} ref={(el) => { this.plotEnd = el; }}></div>


				{/*<Row className="bg-main" style={{
					zIndex: 10,
					borderRadius: 20,
					border: "1px solid black",
					height: 500,
					width: 600,
					marginLeft: 50,
					position: "fixed",
				}}>
					DIALOG
				</Row>*/}
			</Column>
		</Row>
	}
}

export default Plot;