import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import StrategyThumbnailChart from './StrategyThumbnailChart'
import { renderMonth, niceNumber, niceSignedNumber, signedPercent } from '/src/utils/numbers';
import { Link } from "react-router-dom";
import { StrategyResponse } from '/src/services/strategies'
import { FormattedMessage, FormattedDate } from 'react-intl';
import { ThemeContext } from "/src/components/ThemeProvider";

interface Props {
	data: StrategyResponse,
	ownStrategy: boolean
}

const DemoedStrategy: React.FunctionComponent<Props> = props => <ThemeContext.Consumer>
	{(theme) => <Link to={{ pathname: "/strategy/" + props.data.strategy.id, state: props.data }}>
		<Column className="up-border1" style={{ height: "14.4rem" }}>
			<Row crossAxis="baseline" style={{ marginTop: "0.8rem" }}>
				<div className="font18 segoe ">{props.data.strategy.name.toUpperCase()}</div>
				<div className="flex1"></div>
				<div className="font22 semibold">{signedPercent(props.data.demoStatement.equityHistory.relativeChange, 1)}</div>
			</Row>
			<Row crossAxis="baseline">
				<div className="font13">{props.data.author.nickname}</div>
				<div className="flex1"></div>
				{
					props.data.demoStatement.equityHistory.values.length > 0 &&
					<div className="font13">{renderMonth(props.data.demoStatement.equityHistory.values[props.data.demoStatement.equityHistory.values.length - 1].time)}</div>
				}
			</Row>
			<Row>
				<Column style={{ flex: 1 }}>
					<div className="flex1 top3"></div>
					<Row>{props.data.strategy.tags && props.data.strategy.tags.map(t => {
						return <div key={t} className="font14 segoe italic lightgray right3" style={{ padding: "0.2rem" }}>#{t}</div>;
					})}</Row>
				</Column>
				{
					props.data.demoStatement.equityHistory.values.length > 2 &&
					<div style={{ flex: 2, marginTop: "1.3rem", height: "6.6rem", backgroundColor: "#f000" }}>
						<StrategyThumbnailChart data={props.data.demoStatement.equityHistory.values} color={theme.cssVar("--color-main")} filled={false} />
					</div>
				}
			</Row>
		</Column >
	</Link>}
</ThemeContext.Consumer>

export default DemoedStrategy;