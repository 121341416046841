export default {
	'en': {
		'investment.allTimeInvestmentsResults': 'ALL TIME INVESTMENT RESULTS',
		'investment.equity': 'equity',
		'investment.netProfit': 'net profit',
		'investment.netAllocated': 'net allocated',
		'investment.firstAllocation': 'TOTAL RESULT SINCE ',
		'investment.trailingStop': 'TRAILING STOP',
		'investment.trailingStopLevel': 'TRAILING STOP LEVEL',
		'investment.investMore': 'INVEST MORE',
		'investment.investLess': 'EXIT INVESTMENT',
		'investment.currentStatement': 'CURRENT STATEMENT',
		'investment.balance': 'balance',
		'investment.floatingProfit': 'floating profit',
		'investment.margin': 'margin',
		'investment.marginLevel': 'margin level',
		'investment.activeTrades': 'active trades',
		'investment.currentPositions': 'CURRENT POSITIONS',
		'investment.history': 'HISTORY',
		'investment.isActive': 'INVESTMENT IS ACTIVE',
		'investment.stopNow': 'STOP AND CLOSE',
		'investment.isStopped': 'INVESTMENT IS STOPPED',
		'investment.activateNow': 'ACTIVATE NOW',
		'investment.strategyNotReady': 'This strategy is not ready for investments yet',

		'trade.volume': 'Volume',
		'trade.open': 'Open',
		'trade.opening': 'Opening',
		'trade.closing': 'Closing',
		'trade.closed': 'Closed',
		'trade.rejected': 'Rejected',
		'rejected.none': 'Unknown',
		'rejected.notEnoughFunds': 'Not enough funds',
		'rejected.minVolume': 'Min volume',
		'rejected.closeOnly': 'Only closing trades is allowed for that instrument',
		'rejected.buyOnly': 'Only buy trades are allowed for that instrument',
		'rejected.tradingDisabled': 'Trading is disabled for that instrument',
		'rejected.notAllowed': 'Trading is not allowed for that instrument',

		'strategy.tab.strategy': 'STRATEGY',
		'strategy.tab.investment': 'INVESTMENT',
		'strategy.tab.investors': 'INVESTORS',

		'strategy.addDescription': '+ Add description',
		'strategy.month': 'last month',
		'strategy.lastYear': 'last year',
		'strategy.investInThis': 'INVEST IN THIS STRATEGY',
		'strategy.subscribe': 'SUBSCRIBE',
		'strategy.unsubscribe': 'UNSUBSCRIBE',

		

		'home.tab.strategies': 'Strategies',
		'home.tab.wallet': 'Wallet',
		'home.tab.profile': 'Profile',
		'home.tab.sign_up': 'Sign up',

		'wallet.withdraw': 'WITHDRAW',
		'wallet.deposit': 'DEPOSIT',

		'dashboard.freeFunds': 'AVAILABLE',
		'dashboard.invested': 'INVESTED',
		'dashboard.newStrategy': 'CREATE A STRATEGY',
		'dashboard.noStrategiesYet': 'You have no own strategies yet',
		'dashboard.recommendedStrategies': 'SELECTION OF STRATEGIES',

		'transaction.depositedVia': 'Deposited via {provider}',
		'transaction.withdrawnTo': 'Withdrawn to {provider}',
		'transaction.investedTo': 'Invested to',
		'transaction.returnedFrom': 'Returned from',
		'transaction.inProgress': 'In progress...',
		'transaction.rejected': 'Rejected',

		'investDialog.investTo': 'Invest to',
		'investDialog.reinvestedMessage': 'All profits are reinvested in the strategy',
		'investDialog.from': 'out of',
		'investDialog.invest': 'INVEST',
		'investDialog.cancel': 'CANCEL',

		'returnDialog.returnFrom': 'Exit from',
		'returnDialog.from': 'out of',
		'returnDialog.returnMessage': 'Balance will be transfered to your wallet as soon as all position are closed',
		'returnDialog.return': 'EXIT',
		'returnDialog.cancel': 'CANCEL',

		'rules.new.instrument': 'INSTRUMENT',
		'rules.new.indicator': 'FUNCTION',
		'rules.new.trade': 'BUY/SELL',

		'picker.cancel': 'CANCEL',
		'picker.new.done': 'ADD',
		'picker.edit.done': 'APPLY',
		'picker.edit.delete': 'DELETE',

		'picker.datasource.search': 'Search',
		'picker.datasource.dataSeries': 'DATA SERIES',
		'picker.datasource.timeframe': 'TIMEFRAME',
		'picker.datasource.field': 'FIELD',
		'picker.datasource.name': 'NAME',

		'picker.indicator.search': 'Search',
		'picker.indicator.name': 'Name',

		'tradeRule.direction.Buy': 'Buy',
		'tradeRule.direction.Sell': 'Short Sell',
		'picker.tradeRule.search': 'WHAT TO TRADE',
		'picker.tradeRule.direction': 'DIRECTION',
		'picker.tradeRule.fundsPerTrade': 'for ',
		'picker.tradeRule.maxOpenTrades': 'MAX OPEN TRADES',
		'picker.tradeRule.entryConditions': 'ENTRY CONDITIONS',
		'picker.tradeRule.exitConditions': 'EXIT CONDITIONS',
		'picker.tradeRule.takeProfitPercent': 'TAKE PROFIT, %',
		'picker.tradeRule.stopLossPercent': 'STOP LOSS, %',

		'editor.datasource.Price': 'Price',
		'editor.datasource.Bid': 'Bid',
		'editor.datasource.Ask': 'Ask',

		'editor.timeframe.1m': '1 min',
		'editor.timeframe.5m': '5 min',
		'editor.timeframe.1h': '1 hour',
		'editor.timeframe.1d': '1 day',

		'initial.profile': 'PROFILE',
		'initial.nickName': 'Nickname',
		'initial.done': 'DONE',

		'landing.description': 'Keeping your trades on the right path',
		'landing.longdescription': 'Create, test and run your trading strategies without programming.',
		'landing.email': 'Enter your email address',
		'landing.enterOtp': 'Enter the code',
		'landing.enter': 'Enter',
		'landing.howitworks': 'How it works',
		'landing.strategy': 'Strategy',
		'landing.run': 'Run',
		'landing.invest': 'Invest',
		'landing.step_1': 'Create a strategy with a simple online editor. Every step is visualized. No programming',
		'landing.step_2': 'Run a backtest on historical data in one click',
		'landing.step_3': 'Once backtested the strategy is running live and ready for investments',
		'landing.entering': 'Entering...',
		'landing.open_web_platform': 'Open web platform',
		'landing.sign_in': 'Sign in',

		'landing.for_traders': 'Designed for traders. Not for programmers.',
		'landing.for_traders_description': 'Focus on things that matter to you',
		'landing.for_traders.title.0': 'No programming',
		'landing.for_traders.0': 'No programming language required. Add indicators in simple steps. Each step is immediately visualized on the chart.',
		'landing.for_traders.title.1': 'Real history',
		'landing.for_traders.1': 'Test results that can be trusted. Real historical data is used for testing. Forward looking is impossible. Every trade can be inspected.',
		'landing.for_traders.title.2': 'Multi-asset',
		'landing.for_traders.2': 'Multi-instrument and multi-timeframe strategies. You do not have to stick to one instrument or one timeframe in your trading ideas - combine them as you like.',
		'landing.for_traders.title.3': 'Place to show off',
		'landing.for_traders.3': 'Attract investors by creating strategies with good performance.',
		'landing.for_traders.title.4': 'Realtime',
		'landing.for_traders.4': 'Feel the data flow - everything updated in real time: prices, indicators, profits.',
		'landing.for_traders.title.5': 'Always running',
		'landing.for_traders.5': 'Once the strategy is tested it is always running on the server',

		'landing.for_investors': 'Everyone can invest',
		'landing.for_investors_description': 'Coming soon',
		'landing.for_investors.title.0': 'Transparent',
		'landing.for_investors.0': 'Strategy stats, positions history and trading rules are open for review.',
		'landing.for_investors.title.1': 'Pick the best',
		'landing.for_investors.1': 'Invest in the most successful strategies of other traders: allocate funds that will be used for autotrading by the signals of a strategy.',
		'landing.for_investors.title.2': 'Full control',
		'landing.for_investors.2': 'You have full control over your investments: all strategy positions are opened on your account.',

		'entrance.register_sign_in': 'Sign-in',
		'entrance.enter_email': 'Enter your email address',
		'entrance.enter_code': 'Enter the code that was sent to the email',

		'notification.dealOpened': '{strategyName} opened {volume} {symbol} position at {price}',
		'notification.dealClosed': '{strategyName} closed {volume} {symbol} position at {price} with result {profit} {currency}',

		'notification.demoPositionOpened': '{strategyName} opened {volume} {symbol} demo position at {price}',
		'notification.demoPositionClosed': '{strategyName} closed {volume} {symbol} demo position at {price} with result {profit} {currency}',

		'notification.depositCompleted': 'Deposit of {amount} {currency} via {provider} completed',
		'notification.depositRejected': 'Deposit of {amount} {currency} via {provider} rejected',

		'notification.withdrawalCompleted': 'Withdrawal of {amount} {currency} via {provider} completed',
		'notification.withdrawalRejected': 'Withdrawal of {amount} {currency} via {provider} rejected',

		'strategy.has_not_been_tested': 'This strategy has not been tested yet',

		'tabs.strategy': 'STRATEGY',
		'tabs.rules': 'RULES',
		'tabs.positions': 'POSITIONS',

		'menu.run_test': 'RUN TEST',
		'menu.plus_instrument': 'INSTRUMENT',
		'menu.plus_indicator': 'FUNCTION',
		'menu.plus_trade_rule': 'TRADE RULE',
		'menu.invest': 'INVEST',
		'menu.delete': 'DELETE',

		'strategy.demo_positions': 'DEMO - OPEN POSITIONS',
		'strategy.demo_history': 'DEMO - CLOSED POSITIONS',
		'strategy.positions': 'POSITIONS',
		'strategy.history': 'HISTORY',
		'strategy.not_enough_for_histogram': 'Not enough data for the histogram',
		'strategy.loss_trades': 'loss trades',
		'strategy.profit_trades': 'profit trades',
		'strategy.max_drawdown': 'max drawdown',
		'strategy.demo_stats': 'STRATEGY DEMO STATS',
		'strategy.describe_here': 'Strategy description',
		'strategy.in_days': 'In {count} days',

		'trade.status': 'Trade status',
		'trade.id': 'ID',
		'trade.signal_volume': 'Signal volume',
		'trade.reserved_margin': 'Reserved margin',
		'trade.open_time': 'Open time',
		'trade.open_price': 'Open price',
		'trade.currency_conversion': 'Currency conversion',
		'trade.MV_on_open': 'Market Value on open',
		'trade.close_time': 'Close time',
		'trade.close_price': 'Close price',
		'trade.MV_on_close': 'Market Value on close',
		'trade.trade_result': 'Trade result',

		'editor.chart_mode.Own': 'Own',
		'editor.chart_mode.Shared': 'Shared',
		'editor.chart_mode.Hidden': 'Hidden',
	},

	'ru': {
		'investment.allTimeInvestmentsResults': 'РЕЗУЛЬТАТЫ ИНВЕСТИЦИИ',
		'investment.equity': 'портфель',
		'investment.netProfit': 'общая прибыль',
		'investment.netAllocated': 'всего вложено',
		'investment.firstAllocation': 'ОБЩИЙ РЕЗУЛЬТАТ С ',
		'investment.trailingStop': 'СКОЛЬЗЯЩИЙ СТОП',
		'investment.trailingStopLevel': 'УРОВЕНЬ СТОПА',
		'investment.investMore': 'ИНВЕСТИРОВАТЬ ЕЩЕ',
		'investment.investLess': 'ЗАБРАТЬ ДЕНЬГИ',
		'investment.currentStatement': 'ТЕКУЩЕЕ СОСТОЯНИЕ',
		'investment.balance': 'баланс',
		'investment.floatingProfit': 'прибыль',
		'investment.margin': 'залог',
		'investment.marginLevel': 'уровень залога',
		'investment.activeTrades': 'сделок',
		'investment.currentPositions': 'ТЕКУЩИЕ ПОЗИЦИИ',
		'investment.history': 'ИСТОРИЯ',
		'investment.isActive': 'ИНВЕСТИЦИЯ АКТИВНА',
		'investment.stopNow': 'ОСТАНОВИТЬ И ЗАКРЫТЬ',
		'investment.isStopped': 'ИНВЕСТИЦИЯ ПРИОСТАНОВЛЕНА',
		'investment.activateNow': 'АКТИВИРОВАТЬ',
		'investment.strategyNotReady': 'Эта стратегия не готова для инвестиций',

		'trade.volume': 'Объем',
		'trade.open': 'Открыта',
		'trade.opening': 'Открывается',
		'trade.closing': 'Закрывается',
		'trade.closed': 'Закрыта',
		'trade.rejected': 'Отклонена',

		'rejected.none': 'Неизвестно',
		'rejected.notEnoughFunds': 'Недостаточно средств',
		'rejected.minVolume': 'Минимальный объем',
		'rejected.closeOnly': 'Для этого инструмента доступно только закрытие сделок',
		'rejected.buyOnly': 'Для этого инструмента доступны только сделки на покупку',
		'rejected.tradingDisabled': 'Для этого инструмента торговля отключена',
		'rejected.notAllowed': 'Для этого инструмента торговля недоступна',

		'strategy.tab.strategy': 'СТРАТЕГИЯ',
		'strategy.tab.investment': 'ИНВЕСТИЦИЯ',
		'strategy.tab.investors': 'ИНВЕСТОРЫ',

		'strategy.addDescription': '+ Добавить описание',
		'strategy.month': 'месяц',
		'strategy.lastYear': 'год',
		'strategy.investInThis': 'ИНВЕСТИРОВАТЬ В ЭТУ СТРАТЕГИЮ',
		'strategy.subscribe': 'ПОДПИСАТЬСЯ',
		'strategy.unsubscribe': 'ОТПИСАТЬСЯ',

		'home.tab.strategies': 'Стратегии',
		'home.tab.wallet': 'Кошелек',
		'home.tab.profile': 'Профиль',
		'home.tab.sign_up': 'Регистрация',

		'wallet.withdraw': 'СНЯТЬ',
		'wallet.deposit': 'ПОПОЛНИТЬ',

		'dashboard.freeFunds': 'В КОШЕЛЬКЕ',
		'dashboard.invested': 'ИНВЕСТИРОВАНО',
		'dashboard.newStrategy': 'СОЗДАТЬ СТРАТЕГИЮ',
		'dashboard.noStrategiesYet': 'У вас пока нет своих стратегий',
		'dashboard.recommendedStrategies': 'ПОДБОРКА ЧУЖИХ СТРАТЕГИЙ',

		'transaction.depositedVia': 'Пополнено с {provider}',
		'transaction.withdrawnTo': 'Списано в {provider}',
		'transaction.investedTo': 'Инвестировано в',
		'transaction.returnedFrom': 'Выведено из',
		'transaction.inProgress': 'Обрабатывается...',
		'transaction.rejected': 'Отклонена',

		'investDialog.investTo': 'Инвестировать в',
		'investDialog.reinvestedMessage': 'Вся прибыль реинвестируется в стратегию',
		'investDialog.from': 'из',
		'investDialog.invest': 'ИНВЕСТИРОВАТЬ',
		'investDialog.cancel': 'ОТМЕНА',

		'returnDialog.returnFrom': 'Выйти из',
		'returnDialog.from': 'из',
		'returnDialog.returnMessage': 'Баланс будет перечислен на ваш кошелек после закрытия всех текущих позиций',
		'returnDialog.return': 'ВЫЙТИ',
		'returnDialog.cancel': 'ОТМЕНА',

		'rules.new.instrument': 'ИНСТРУМЕНТ',
		'rules.new.indicator': 'ФУНКЦИЯ',
		'rules.new.trade': 'ТОРГОВЛЯ',

		'picker.cancel': 'ОТМЕНА',
		'picker.new.done': 'ДОБАВИТЬ',
		'picker.edit.done': 'ПРИМЕНИТЬ',
		'picker.edit.delete': 'УДАЛИТЬ',

		'picker.datasource.search': 'Поиск',
		'picker.datasource.dataSeries': 'ДАННЫЕ',
		'picker.datasource.timeframe': 'ТАЙМФРЕЙМ',
		'picker.datasource.field': 'ПОЛЯ',
		'picker.datasource.name': 'ИМЯ',

		'picker.indicator.search': 'Поиск',
		'picker.indicator.name': 'ИМЯ',

		'tradeRule.direction.Buy': 'Покупка',
		'tradeRule.direction.Sell': 'Шорт Продажа',
		'picker.tradeRule.search': 'ЧЕМ ТОРГОВАТЬ',
		'picker.tradeRule.direction': 'НАПРАВЛЕНИЕ',
		'picker.tradeRule.fundsPerTrade': 'СРЕДСТВ НА СДЕЛКУ, %',
		'picker.tradeRule.maxOpenTrades': 'ЛИМИТ СДЕЛОК',

		'picker.tradeRule.entryConditions': 'УСЛОВИЯ ВХОДА',
		'picker.tradeRule.exitConditions': 'УСЛОВИЯ ВЫХОДА',
		'picker.tradeRule.takeProfitPercent': 'ТЭЙК ПРОФИТ, %',
		'picker.tradeRule.stopLossPercent': 'СТОП ЛОСС, %',

		'initial.profile': 'ПРОФИЛЬ',
		'initial.nickName': 'Имя',
		'initial.done': 'ГОТОВО',

		'landing.description': 'Автоматизация торговых стратегий без программирования',
		'landing.longdescription': 'Трейдеры используют Cicada для транcформации идей в стратегии, тестирования на исторических данных и инвестиций.',
		'landing.email': 'Введите email адрес',
		'landing.enterOtp': 'Введите код',
		'landing.enter': 'Войти',
		'landing.howitworks': 'Как это работает',
		'landing.strategy': 'Стратегия',
		'landing.run': 'Бэктест',
		'landing.invest': 'Инвестиция',
		'landing.step_1': 'Создайте стратегию в простом онлайн редакторе. Каждый шаг визуализируется. Без программирования',
		'landing.step_2': 'Запустите бэктест на исторических данных одним кликом',
		'landing.step_3': 'После бэктеста стратегия продолжает работу на реальных данных и готова для инвестиций',

		'landing.for_traders': 'Designed for traders. Not for programmers.',
		'landing.for_traders.0': 'No programming language required. Add indicators in simple steps. Each step is immediately visualized on the chart.',
		'landing.for_traders.1': 'Test results that can be trusted. Real historical data is used for testing. Forward looking is impossible. Every trade can be inspected.',
		'landing.for_traders.2': 'Multi-instrument and multi-timeframe strategies. You do not have to stick to one instrument or one timeframe in your trading ideas - combine them as you like.',
		'landing.for_traders.3': 'Attract investors by creating strategies with good performance.',
		'landing.for_traders.4': 'Feel the data flow - everything updated in real time: prices, indicators, profits.',
		'landing.for_traders.5': ' ',

		'landing.for_investors': 'Everyone can invest',
		'landing.for_investors.0': 'Invest in the most successful strategies of other traders: allocate funds that will be used for autotrading by the signals of a strategy.',
		'landing.for_investors.1': 'You have full control over your investments: all strategy trades are opened on your account.',
		'landing.for_investors.2': 'When a strategy makes a trade all investors get the same execution price.',
		'landing.for_investors.3': ' ',

		'landing.entering': 'Вход...',
		'landing.open_web_platform': 'Открыть веб платформу',
		'landing.sign_in': 'Войти',

		'entrance.register_sign_in': 'Вход',
		'entrance.enter_email': 'Введите емейл адрес',
		'entrance.enter_code': 'Введите код, который был отправлен на ваш email',
		'notification.dealOpened': '{strategyName} открыла {volume} {symbol} позицию по цене {price}',
		'notification.dealClosed': '{strategyName} закрыла {volume} {symbol} позицию по цене {price} с результатом {profit} {currency}',

		'notification.demoPositionOpened': '{strategyName} открыла {volume} {symbol} демо позицию по цене {price}',
		'notification.demoPositionClosed': '{strategyName} закрыла {volume} {symbol} демо позицию по цене {price} с результатом {profit} {currency}',

		'notification.depositCompleted': 'Пополнение {amount} {currency} через {provider} завершено',
		'notification.depositRejected': 'Пополнение {amount} {currency} через {provider} отклонено',

		'notification.withdrawalCompleted': 'Списание {amount} {currency} через {provider} завершено',
		'notification.withdrawalRejected': 'Списание {amount} {currency} через {provider} отклонено',

		'strategy.has_not_been_tested': 'Эта стратегия еще не протестирована',
		'tabs.strategy': 'СТРАТЕГИЯ',
		'tabs.rules': 'ПРАВИЛА',

		'menu.run_test': 'ЗАПУСТИТЬ ТЕСТ',
		'menu.plus_instrument': 'ИНСТРУМЕНТ',
		'menu.plus_indicator': 'ФУНКЦИЯ',
		'menu.plus_trade_rule': 'ТОРГОВОЕ ПРАВИЛО',
		'menu.invest': 'ИНВЕСТИРОВАТЬ',
		'menu.delete': 'УДАЛИТЬ',

		'strategy.demo_positions': 'ДЕМО ПОЗИЦИИ',
		'strategy.positions': 'ПОЗИЦИИ',
		'strategy.demo_history': 'ДЕМО ИСТОРИЯ',
		'strategy.history': 'ИСТОРИЯ',
		'strategy.not_enough_for_histogram': 'Недостаточно данных для гистограммы',
		'strategy.loss_trades': 'убыточных сделок',
		'strategy.profit_trades': 'прибыльных сделок',
		'strategy.max_drawdown': 'максимальная просадка',
		'strategy.demo_stats': 'ДЕМО СТАТИСТИКА СТРАТЕГИИ',
		'strategy.describe_here': 'Описание стратегии',
		'strategy.in_days': 'За {count} дней',

		'trade.status': 'Статус сделки',
		'trade.id': 'ID',
		'trade.signal_volume': 'Объем сигнала',
		'trade.reserved_margin': 'Размер залога',
		'trade.open_time': 'Время открытия',
		'trade.open_price': 'Цена открытия',
		'trade.currency_conversion': 'Валютный курс',
		'trade.MV_on_open': 'Рыночная стоимость при открытии',
		'trade.close_time': 'Время закрытия',
		'trade.close_price': 'Цена закрытия',
		'trade.MV_on_close': 'Рыночная стоимость при закрытии',
		'trade.trade_result': 'Результат сделки',

		'editor.chart_mode.Own': 'Свой',
		'editor.chart_mode.Shared': 'Общий',
		'editor.chart_mode.Hidden': 'Скрыт',
	}
};