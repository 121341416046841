import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import { withRouter } from "react-router-dom"
import PageContainer from '../../components/PageContainer'
import WalletsService from '/src/services/wallets'
import StrategyService, { StrategyResponse, EquityUpdate, TimeValue } from '/src/services/strategies'
import AuthenticationService from '/src/services/authentication';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { SplashScreen } from '@capacitor/splash-screen';
import CreateStrategyButton from "./CreateStrategyButton";
import DemoedStrategy from "./DemoedStrategy";
import InvestedStrategy from "./InvestedStrategy";
import EmptyStrategy from "./EmptyStrategy";

interface Props {
	balance: number,
	invested: number,
	history: any;
}

interface State {
	strategies: StrategyResponse[],
	recommendedStrategies: StrategyResponse[],
	balance: number,
	invested: number
}

class DashboardPage extends React.Component<Props, State> {

	constructor(props) {
		super(props);
		this.state = { strategies: undefined, recommendedStrategies: undefined, balance: props.balance, invested: props.invested };
	}

	sseInvestedEquitiesHandler: EventSource
	sseSampledEquitiesHandler: EventSource

	stopInvestedEquitiesSse = () => {
		if (this.sseInvestedEquitiesHandler) {
			this.sseInvestedEquitiesHandler.close();
			this.sseInvestedEquitiesHandler = undefined;
		}
	}

	stopSampledEquitiesSse = () => {
		if (this.sseSampledEquitiesHandler) {
			this.sseSampledEquitiesHandler.close();
			this.sseSampledEquitiesHandler = undefined;
		}
	}

	subscribeForMyEquities = (strategyIds: string[]) => {
		if (this.sseInvestedEquitiesHandler !== undefined) {
			return;
		}
		this.sseInvestedEquitiesHandler = StrategyService.subscribeForEquities(strategyIds.join(","), false, (s) => {
			const eventData: EquityUpdate = JSON.parse(s);
			const newStrategies = this.state.strategies.slice();
			const updatedStrategy = newStrategies.find(s => s.strategy.id === eventData.strategyId);
			if (updatedStrategy) {
				updatedStrategy.investmentStatement.stats.equity = eventData.value;

				const timevalue: TimeValue = { time: eventData.timestamp, value: eventData.value };

				updatedStrategy.investmentStatement.equityHistory.values.length > 0 ?
					updatedStrategy.investmentStatement.equityHistory.values[updatedStrategy.investmentStatement.equityHistory.values.length - 1] = timevalue :
					updatedStrategy.investmentStatement.equityHistory.values.push(timevalue);

				updatedStrategy.investmentStatement.equityHistory.relativeChange =
					updatedStrategy.investmentStatement.equityHistory.values[updatedStrategy.investmentStatement.equityHistory.values.length - 1].value /
					updatedStrategy.investmentStatement.equityHistory.values[0].value
			}
			else {
				console.log("Cannot find invested strategy " + eventData.strategyId);
			}
			var invested = newStrategies.reduce((a, b) => a + (b.investmentStatement ? b.investmentStatement.stats.equity : 0), 0);

			this.setState({ strategies: newStrategies, invested: invested });
		});
	}

	subscribeForSampleEquities = (strategyIds: string[]) => {
		if (this.sseSampledEquitiesHandler !== undefined) {
			return;
		}
		this.sseSampledEquitiesHandler = StrategyService.subscribeForEquities(strategyIds.join(","), true, (s) => {
			const eventData: EquityUpdate = JSON.parse(s);
			const newStrategies = this.state.strategies.slice();
			const newRecommendedStrategies = this.state.recommendedStrategies.slice();
			let isRecommended = false;
			let updatedStrategy = newStrategies.find(s => s.strategy.id === eventData.strategyId);
			if (!updatedStrategy) {
				updatedStrategy = newRecommendedStrategies.find(s => s.strategy.id === eventData.strategyId);
				isRecommended = true;
			}
			if (updatedStrategy && updatedStrategy.demoStatement) {
				updatedStrategy.demoStatement.stats.equity = eventData.value;

				const timevalue: TimeValue = { time: eventData.timestamp, value: eventData.value };

				updatedStrategy.demoStatement.equityHistory.values.length > 0 ?
					updatedStrategy.demoStatement.equityHistory.values[updatedStrategy.demoStatement.equityHistory.values.length - 1] = timevalue :
					updatedStrategy.demoStatement.equityHistory.values.push(timevalue);

				updatedStrategy.demoStatement.equityHistory.relativeChange =
					updatedStrategy.demoStatement.equityHistory.values[updatedStrategy.demoStatement.equityHistory.values.length - 1].value /
					updatedStrategy.demoStatement.equityHistory.values[0].value
				this.setState(!isRecommended ? { strategies: newStrategies } : { recommendedStrategies: newRecommendedStrategies });
			}
		});
	}

	onMenuKeyDown = async () => {
		//this.log += 'onMenuKeyDown event fired;'
	}

	async componentDidMount() {
		document.addEventListener("menubutton", this.onMenuKeyDown, false);

		setTimeout(async () => { await SplashScreen.hide(); }, 100);


		let [strategies, recommendedStrategies] = await Promise.all([StrategyService.getMyStrategies(), StrategyService.getRecommendedStrategies()]);

		strategies.sort((a, b) => {
			if (a.investmentStatement === undefined) {
				return 1;
			}
			if (b.investmentStatement === undefined) {
				return -1;
			}
			return b.investmentStatement.stats.equity - a.investmentStatement.stats.equity
		})
		var invested = strategies.reduce((a, b) => a + (b.investmentStatement ? b.investmentStatement.stats.equity : 0), 0);
		this.setState({
			balance: 0,
			strategies: strategies,
			invested: invested,
			recommendedStrategies: recommendedStrategies
		})

		this.subscribeForMyEquities(strategies.filter(s => s.investmentStatement !== undefined).map(s => s.strategy.id));
		this.subscribeForSampleEquities(strategies.filter(s => s.investmentStatement === undefined).concat(recommendedStrategies).map(s => s.strategy.id));
	}

	toggleFav = async (strategy, fav) => {
		/*if (fav) {
			const new_my_strategies = this.state.strategies.slice()
			new_my_strategies.push(strategy);
			const new_recommended = this.state.recommendedStrategies.slice().filter(s => s.id !== strategy.id);
			this.setState({ strategies: new_my_strategies, recommendedStrategies: new_recommended });

			await StrategyService.favourite(strategy.id);
		}
		else {
			const new_recommended = this.state.recommendedStrategies.slice()
			new_recommended.push(strategy);
			const new_my_strategies = this.state.strategies.slice().filter(s => s.id !== strategy.id);
			this.setState({ strategies: new_my_strategies, recommendedStrategies: new_recommended });

			await StrategyService.unfavourite(strategy.id);
		}*/
	}

	componentWillUnmount() {
		this.stopInvestedEquitiesSse();
		this.stopSampledEquitiesSse();
	}

	render() {
		document.title = "Dashboard " + String.fromCharCode(183) + " Cicada";

		return isDesktop ?
			<PageContainer maxWidth={700} history={this.props.history} tab={0}>
				<Row mainAxis="center">
					<Column style={{ padding: "0rem 2.4rem", maxWidth: 700, flex: 1, position: "relative", minWidth: 20 }}>

						{(this.state.strategies && this.state.strategies.length === 0)
							? <Column mainAxis="center" crossAxis="center" className="font16 c10" style={{ minHeight: "50vh", height: "30rem" }}>
								<FormattedMessage id="dashboard.noStrategiesYet" />
								<CreateStrategyButton history={this.props.history} />
							</Column>
							: <Column className="" mainAxis="center" crossAxis="center">
								<CreateStrategyButton history={this.props.history} />
							</Column>
						}

						{
							this.state.strategies && this.state.strategies.length > 0 &&
							<Column className="flex1" crossAxis="stretch" style={{ minWidth: 0, marginTop: "1.4rem" }}>
								{
									this.state.strategies.map(s => {
										const ownStrategy = AuthenticationService.getCurrentUserId() === s.author.id;
										return s.investmentStatement !== undefined ?
											<InvestedStrategy key={s.strategy.id} data={s} ownStrategy={ownStrategy} />
											:
											s.demoStatement !== undefined ?
												<DemoedStrategy key={s.strategy.id} data={s} ownStrategy={ownStrategy} />
												:
												<EmptyStrategy key={s.strategy.id} data={s} ownStrategy={ownStrategy} />
									})
								}
							</Column>
						}
						{/*
						{this.state.recommendedStrategies &&
							<Row className="top50">
								<div className="textCenter flex1 font14"><FormattedMessage id="dashboard.recommendedStrategies" /></div>
							</Row>
						}
						{this.state.recommendedStrategies &&
							<Column className="top10 flex1">
								{
									this.state.recommendedStrategies.map(s => {
										return <DemoedStrategy key={s.strategy.id} data={s} ownStrategy={false} />
									})
								}
							</Column>
							}
						*/}
					</Column>


				</Row >
			</PageContainer>
			:
			<PageContainer maxWidth={700} history={this.props.history} tab={0}>
				<Column style={{ padding: "0px 12px" }}>
					{(this.state.strategies && this.state.strategies.length === 0)
						? <Column mainAxis="center" crossAxis="center" className="font16 c10" style={{ minHeight: "50vh", height: "30rem" }}>
							<FormattedMessage id="dashboard.noStrategiesYet" />
							<CreateStrategyButton history={this.props.history} />
						</Column>
						: <Column mainAxis="center" crossAxis="center">
							<CreateStrategyButton history={this.props.history} />
						</Column>
					}

					{
						this.state.strategies && this.state.strategies.length > 0 &&
						<Column className="flex1" crossAxis="stretch" style={{ minWidth: 0 }}>
							{
								this.state.strategies.map(s => {
									const ownStrategy = AuthenticationService.getCurrentUserId() === s.author.id;
									return s.investmentStatement !== undefined ?
										<InvestedStrategy key={s.strategy.id} data={s} ownStrategy={ownStrategy} />
										:
										s.demoStatement !== undefined ?
											<DemoedStrategy key={s.strategy.id} data={s} ownStrategy={ownStrategy} />
											:
											<EmptyStrategy key={s.strategy.id} data={s} ownStrategy={ownStrategy} />
								})
							}
						</Column>
					}
					{/*
					{this.state.recommendedStrategies &&
						<Row className="top50">
							<div className="textCenter flex1 font14"><FormattedMessage id="dashboard.recommendedStrategies" /></div>
						</Row>
					}
					{this.state.recommendedStrategies &&
						<Column className="top10 flex1">
							{
								this.state.recommendedStrategies.map(s => {
									return <DemoedStrategy key={s.strategy.id} data={s} ownStrategy={false} />
								})
							}
						</Column>
					}
					*/}
				</Column>
			</PageContainer>
	}
}


export default withRouter(DashboardPage);