import * as React from "react";

import LandingPage from './pages/landing/index'
import DashboardPage from './pages/dashboard/index'
import EntrancePage from './pages/entrance/entrance'
import TransactionsPage from './pages/TransactionsPage'
import DisclosuresPage from './pages/disclosures/index'
import EditProfilePage from './pages/profile/index'
import StrategyPage from './pages/strategy/index'
import VerifyEmailPage from './pages/verify-email/index'
import DepositPage from './pages/DepositPage'
import SignUpPage from './pages/sign-up/sign-up'
import WithdrawPage from './pages/WithdrawPage'
import DealingPage from './pages/DealingPage'
import AuthRoute, { AuthRequirement } from './AuthRoute'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import { Capacitor } from '@capacitor/core';
import CreateAccountPage from "./pages/CreateAccountPage";
import { StrategyTab } from "./pages/strategy/EditorModels";
import FastUnsubscribePage from "./pages/fast-unsubscribe/fast-unsubscribe";

export const MainRouting: React.FunctionComponent = () => {
	let location = useLocation();
	return (
		<TransitionGroup>
			{/*
            This is no different than other usage of
            <CSSTransition>, just make sure to pass
            `location` to `Switch` so it can match
            the old location as it animates out.
          
			<CSSTransition
				key={location.key}
				classNames="fade"
				timeout={300}
			>*/}
			<Switch location={location}>
				<AuthRoute key={Math.random()} path="/dashboard" component={DashboardPage} type={AuthRequirement.User} />
				<AuthRoute key={Math.random()} path="/wallet" component={TransactionsPage} type={AuthRequirement.User} />
				<AuthRoute key={Math.random()} path="/deposit" component={DepositPage} type={AuthRequirement.User} />
				<AuthRoute key={Math.random()} path="/withdraw" component={WithdrawPage} type={AuthRequirement.User} />
				<AuthRoute key={Math.random()} path="/verify-email" component={VerifyEmailPage} type={AuthRequirement.Any} />
				<AuthRoute key={Math.random()} path="/sign-up" component={SignUpPage} type={AuthRequirement.User} />

				<AuthRoute key={Math.random()} path="/disclosures" component={DisclosuresPage} type={AuthRequirement.Any} />
				<AuthRoute key={Math.random()} path="/fast-unsubscribe" component={FastUnsubscribePage} type={AuthRequirement.Any} />

				<AuthRoute key={Math.random()} path="/profile/general" component={c => { return <EditProfilePage tab={0} /> }} type={AuthRequirement.User} />
				<AuthRoute key={Math.random()} path="/profile/settings" component={c => { return <EditProfilePage tab={1} /> }} type={AuthRequirement.User} />
				{/*<AuthRoute key={Math.random()} path="/profile/legal" component={c => { return <EditProfilePage tab={1} /> }} type={AuthRequirement.User} />
				<AuthRoute key={Math.random()} path="/profile/banks" component={c => { return <EditProfilePage tab={2} /> }} type={AuthRequirement.User} />*/}

				<AuthRoute key={Math.random()} path="/create-account" component={CreateAccountPage} type={AuthRequirement.User} />

				<AuthRoute key={Math.random()} path="/strategy/:id/rules" component={c => {
					return <StrategyPage strategyId={c.match.params.id} strategy={c.location.state} tab={StrategyTab.Rules} />
				}} type={AuthRequirement.Any} />

				<AuthRoute key={Math.random()} path="/strategy/:id/positions" component={c => {
					return <StrategyPage strategyId={c.match.params.id} strategy={c.location.state} tab={StrategyTab.Positions} />
				}} type={AuthRequirement.Any} />

				<AuthRoute key={Math.random()} path="/strategy/:id" component={c => {
					return <StrategyPage strategyId={c.match.params.id} strategy={c.location.state} tab={StrategyTab.Stats} />
				}} type={AuthRequirement.Any} />


				<Route exact path="/dealing" component={DealingPage} />
				<Route exact path="/entrance" component={c => {
					return <EntrancePage />
				}} />

				<AuthRoute key={Math.random()} path="/" component={Capacitor.getPlatform() === 'web' ? LandingPage : EntrancePage} type={AuthRequirement.Guest} />
			</Switch>
			{/*</CSSTransition>*/}
		</TransitionGroup>)
}