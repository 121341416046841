import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { HiMiniEllipsisVertical } from "/node_modules/react-icons/hi2/index";
import { MenuItem } from "../MenuItem";
import { Listbox } from "/node_modules/@headlessui/react/dist/index";

interface Props {
	items: MenuItem[],
}

const TokenMenu: React.FunctionComponent<Props> = (props) => {
	return <div style={{ position: "relative" }}>
		<Listbox value={props.items[0]} onChange={v => { }}>
			{({ open }) => (
				<>
					<Listbox.Button>
						<Row className={"hoverable mleft1 " + (open ? "bg-button" : "")} mainAxis="center" crossAxis="center"
							style={{ borderRadius: 13, height: 27, width: 27 }}
							onClick={(e) => { }}>
							<HiMiniEllipsisVertical style={{ height: 15, width: 15 }} />
						</Row>
					</Listbox.Button>

					<Listbox.Options>
						<Column className="font14 shadow bg-button white segoe"
							style={{
								zIndex: 10001,
								position: "absolute",
								borderRadius: "5px 0px 5px 5px",
								right: 0, top: 35
							}}>
							{
								props.items.map(item => <Row key={item.text} className={item.isEnabled ? "hoverable pointer color-main" : "color-alt"}
									crossAxis="center"
									style={{ width: 180, height: 35 }} onClick={() => {
										if (item.isEnabled) {
											item.action();
											//setIsExpanded(false);
										}
									}}>
									<Row mainAxis="center" style={{ width: 35 }}>
										{item.icon}
									</Row>
									{item.text}
								</Row>)
							}

						</Column>
					</Listbox.Options>
				</>)}
		</Listbox>
	</div>
}

export default TokenMenu;