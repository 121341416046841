import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import AuthenticationService from '/src/services/authentication'
import { withRouter } from 'react-router-dom'
import { FormattedMessage, FormattedDate } from 'react-intl';
import landing from '/src/assets/landing.svg';
import { Position } from "/generated/cicada-client";
import Api, { ErrorCode } from "/generated/cicada-client";
import { serverUrl } from '/src/services/common'
const api = new Api(serverUrl, undefined, async () => { return await AuthenticationService.getAuthHeaderValue(); });

interface Props {
	code: string,
	history: any
}

interface State {
	closedTrades?: Position[],
	userId: string,
	strategyId: string
}

class DealingPage extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = { closedTrades: [], userId: "demo", strategyId: "9S0AOD2" };
	}

	search = async () => {
		const now = new Date().getTime();
		const closedTrades = await api.trading.findClosedDeals(this.state.strategyId, this.state.userId, now + 10000, 1000);
		this.setState({ closedTrades: closedTrades });
	}

	async componentDidMount() {
		this.search();
	}

	render() {
		document.title = "Dealing" + " " + String.fromCharCode(183) + " Cicada";

		return <Column crossAxis="center" className="bc20">
			<Row className="flex1">
				<img style={{ marginTop: "-130px" }} src={landing} alt="Cicada" />
			</Row>
			<Column className="white font18 top50 segoe" style={{ width: "1500px" }}>
				<Column className="font12 top50">
					<table cellSpacing="0" style={{ borderSpacing: "5px 0px"}}>
						<tr>
							<th style={{ textAlign: "left", width: "230px" }}>opening</th>
							<th style={{ textAlign: "left", width: "90px" }}>id</th>
							<th style={{ textAlign: "left", width: "90px" }}>
								<input placeholder="strategy" style={{width: "90%"}} className="bold segoe" onChange={e => {
									this.setState({ strategyId: e.target.value });
									setTimeout(() => {
										this.search();
									}, 100);
								}} />
							</th>
							<th style={{ textAlign: "left" }}>ruleId</th>
							<th style={{ textAlign: "left" }}>signalId</th>
							<th style={{ textAlign: "left" }}>
								<input placeholder="user"  style={{width: "90%"}} className="bold segoe" onChange={e => {
									this.setState({ userId: e.target.value });
									setTimeout(() => {
										this.search();
									}, 100);
								}} />
							</th>
							<th style={{ textAlign: "left" }}>instrument</th>
							<th style={{ textAlign: "right" }}>volume</th>
							<th style={{ textAlign: "right" }}>margin</th>
							<th style={{ textAlign: "right" }}>opening</th>
							<th style={{ textAlign: "right" }}>open</th>
							<th style={{ textAlign: "right" }}>closing</th>
							<th style={{ textAlign: "right" }}>close</th>
							<th style={{ textAlign: "right" }}>profit</th>
							<th style={{ textAlign: "left" }}>closetime</th>
						</tr>

						{this.state.closedTrades.map((trade, index) =>
							<tr style={index % 2 == 1 ? { backgroundColor: "#ffffff10" } : {}}>
								<td>{new Date(trade.openTime).toUTCString()}</td>
								<td>{trade.id}</td>
								<td>{trade.strategyId}</td>
								<td>{trade.ruleId}</td>
								<td>{trade.signalId}</td>
								<td>{trade.userId}</td>
								<td>{trade.symbol}</td>
								<td style={{ textAlign: "right" }}>{trade.volume.toFixed(3)}</td>
								<td style={{ textAlign: "right" }}>{trade.margin.toFixed(2)}</td>
								<td style={{ textAlign: "right" }}>{trade.openingPrice.toFixed(2)}</td>
								<td style={{ textAlign: "right" }}>{trade.openPrice.toFixed(2)}</td>
								<td style={{ textAlign: "right" }}>{trade.closingPrice.toFixed(2)}</td>
								<td style={{ textAlign: "right" }}>{trade.closePrice.toFixed(2)}</td>
								<td style={{ textAlign: "right" }}>{trade.profit.toFixed(2)}</td>
								<td className="mleft5">{new Date(trade.closeTime).toUTCString()}</td>
							</tr>
						)}
					</table>
				</Column>

			</Column>
		</Column >
	}
}

export default withRouter(DealingPage);