import * as React from "react";
import { Column, isDesktop, Row } from '/src/components/Layout'
import StrategyThumbnailChart from './StrategyThumbnailChart'
import { renderMonth, niceNumber, niceSignedNumber, signedPercent } from '/src/utils/numbers';
import { Link } from "react-router-dom";
import StrategyService, { StrategyResponse } from '/src/services/strategies'
import { FormattedMessage, FormattedDate } from 'react-intl';
import big_plus from '/src/assets/big_plus.svg';
import SvgBigPlus from "/src/components/svg/SvgBigPlus";

interface Props {
	history: any;
}

const CreateStrategyButton: React.FunctionComponent<Props> = props => {
	const [pressed, setPressed] = React.useState<Boolean>(false);

	const onClick = async e => {
		if (!pressed) {
			setPressed(true)
			const newStrategy = await StrategyService.create();
			props.history.push("/strategy/" + newStrategy.id + "/rules");
		}
	}

	return <Row className="pointer semibold font12 mtop30 mbottom30"
			crossAxis="center"
			onClick={onClick}>
			<div style={{ width: 38, height: 38 }} >
				<div style={{ animation: "growFromZero 0.4s" }}>
					<SvgBigPlus/>
				</div>
			</div>
			<div style={{ marginLeft: 10 }}>
				<FormattedMessage id="dashboard.newStrategy" />
			</div>
		</Row>
}




export default CreateStrategyButton;