import * as React from "react";
import { ChartCanvas, Chart, series } from "react-stockcharts";
import { BarSeries, AreaSeries, ScatterSeries, CircleMarker } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import { last } from "react-stockcharts/lib/utils";
import { fitWidth } from "react-stockcharts/lib/helper";
import { isMobile, Column, Row } from '/src/components/Layout'
import { LineModel, IndicatorToken } from './EditorModels';
import { timeFormat } from "d3-time-format";
import { niceNumber, niceSignedNumber } from '/src/utils/numbers';
import { ChartTimeItem } from '/src/services/strategies'
import { ThemeContext } from "/src/components/ThemeProvider";
import { ClickCallback } from "react-stockcharts/lib/interactive";
import { CrossHairCursor, EdgeIndicator, CurrentCoordinate, MouseCoordinateX, MouseCoordinateY } from "react-stockcharts/lib/coordinates";

export interface ChartContext {
	metaSubscribe: (eventType: string, subscriberId: string, metaListener: (input: any) => void) => void,
	triggerMetaEvent: (eventType: string, senderId: string, eventData: any) => void
}

class BooleanChartItem extends React.Component<{
	type: any,
	width: any,
	ratio: any,
	data: ChartTimeItem[],
	tokens: IndicatorToken[],
	metaId: number,
	paddingPercent: number,
	chartContext: ChartContext,
	hasXAxis: boolean,
	isSelected: boolean,
	onHover: (currentItem: ChartTimeItem) => void
}> {

	onMouseEvent = (moreProps, event) => {
		if (moreProps && moreProps.currentItem) {
			this.props.onHover(moreProps.currentItem)
		}
	}

	render() {
		if (this.props.data.length < 2
			|| this.props.tokens === undefined
			|| this.props.tokens.length === 0
			|| this.props.data[1].values.length <= this.props.tokens[0].dataIndex) {
			return <Row mainAxis="center">
				<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
			</Row>
		}

		const { type, width, ratio } = this.props;
		const xScaleProvider = discontinuousTimeScaleProvider
			.inputDateAccessor((d: ChartTimeItem) => new Date(d.timestamp));
		const {
			data,
			xScale,
			xAccessor,
			displayXAccessor,
		} = xScaleProvider(this.props.data);

		const start = xAccessor(last(data));
		const end = xAccessor(data[Math.max(0, data.length - (isMobile ? 40 : 120))]);
		const xExtents = [start, end];

		let height = this.props.tokens.length * 15 + (this.props.hasXAxis ? 20 : 0);
		//if (isMobile) {
		//	height /= 1.5;
		//}

		return <ThemeContext.Consumer>
			{(theme) => <Row className="" style={{ height: height }} onMouseLeave={() => { this.props.onHover(undefined) }}>
				<ChartCanvas metaId={this.props.metaId} ratio={ratio} width={width} height={height}
					metaSubscribe={this.props.chartContext.metaSubscribe}
					triggerMetaEvent={this.props.chartContext.triggerMetaEvent}
					useCrossHairStyleCursor={false}
					type="hybrid"
					zoomEvent={false}
					margin={{ left: 0, right: 60, top: 0, bottom: this.props.hasXAxis ? 20 : 0 }}
					seriesName=""
					xScale={xScale}
					xAccessor={xAccessor}
					displayXAccessor={displayXAccessor}
					xExtents={xExtents}
					data={data}>
					<Chart id={0} yExtents={[0, this.props.tokens.length]} padding={1}>
						{this.props.hasXAxis && <XAxis
							axisAt="bottom"
							orient="bottom"
							zoomEnabled={false}
							fontFamily="Open Sans"
							fontSize={12}
							ticks={6} innerTickSize={-1000} tickStrokeOpacity={0.2}
							stroke={theme.cssVar('--bg-main')}
							tickStroke={theme.cssVar('--color-alt')} />}
						{this.props.tokens.map((token, index) => <ScatterSeries
							markerProps={{
								r: 5,
								opacity: 1,
								stroke: "transparent",
								fill: d => d.values[token.dataIndex][3] > 0
									? theme.cssVar('--color-main')
									: d.values[token.dataIndex][1] > 0
										? theme.cssVar('--color-alt') :
										this.props.isSelected ?
											theme.cssVar('--bg-button') :
											theme.cssVar('--bg-main-alt')
							}}
							yAccessor={d => this.props.tokens.length - 1 - index + 0.5}
							marker={CircleMarker} />)}
						{/*<AreaSeries
							opacity={1}
							yAccessor={(d) => d.values[this.props.token.dataIndex][1]}
							stroke={theme.cssVar('--color-alt')}
							fill={theme.cssVar('--color-alt')}
						/>
						<AreaSeries
							opacity={1}
							yAccessor={(d) => d.values[this.props.token.dataIndex][3]}
							stroke={theme.cssVar('--color-alt')}
							fill={theme.cssVar('--bg-panel')}
						/>
						{/*<BarSeries
							fill={d => d.values[this.props.token.dataIndex][3] > 0
								? theme.cssVar('--color-main')
								: d.values[this.props.token.dataIndex][1] > 0
									? theme.cssVar('--color-alt') :
									this.props.isSelected ?
										theme.cssVar('--bg-button') :
										theme.cssVar('--bg-main-alt')}
							opacity={1}
							width={8}
							key={0}
							yAccessor={d => (1 / 1) * 0.9}
						/>*/}
						<ClickCallback
							onMouseMove={this.onMouseEvent}
						//onMouseDown={(moreProps, e) => { console.log("onMouseDown", moreProps, e); }}
						//onClick={(moreProps, e) => { console.log("onClick", moreProps, e); }}
						//onDoubleClick={(moreProps, e) => { console.log("onDoubleClick", moreProps, e); }}
						//onContextMenu={(moreProps, e) => { console.log("onContextMenu", moreProps, e); }}
						//onPan={(moreProps, e) => { console.log("onPan", moreProps, e); }}
						//onPanEnd={(moreProps, e) => { console.log("onPanEnd", moreProps, e); }}
						/>
						{!isMobile && <MouseCoordinateX
							at="bottom"
							orient="bottom"
							fill="#F2F3F5"
							opacity={1}
							fontFamily="Open Sans"
							fontSize={10}
							textFill="#505865"
							displayFormat={timeFormat("%Y-%m-%d %H:%M")} />}
						{!isMobile && <CrossHairCursor />}
					</Chart>

				</ChartCanvas>
			</Row >}</ThemeContext.Consumer>
	}
}

const BooleanChartItemSized = fitWidth(BooleanChartItem);

export default BooleanChartItemSized;