import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import Particles from '/src/components/LandingPage/Particles'
import { withRouter } from 'react-router-dom'
import { SplashScreen } from '@capacitor/splash-screen';
import AuthenticationService from '/src/services/authentication'
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import Api from "/generated/cicada-client";
import { serverUrl } from '/src/services/common'

interface Props {
	onCodeEnter: (code: string) => void;
	wrongCode?: boolean;
}
interface State {
	code?: string,
}

class CodeInput extends React.Component<Props, State>  {
	constructor(props) {
		super(props);
		this.state = { code: undefined };
		this.codeInput = React.createRef();
		setTimeout(() => this.codeInput.current.focus(), 200);
	}

	codeInput: any

	requestId: string
	codeAttempts: number

	render() {
		document.title = "Cicada";

		return <div style={{ animation: "fadeInFromNone 1s ease-out" }}>
			<Row className="font16 mtop60">
				<FormattedMessage id="entrance.enter_code" />
			</Row>
			<Row className="mtop10" crossAxis="center" style={{ height: 41 }}>
				<input
					ref={this.codeInput}
					className="border-bottom-letters"
					style={{
						fontWeight: "bold",
						textAlign: "left",
						fontSize: 45,
						width: "12ch",
						fontFamily: 'monospace',
						animation: this.props.wrongCode ? "shake 0.82s cubic-bezier(.36,.07,.19,.97) both" : undefined
					}}
					value={this.state.code}
					maxLength={8}
					type="text"
					required
					onChange={e => {
						this.setState({ code: e.target.value });
						if (e.target.value.length == 8) {
							this.props.onCodeEnter(e.target.value)
						}
					}}
				/>
				{this.props.wrongCode === false && <div className="loader mleft30"></div>}
			</Row>
		</div>
	}
}

export default withRouter(CodeInput);