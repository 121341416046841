import * as React from "react";
import { ChartCanvas, Chart, series } from "react-stockcharts";
import { CandlestickSeries, OHLCSeries, LineSeries, BarSeries, ScatterSeries, TriangleMarker, CircleMarker, AreaSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import { last } from "react-stockcharts/lib/utils";
import { fitWidth } from "react-stockcharts/lib/helper";
import { CrossHairCursor, EdgeIndicator, CurrentCoordinate, MouseCoordinateX, MouseCoordinateY } from "react-stockcharts/lib/coordinates";
import { ClickCallback } from "react-stockcharts/lib/interactive";
import { SingleValueTooltip } from "react-stockcharts/lib/tooltip";
import { HoverTooltip } from "react-stockcharts/lib/tooltip";
import { isMobile, Column, Row } from '/src/components/Layout'
import { LineModel, IToken, TokenType } from './EditorModels';
import { LabelAnnotation, Label, Annotate, SvgPathAnnotation, buyPath } from "react-stockcharts/lib/annotation";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import { niceNumber, niceSignedNumber } from '/src/utils/numbers';
import { ChartTimeItem } from '/src/services/strategies'
import { svg_pather } from "/src/utils/svgPather";
import Loader from "/src/components/Loader";
import { ThemeContext } from "/src/components/ThemeProvider";


export interface ChartContext {
	metaSubscribe: (eventType: string, subscriberId: string, metaListener: (input: any) => void) => void,
	triggerMetaEvent: (eventType: string, senderId: string, eventData: any) => void
}

class ChartItem extends React.Component<{
	type: any, width: any, ratio: any, data: ChartTimeItem[], color: string, tokens: IToken[],
	metaId: number, paddingPercent: number, chartContext: ChartContext, instruments: string[], selected: IToken, onHover: (currentItem: ChartTimeItem) => void
}> {

	onMouseEvent = (moreProps, event) => {
		if (moreProps && moreProps.currentItem) {
			this.props.onHover(moreProps.currentItem)
		}
	}

	render() {

		if (this.props.data.length < 2 || this.props.tokens.some(l => l.dataIndex === undefined) || this.props.tokens.some(l => this.props.data[1].values.length <= l.dataIndex)) {
			return <Loader />
		}

		const { type, width, ratio } = this.props;
		const xScaleProvider = discontinuousTimeScaleProvider
			.inputDateAccessor((d: ChartTimeItem) => new Date(d.timestamp));
		const {
			data,
			xScale,
			xAccessor,
			displayXAccessor,
		} = xScaleProvider(this.props.data);

		const start = xAccessor(last(data));
		const end = xAccessor(data[Math.max(0, data.length - (isMobile ? 40 : 120))]);
		const xExtents = [start, end];

		const isSource = this.props.tokens.find(t => t.type === TokenType.Instrument);

		const hasYAxis = true;
		let height = isSource ? 300 : (hasYAxis ? 120 : this.props.tokens.length * 25 + 20);
		if (isMobile) {
			height /= 1.5;
		}

		const paddingCoef = this.props.paddingPercent ? (1 + this.props.paddingPercent / 100) : 1;

		const annotationProps = {
			fontFamily: "Open Sans",
			fontWeight: "bold",
			fontSize: 15,
			fill: "#505865",
			opacity: 0.8,
			text: (d: ChartTimeItem) => niceSignedNumber((d.closeDeals[0].closePrice - d.closeDeals[0].openPrice) / d.closeDeals[0].openPrice * 100, 1) + "%",
			y: ({ yScale, datum }) => yScale(datum.closeDeals[0].closePrice) - 19
		};

		// https://yqnn.github.io/svg-path-editor/
		// remove spaces around letters
		const openLongIcon = "M-12 0V2H-3.41L-13 11.59L-11.59 13L-2 3.41V12H0V0Z";
		const closeLongIcon = "M12 -0V-2H3.41L13 -11.59L11.59 -13L2 -3.41V-12H0V0Z";
		const openShortIcon = "M-0 -12H-2V -3.41L-11.59 -13L-13 -11.59L-3.41 -2H-12V0H0Z";
		const closeShortIcon = "M0 12H2V3.41L11.59 13L13 11.59L3.41 2H12V0H0Z";

		const selectedInSection = (this.props.selected && this.props.tokens.some(t => t.position == this.props.selected.position));

		return <ThemeContext.Consumer>
			{(theme) => <Row
				className="top7 bottom10" style={{ height: height }}
				onMouseLeave={() => { this.props.onHover(undefined) }
				}>
				<ChartCanvas metaId={this.props.metaId} ratio={ratio} width={width} height={height}
					metaSubscribe={this.props.chartContext.metaSubscribe}
					triggerMetaEvent={this.props.chartContext.triggerMetaEvent}
					useCrossHairStyleCursor={true}
					type="hybrid"
					zoomEvent={false}
					margin={{ left: 0, right: 60, top: 0, bottom: 20 }}
					xScale={xScale}
					xAccessor={xAccessor}
					displayXAccessor={displayXAccessor}
					xExtents={xExtents}
					data={data}>
					<Chart id={0}
						padding={1}
						yExtentsCalculator={({ plotData }: { plotData: ChartTimeItem[] }) => {
							const aaa = plotData.map((d: ChartTimeItem) => {
								return this.props.tokens.map(l => d.values[l.dataIndex].length == 4 ? [d.values[l.dataIndex][1], d.values[l.dataIndex][2]] : [])
							}
							);
							const bbb = aaa.flat(2);

							const minmax = bbb.reduce((a, b) => {
								return {
									min: Math.min(a.min, isNaN(b) ? Number.MAX_SAFE_INTEGER : b),
									max: Math.max(a.max, isNaN(b) ? Number.MIN_SAFE_INTEGER : b)
								}
							}, { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER });

							//console.log({instr: this.props.instruments})

							//console.log(minmax);
							const padding = (minmax.max - minmax.min) * 0.2;
							plotData.forEach((d: ChartTimeItem) => {



								d.closeDeals && d.closeDeals.forEach(closeDeal => {
									if (this.props.instruments.indexOf(closeDeal.symbol) !== -1) {
										const level = closeDeal.closePrice + padding;
										minmax.max = Math.max(minmax.max, level);
										minmax.min = Math.min(minmax.min, level);
									}
								});
								d.openDeals && d.openDeals.forEach(openDeal => {
									if (this.props.instruments.indexOf(openDeal.symbol) !== -1) {

										const chartPrice = openDeal.openPrice > 0 ? openDeal.openPrice : openDeal.openingPrice;
										const level = chartPrice - padding;
										minmax.max = Math.max(minmax.max, level);
										minmax.min = Math.min(minmax.min, level);
									}
								});
								return undefined;
							});

							//console.log({ min: minmax.min, max: minmax.max })

							return [minmax.min, minmax.max]
						}}>
						<ClickCallback
							onMouseMove={this.onMouseEvent}
						//onMouseDown={(moreProps, e) => { console.log("onMouseDown", moreProps, e); }}
						//onClick={(moreProps, e) => { console.log("onClick", moreProps, e); }}
						//onDoubleClick={(moreProps, e) => { console.log("onDoubleClick", moreProps, e); }}
						//onContextMenu={(moreProps, e) => { console.log("onContextMenu", moreProps, e); }}
						//onPan={(moreProps, e) => { console.log("onPan", moreProps, e); }}
						//onPanEnd={(moreProps, e) => { console.log("onPanEnd", moreProps, e); }}
						/>
						<XAxis
							axisAt="bottom"
							orient="bottom"
							zoomEnabled={false}
							fontFamily="Open Sans"
							fontSize={12}
							ticks={6} innerTickSize={-1000} tickStrokeOpacity={0.2}
							stroke={theme.cssVar('--bg-main')}
							tickStroke={theme.cssVar('--color-alt')} />
						{hasYAxis &&
							<YAxis
								axisAt="right"
								orient="right"
								zoomEnabled={false}
								// tickInterval={5}
								// tickValues={[40, 60]}
								ticks={5} innerTickSize={-1000} tickStrokeOpacity={0.2}
								fontFamily="Open Sans"
								fontSize={12}
								stroke={theme.cssVar('--bg-main')}
								tickStroke={theme.cssVar('--color-alt')}
							/>
						}
						{this.props.tokens.map((token, index) => {
							const color = theme.cssVar("--color-main");
							return token.type === TokenType.Instrument ?
								<CandlestickSeries
									key={index}
									yAccessor={(d: ChartTimeItem) => ({
										open: d.values[token.dataIndex][0],
										high: d.values[token.dataIndex][1],
										low: d.values[token.dataIndex][2],
										close: d.values[token.dataIndex][3]
									})}
									opacity={1}
									stroke={(d) => d.close > d.open ? color : color}
									wickStroke={(d) => d.close > d.open ? color : color}
									fill={(d) => d.close > d.open ? theme.cssVar("--bg-main") : color} />
								:
								<LineSeries
									key={index}
									yAccessor={(d: ChartTimeItem) => d.values[token.dataIndex][3]}
									strokeWidth={(selectedInSection && this.props.selected.position === token.position) ? 3 : 2}
									connectNulls={true}
									stroke={color}
								/>
						})}

						<Annotate with={SvgPathAnnotation} when={(d: ChartTimeItem) => d && d.openDeals && this.props.instruments.indexOf(d.openDeals[0].symbol) !== -1}
							usingProps={{
								fill: theme.cssVar('--color-alt'),
								path: ({ x, y }) => svg_pather(x, y, openLongIcon),
								tooltip: "Go long",
								y: ({ yScale, datum }) => yScale(datum.openDeals[0].openPrice > 0 ? datum.openDeals[0].openPrice : datum.openDeals[0].openingPrice)
							}} />

						<Annotate with={SvgPathAnnotation} when={(d: ChartTimeItem) => d && d.closeDeals && this.props.instruments.indexOf(d.closeDeals[0].symbol) !== -1}
							usingProps={{
								fill: theme.cssVar('--color-alt'),
								path: ({ x, y }) => svg_pather(x, y, closeLongIcon),
								tooltip: "Close",
								y: ({ yScale, datum }) => yScale(datum.closeDeals[0].closePrice)
							}} />

						{hasYAxis && this.props.tokens.map((line, index) =>
							<EdgeIndicator key={index} itemType="last" orient="right" edgeAt="right"
								fontFamily="Open Sans"
								fontSize={11}
								displayFormat={d => d.toFixed(line.precision)}
								arrowWidth={0}
								yAccessor={(d: ChartTimeItem) => d.values[line.dataIndex][3]} fill="#505865" textFill="white" />)}

						{!isMobile && <MouseCoordinateX
							at="bottom"
							orient="bottom"
							fill="#F2F3F5"
							opacity={1}
							fontFamily="Open Sans"
							fontSize={10}
							textFill="#505865"
							displayFormat={timeFormat("%Y-%m-%d %H:%M")} />}
						{!isMobile && <CrossHairCursor />}
						{/*<Annotate with={LabelAnnotation}
						when={(d: ChartTimeItem) => d.closeDeals !== undefined && this.props.instruments.indexOf(d.closeDeals[0].symbol) !== -1}
					usingProps={annotationProps} />*/}

					</Chart>
				</ChartCanvas>
			</Row >}</ThemeContext.Consumer>
	}
}

const ChartItemSized = fitWidth(ChartItem);

export default ChartItemSized;