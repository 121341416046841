import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { HiEye, HiEyeSlash, HiMiniEllipsisVertical, HiOutlineEye } from "/node_modules/react-icons/hi2/index";
import { MenuItem } from "../MenuItem";
import { Listbox } from "/node_modules/@headlessui/react/dist/index";
import { ChartMode } from "../EditorModels";

interface Props {
	chart: ChartMode,
	onChanged: (mode: ChartMode) => void,
}



const ChartTypeMenu: React.FunctionComponent<Props> = (props) => {

	const items: MenuItem[] = [
		{ icon: <HiEye style={{ height: 17, width: 17 }} />, text: "Own chart", action: () => { props.onChanged(ChartMode.Own) }, isEnabled: true },
		{ icon: <HiOutlineEye style={{ height: 17, width: 17 }} />, text: "Shared chart", action: () => { props.onChanged(ChartMode.Shared) }, isEnabled: true },
		{ icon: <HiEyeSlash style={{ height: 17, width: 17 }} />, text: "No chart", action: () => { props.onChanged(ChartMode.Hidden) }, isEnabled: true }
	]

	const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
	return <div style={{ position: "relative" }}>
		<Listbox value={items[0]} onChange={v => { }}>
			{({ open }) => (
				<>
					<Listbox.Button>
						<Row className={"hoverable mleft4 " + (open ? "bg-button" : "")} mainAxis="center" crossAxis="center"
							style={{ borderRadius: 13, height: 27, width: 27 }}
							onClick={(e) => setIsExpanded(!isExpanded)}>
							{props.chart === ChartMode.Own && <HiEye style={{ height: 17, width: 17 }} />}
							{props.chart === ChartMode.Shared && <HiOutlineEye style={{ height: 17, width: 17 }} />}
							{props.chart === ChartMode.Hidden && <HiEyeSlash style={{ height: 17, width: 17 }} />}
						</Row>
					</Listbox.Button>

					<Listbox.Options>
						<Column className="font14 shadow bg-button white segoe"
							style={{
								zIndex: 10001,
								position: "absolute",
								borderRadius: "5px 0px 5px 5px",
								right: 0, top: 35
							}}>
							{
								items.map(item => <Row key={item.text} className={item.isEnabled ? "hoverable pointer color-main" : "color-alt"}
									crossAxis="center"
									style={{ width: 180, height: 35 }} onClick={() => {
										if (item.isEnabled) {
											item.action();
											setIsExpanded(false);
										}
									}}>
									<Row mainAxis="center" style={{ width: 35 }}>
										{item.icon}
									</Row>
									{item.text}
								</Row>)
							}

						</Column>
					</Listbox.Options>
				</>)}
		</Listbox>
	</div>
}

export default ChartTypeMenu;