import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import Select, { components } from 'react-select';
import { countries_with_codes, CountryData } from "/src/utils/countries";

export interface KeyValue {
	value: any,
	label: string
}

interface Props {
	value?: any,
	onValueChange: (value: any) => void,
	label: string,
	isMulti?: boolean,
	className?: string,
	tip?: string,
	disabled?: boolean
}

const customStyles = {
	option: (provided, state) => {
		return ({
			...provided,
			color: 'black',
			fontWeight: state.isSelected ? 600 : 400,
			backgroundColor: state.isFocused ? '#F4F4F4' : 'white'
		})
	},
	singleValue: (provided, state) => ({
		...provided,
		color: state.isDisabled ? '#6E7684' : '#505865'
	}),
	control: (provided, state) => ({
		...provided,
		'&:hover': { borderColor: '#dddddd' },
		boxShadow: undefined,
		borderColor: '#dddddd',
		borderRadius: '0px',
		fontSize: '15px',
		fontWeight: 600,
		minHeight: 37,
		height: 37,
		backgroundColor: state.isDisabled ? '#F9FAFB' : 'white',
	}),
	menu: (provided, state) => ({
		...provided,
		width: 300
	})
}

const SingleValue = props => (
	<components.SingleValue {...props}>
		<img className="mtop4 bor4"
			alt="flag"
			style={{ width: 28, filter: "grayscale(50%)" }}
			src={"https://purecatamphetamine.github.io/country-flag-icons/3x2/" + props.data.alpha2 + ".svg"} />
	</components.SingleValue>
);

const Input = props => {
	return <components.Input {...props} autoComplete="custom-country-code" />;
};

const all_country_codes = countries_with_codes
	.map(country => { return { value: country.countryCallingCodes[0], alpha2: country.alpha2, label: country.name + " (" + country.countryCallingCodes[0] + ")", areaCodes: country.areaCodes } })
	.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

const country_by_number = all_country_codes.slice()
	.sort((a, b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0));


const sameBeginning = (string1: string, string2: string) => {
	return string1.indexOf(string2) == 0 || string2.indexOf(string1) == 0;
}

const extractCountryOption = (number, currentlySelectedCountry) => {
	if (number === undefined) return undefined;

	const numberTrimmed = number.replaceAll(' ', '');

	let matches = country_by_number.filter(c => c.areaCodes ?
		c.areaCodes.some(a => sameBeginning(numberTrimmed, c.value + a)) :
		sameBeginning(numberTrimmed, c.value)
	);
	if (matches.length > 1) {
		const matchByArea = matches.find(m => m.areaCodes && m.areaCodes.some(a => numberTrimmed.indexOf(m.value + a) === 0));
		if (matchByArea) {
			return matchByArea;
		}
	}
	const alreadySelected = matches.find(m => m.alpha2 === (currentlySelectedCountry && currentlySelectedCountry.alpha2));
	if (alreadySelected) {
		return alreadySelected;
	}
	const noAreas = matches.find(m => !m.areaCodes);
	if (noAreas) {
		return noAreas;
	}
	return matches.length > 0 ? matches[matches.length - 1] : undefined;
}

const PhoneInput: React.FunctionComponent<Props> = ({ value, onValueChange, label, isMulti, className, tip, disabled }) => {

	const colors = disabled ? "bc0 c16" : "black";

	const [inputValue, setInputValue] = React.useState(value.value || "+");
	const [country, setCountry] = React.useState(() => extractCountryOption(value.value, undefined));

	const propsInitialized = React.useRef(false);
	React.useEffect(() => {
		if (!propsInitialized.current) { propsInitialized.current = true; return; }
		setCountry(extractCountryOption(value.value || "", country))
		setInputValue(value.value || "")
	}, [value.value]);

	return <Column className={"flex1 " + className}>
		<div className="font14" title={tip}>{label}</div>
		<Row className="flex1">
			<Select
				components={{ SingleValue, Input }}
				isDisabled={disabled}
				styles={customStyles}
				value={country}
				isSearchable={true}
				name="color"
				isMulti={isMulti}
				options={all_country_codes}
				onChange={v => {
					setCountry(v)
					setInputValue(v.value)
				}}
			/>
			<input
				size={5}
				disabled={disabled}
				type="tel"
				name="phone"
				className={"flex1 font18 semibold segoe c20 " + colors}
				value={inputValue}
				style={{ height: 35, lineHeight: "35px", paddingLeft: 8, border: "1px solid #dddddd", borderLeftStyle: "none" }}
				onKeyDown={event => { if (event.key === 'Enter') onFinish(); }}
				onBlur={e => { }}
				onChange={(event) => {
					setCountry(extractCountryOption(event.target.value, country))
					setInputValue(event.target.value);
					onValueChange(event.target.value)
				}} />
		</Row>
		{value.error && !value.hideError && <Row mainAxis="flex-end" className="font11 italic semibold error-blink">{value.error}</Row>}
	</Column>
}


export default PhoneInput;