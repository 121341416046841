import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import { Link } from "react-router-dom";
import { PublicUserModel } from '/src/services/users'
import { StrategyInfo } from "/src/services/strategies";
import { injectIntl } from "react-intl";

interface Props {
	author: PublicUserModel,
	currentStrategy: StrategyInfo,
	onStrategyDescriptionChanged: (description: string) => void,
	ownStrategy: boolean,
	intl: any
}

interface State {
	currentDescription: string
}

class Description extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			currentDescription: props.currentStrategy ? props.currentStrategy.description : ""
		};
	}

	componentWillReceiveProps(nextProps: Props) {
		if (!this.props.currentStrategy || nextProps.currentStrategy.id != this.props.currentStrategy.id) {
			this.setState({
				currentDescription: nextProps.currentStrategy ? nextProps.currentStrategy.description || "" : ""
			});
		}
	}

	render() {
		const props = this.props;
		return isDesktop ?
			<Row mainAxis="flex-end" style={{ marginTop: 30, marginBottom: 30 }} >
				<Column>
					<textarea disabled={!props.ownStrategy} placeholder={props.intl.formatMessage({ id: "strategy.describe_here" })}
						style={{ width: 460, fontSize: 18, textAlign: "right" }} className="italic segoe" value={this.state.currentDescription}
						rows={2}
						onBlur={e => { this.props.onStrategyDescriptionChanged(this.state.currentDescription); }
						} onChange={e =>
							this.setState({ currentDescription: e.target.value })
						} ></textarea>
					<div className="font14 dimmed" style={{ overflow: "visible", zIndex: 1 }}>
						{/*<Link to={{ pathname: "/profile/" + (this.props.author && this.props.author.id), state: undefined }}>*/}
						<Row crossAxis="center" mainAxis="flex-end" style={{ whiteSpace: "nowrap", marginTop: 10 }}>
							{this.props.author && <div className="font14 left5">by {this.props.author.nickname}</div>}
						</Row>
						{/*</Link>*/}
					</div>
				</Column>
			</Row >
			:
			<Row mainAxis="flex-end" className="mright20" style={{ marginTop: 30, marginBottom: 30 }}>
				<Column>
					<textarea disabled={!props.ownStrategy} placeholder={props.intl.formatMessage({ id: "strategy.describe_here" })}
						style={{ width: 460, fontSize: 18, textAlign: "right" }} className="italic segoe" value={this.state.currentDescription}
						rows={2}
						onBlur={e => { this.props.onStrategyDescriptionChanged(this.state.currentDescription); }
						} onChange={e =>
							this.setState({ currentDescription: e.target.value })
						} ></textarea>
					<div className="font14 dimmed" style={{ overflow: "visible", zIndex: 1 }}>
						{/*<Link to={{ pathname: "/profile/" + (this.props.author && this.props.author.id), state: undefined }}>*/}
						<Row crossAxis="center" mainAxis="flex-end" style={{ whiteSpace: "nowrap", marginTop: 10 }}>
							{this.props.author && <div className="font14 left5">by {this.props.author.nickname}</div>}
						</Row>
						{/*</Link>*/}
					</div>
				</Column>
			</Row>
	}
}

export default React.memo(injectIntl(Description), (prev, next) => prev.currentStrategy && next.currentStrategy && prev.currentStrategy.id === next.currentStrategy.id);