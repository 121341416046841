import * as React from "react";
import { Column, isDesktop, Row } from '/src/components/Layout'
import { ChartMode, EditingCallbacks, FunctionCall, IndicatorToken, InstrumentToken, IToken, LineModel, MovingCallbacks, SectionModel, TokenType, TradeRuleToken } from "./EditorModels";
import TokenText from "./TokenText";
import { ChartTimeItem } from "/src/services/strategies";
import BooleanChartItem from "./BooleanChartItem";
import TradeRuleEditingLine from "./Plot/TradeRuleEditingLine";
import CodeLine from "./Plot/CodeLine";
import { HiPlus } from "/node_modules/react-icons/hi2/index";

interface Props {
	token: TradeRuleToken,
	onSelect: (codeLine: IToken) => void,
	selected: IToken,
	metaId: number,
	editable: boolean,
	instrumentOptions: InstrumentToken[],
	metaSubscribe: (eventType, subscriberId, metaListener) => void,
	triggerMetaEvent: (eventType, senderId, eventData) => void,
	chartData: ChartTimeItem[],
	onHover: (currentItem: ChartTimeItem) => void,
	allTokens: IToken[],
	hoveredItem: ChartTimeItem,
	editingCallbacks: EditingCallbacks,
	movingCallbacks: MovingCallbacks
}

const createDefaultCondition = (allOptions: IToken[]): IndicatorToken => {
	return {
		functionName: "CrossDown",
		parameters: [allOptions[Math.max(0, allOptions.length - 1)].name, allOptions[Math.max(0, allOptions.length - 2)].name],
		name: undefined,
		position: undefined,
		dataIndex: undefined,
		chart: ChartMode.Own,
		type: TokenType.Indicator
	};
}

const TradeSection: React.FunctionComponent<Props> = (props) => {

	const getLastValue = (token: IToken, chartData: ChartTimeItem[]) => {

		if (props.hoveredItem && props.hoveredItem.values[token.dataIndex]) {
			return props.hoveredItem.values[token.dataIndex][3];
		}

		if (!chartData || token.dataIndex === undefined ||
			chartData.length === 0 || chartData[0].values === undefined ||
			chartData[0].values[token.dataIndex] === undefined) {
			return undefined;
		}
		for (let i = chartData.length - 1; i >= 0; i--) {
			if (chartData[i].values[token.dataIndex][3] !== undefined) {
				return chartData[i].values[token.dataIndex][3];
			}
		}
		return undefined;
	}

	const style = (props.token === props.selected) ? "bg-button" : "";

	return <Column>

		<Row style={{ flexWrap: "wrap", rowGap: 5 }} className="">

			<CodeLine
				instrumentOptions={props.instrumentOptions}
				editingCallbacks={props.editingCallbacks}
				movingCallbacks={props.movingCallbacks}
				options={props.allTokens}
				chartMode={ChartMode.Own}
				editable={props.editable}
				key={"tradeRule" + 0}
				isSelected={(props.token === props.selected)}
				token={props.token}
				value={0}
				onClick={() => props.onSelect(props.token)}
			/>

			{props.token.openConditions.map((condition, indexInSection) =>
				<Column>
					<Row className="font8 semibold">{indexInSection == 0 ? "OPEN POSITION WHEN" : "AND"}</Row>
					<CodeLine token={condition}
						isSelected={condition === props.selected}
						onClick={() => props.onSelect(condition)}
						editable={props.editable}
						value={getLastValue(condition, props.chartData)}
						chartMode={ChartMode.Shared}
						options={props.allTokens}
						instrumentOptions={props.instrumentOptions}
						editingCallbacks={props.editingCallbacks}
						movingCallbacks={props.movingCallbacks}
						isBoolean={true} />
				</Column>
			)}
			<Row className="border hoverable pointer mtop10" mainAxis="center" onClick={e => {
				const updatedInstrument: TradeRuleToken = { ...props.token };
				updatedInstrument.openConditions.push(createDefaultCondition(props.allTokens));
				props.editingCallbacks.onTradeRuleUpdated(updatedInstrument);
			}} style={{
				position: "relative",
				boxSizing: "border-box",
				alignItems: "center",
				borderRadius: "17px",
				marginRight: 5, height: 27, width: 27
			}}><HiPlus style={{ height: 19, width: 19 }} /></Row>

			{props.token.closeConditions.map((condition, indexInSection) =>
				<Column>
					<Row className="font8 semibold">{indexInSection == 0 ? "CLOSE POSITION WHEN" : "AND"}</Row>
					<CodeLine token={condition}
						isSelected={condition === props.selected}
						onClick={() => props.onSelect(condition)}
						editable={props.editable}
						value={getLastValue(condition, props.chartData)}
						chartMode={ChartMode.Shared}
						options={props.allTokens}
						instrumentOptions={props.instrumentOptions}
						editingCallbacks={props.editingCallbacks}
						movingCallbacks={props.movingCallbacks}
						isBoolean={true} />
				</Column>
			)}
			<Row className="border hoverable pointer mtop10" mainAxis="center" onClick={e => {
				const updatedInstrument: TradeRuleToken = { ...props.token };
				updatedInstrument.closeConditions.push(createDefaultCondition(props.allTokens));
				props.editingCallbacks.onTradeRuleUpdated(updatedInstrument);
			}} style={{
				position: "relative",
				boxSizing: "border-box",
				alignItems: "center",
				borderRadius: "17px",
				marginRight: 5, height: 27, width: 27
			}}><HiPlus style={{ height: 19, width: 19 }} /></Row>
		</Row>


		<Row mainAxis="flex-end" className="font11 mright60">Opening conditions</Row>
		<BooleanChartItem
			isSelected={false}
			hasXAxis={false}
			metaId={"chart_open_" + props.metaId + "_" + 0}
			chartContext={{ metaSubscribe: props.metaSubscribe, triggerMetaEvent: props.triggerMetaEvent }}
			data={props.chartData}
			tokens={props.token.openConditions}
			onHover={props.onHover}
		/>
		<Row mainAxis="flex-end" className="font11 mright60">Closing conditions</Row>
		<BooleanChartItem
			isSelected={false}
			hasXAxis={false}
			metaId={"chart_open_" + props.metaId + "_" + 1}
			chartContext={{ metaSubscribe: props.metaSubscribe, triggerMetaEvent: props.triggerMetaEvent }}
			data={props.chartData}
			tokens={props.token.closeConditions}
			onHover={props.onHover}
		/>
	</Column >

}


export default TradeSection;