import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { withRouter } from 'react-router-dom'
import PageContainer from '../components/PageContainer'
import WalletsService from '/src/services/wallets'
import StrategyService from '/src/services/strategies'
import { FormattedMessage, FormattedDate } from 'react-intl';
import { Link } from "react-router-dom";
import Back from '/src/components/Back'
import AccountsService, { AchRelationship, BankData } from "/src/services/accounts";
import { Slide, toast, ToastContainer } from "react-toastify/dist";
import Loader from "/src/components/Loader";
import Button from "/src/components/Button";

interface Props {
	history: any
}

interface State {
	amount: string,
	ach: AchRelationship,
	bank: BankData,
	selectedAch: AchRelationship,
	selectedBank: BankData
}

class WithdrawPage extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { amount: "", ach: undefined, selectedAch: undefined, bank: undefined, selectedBank: undefined };
	}

	async componentDidMount() {
		let [achResponse, bankResponse] = await Promise.all([
			AccountsService.getAch(),
			AccountsService.getBank()
		]);

		this.setState({
			ach: achResponse === undefined ? null : achResponse,
			bank: bankResponse === undefined ? null : bankResponse
		})
	}
	render() {
		document.title = "Wallet " + String.fromCharCode(183) + " Cicada";

		return <PageContainer maxWidth={700} history={this.props.history} tab={1}>
			<Column>

				{
					this.state.selectedAch !== undefined ?
						<Column>
							<Row className="mtop30">
								<Back onBack={() => { this.setState({ selectedAch: undefined }) }} />
							</Row>
							<Column crossAxis="center" className="top50 font16">
								<div>Withdraw with <span className="bold mleft5">{this.state.selectedAch.nickname}</span></div>
								<Row crossAxis="center" className="mtop15">
									<input className="left10 tab-border1 font25" value={this.state.amount} style={{ width: 120, textAlign: "right" }}
										onChange={e => this.setState({ amount: e.target.value })} />
									<div className="left10">USD</div>
								</Row>
								<Button label="Withdraw" className="mtop15" onClick={async () => {
									var amountDouble = parseFloat(this.state.amount);
									if (amountDouble > 0) {
										const result = await WalletsService.requestWithdrawal(this.state.ach.id, amountDouble);
										if (result.error) {
											toast(result.error, { theme: "dark" })
										}
										else {
											this.props.history.push("/wallet/");
										}
									}
								}} />
							</Column>
						</Column>
						:
						this.state.ach === undefined ?
							<Loader /> :
							[
								<Row className="mtop30">
									<Link to="/wallet">
										<Back />
									</Link>
								</Row>,
								<Column className="mtop50 font16" crossAxis="center">
									{this.state.ach === null && this.state.bank === null ?
										<Row>You have not added any transfer method yet</Row>
										:
										<Column>
											{this.state.ach && <Row mainAxis="center" crossAxis="center"
												onClick={() => {
													this.setState({ selectedAch: this.state.ach })
												}}
												className="bc2 bor7 pointer mtop15" style={{ width: 300, height: 50, borderRadius: 2, padding: 7 }}>
												Withdraw with ACH <span className="bold mleft5">{this.state.ach.nickname}</span>
											</Row>}
											{this.state.bank && <Row mainAxis="center" crossAxis="center"
												onClick={() => {
													this.setState({ selectedBank: this.state.bank })
												}}
												className="bc2 bor7 pointer mtop15" style={{ width: 300, height: 50, borderRadius: 2, padding: 7 }}>
												Withdraw with Bank Account <span className="bold mleft5">{this.state.bank.bankName}</span>
											</Row>}
										</Column>}

									<Row className="mtop70">
										<Button label="Manage my transfer methods" link="/profile/banks/" />
									</Row>
								</Column>
							]

				}
			</Column>

			<ToastContainer
				limit={4}
				transition={Slide}
				position="bottom-center"
				autoClose={5000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover />
		</PageContainer>
	}
}


export default withRouter(WithdrawPage);