import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import Positions from './Positions'
import candlesticks from '/src/assets/candlesticks.svg';
import indicator from '/src/assets/indicator.svg';
import dollar from '/src/assets/dollar.svg';
import { InstrumentsCache, InstrumentGroup } from '/src/services/instrumentsCache';
import { FormattedMessage, FormattedDate, } from 'react-intl';
import search from '/src/assets/search.svg';
import { LineModel, Parameter, InstrumentToken, DataSource, BarField, Timeframe, InstrumentTokenToLineModel } from '../EditorModels'
import close_cross from '/src/assets/close_cross.svg';
import { niceSignedNumber, niceNumber } from "/src/utils/numbers";
import { Position, PositionStatus } from "/generated/cicada-client";

interface Props {
	trade: Position,
	onPanelClosed: () => void,
}

const TradePanel: React.FunctionComponent<Props> = ({ trade, onPanelClosed }) => {

	return <Column className="segoe pleft25 pright25 font14">
		<Row mainAxis="flex-end" className="mtop10">
			<Row mainAxis="center" crossAxis="center" className="pointer hoverable" style={{ width: 30, height: 30 }}
				onClick={() => onPanelClosed()}>
				<img style={{ width: 16 }} src={close_cross} alt="close_cross" />
			</Row>
		</Row>
		<Row mainAxis="center" className="mtop35 font22 semibold">{trade.symbol} trade</Row>
		<Row className="mtop35">
			<div className="flex1"><FormattedMessage id="trade.status" /></div>
			<div className="flex1 textRight">{PositionStatus[trade.status]}</div>
		</Row>
		<Row className="mtop6">
			<div className="flex1"><FormattedMessage id="trade.id" /></div>
			<div className="flex1 textRight">{trade.id}</div>
		</Row>
		<Row className="mtop35">
			<div className="flex1"><FormattedMessage id="trade.signal_volume" /></div>
			<div className="flex1 textRight">{trade.signalRelativeVolume * 100 + "% of equity"}</div>
		</Row>
		<Row className="mtop6">
			<div className="flex1"><FormattedMessage id="trade.volume" /></div>
			<div className="flex1 textRight">{+trade.volume.toFixed(3)} lots</div>
		</Row>
		<Row className="mtop6">
			<div className="flex1"><FormattedMessage id="trade.reserved_margin" /></div>
			<div className="flex1 textRight">{niceNumber(trade.margin, 2)} USD</div>
		</Row>

		{new Date(trade.openTime).valueOf() > 0 ? <Column>
			<Row className="mtop35">
				<div className="flex1"><FormattedMessage id="trade.open_time" /></div>
				<div className="flex1 textRight">{<FormattedDate value={new Date(trade.openTime)} hourCycle="h23" year='2-digit' month='short' day='numeric' hour="numeric" minute="numeric" second="numeric" />}</div>
			</Row>
			<Row className="mtop6">
				<div className="flex1"><FormattedMessage id="trade.open_price" /></div>
				<div className="flex1 textRight">{trade.openPrice} {trade.instrumentCurrency}</div>
			</Row>
			<Row className="mtop6">
				<div className="flex1"><FormattedMessage id="trade.currency_conversion" /></div>
				<div className="flex1 textRight">1</div>
			</Row>
			<Row className="mtop6 semibold">
				<div className="flex1"><FormattedMessage id="trade.MV_on_open" /></div>
				<div className="flex1 textRight">{niceNumber(trade.openPrice * trade.volume * 1, 2)} USD</div>
			</Row>
		</Column> : <Column>
			<Row className="mtop35">
				<div className="flex1"><FormattedMessage id="trade.opening_time" /></div>
				<div className="flex1 textRight">{<FormattedDate value={new Date(trade.openingTime)} hourCycle="h23" year='2-digit' month='short' day='numeric' hour="numeric" minute="numeric" second="numeric" />}</div>
			</Row>
			<Row className="mtop6">
				<div className="flex1"><FormattedMessage id="trade.opening_price" /></div>
				<div className="flex1 textRight">{trade.openingPrice} {trade.instrumentCurrency}</div>
			</Row>
			<Row className="mtop6">
				<div className="flex1"><FormattedMessage id="trade.currency_conversion" /></div>
				<div className="flex1 textRight">1</div>
			</Row>
			<Row className="mtop6 semibold">
				<div className="flex1"><FormattedMessage id="trade.MV_on_open" /></div>
				<div className="flex1 textRight">{niceNumber(trade.openingPrice * trade.volume * 1, 2)} USD</div>
			</Row>
		</Column>}

		{trade.status !== PositionStatus.Rejected && <Column>
			<Row className="mtop35">
				<div className="flex1"><FormattedMessage id="trade.close_time" /></div>
				<div className="flex1 textRight">{<FormattedDate value={new Date(trade.closeTime)} year='2-digit' month='short' day='numeric' hour="2-digit" minute="2-digit" second="2-digit" hourCycle="h23" />}</div>
			</Row>
			<Row className="mtop6">
				<div className="flex1"><FormattedMessage id="trade.close_price" /></div>
				<div className="flex1 textRight">{trade.closePrice} {trade.instrumentCurrency}</div>
			</Row>
			<Row className="mtop6">
				<div className="flex1"><FormattedMessage id="trade.currency_conversion" /></div>
				<div className="flex1 textRight">1</div>
			</Row>
			<Row className="mtop6 semibold">
				<div className="flex1"><FormattedMessage id="trade.MV_on_close" /></div>
				<div className="flex1 textRight">{niceNumber(trade.closePrice * trade.volume * 1, 2)} USD</div>
			</Row>
		</Column>}

		{trade.status !== PositionStatus.Rejected ? <Row className="mtop35 font16 semibold">
			<div className="flex1"><FormattedMessage id="trade.trade_result" /></div>
			<div className="flex1 textRight">{niceSignedNumber(trade.profit, 2)} USD</div>
		</Row> : <Row className="mtop35 font16 semibold">
			<div>{trade.comment}</div>
		</Row>}
	</Column>
}




export default TradePanel;