import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { Link } from "react-router-dom";
import StrategyService, { ChartTimeItem, IndicatorModel, StrategyInfo, StrategyState, StrategyStatement, TestStatus, TimeValue } from '/src/services/strategies'
import { FormattedMessage, FormattedDate } from 'react-intl';
import { PublicUserModel } from "/src/services/users";
import { niceNumber, signedPercent } from "/src/utils/numbers";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Description from "./Description";
import InvestmentStats from "./InvestmentStats";
import { TransactionModel } from "/src/services/wallets";
import DemoStats from "./DemoStats";
import Api, { ErrorCode } from "/generated/cicada-client";
import { serverUrl } from '/src/services/common'
import AuthenticationService from "/src/services/authentication";
const api = new Api(serverUrl, undefined, async () => { return await AuthenticationService.getAuthHeaderValue(); });

interface Props {
	strategy: StrategyInfo,
	author: PublicUserModel,
	demoStatement?: StrategyStatement,
	investmentStatement?: StrategyStatement,
	ownStrategy: boolean,
	history: any,
	hasTradeRules: boolean,
	onStrategyDescriptionChanged: (description: string) => void,
	backtestStatus: TestStatus,
	positions: Position[],
	dealsStats: DealStats,
	transactions: TransactionModel[],
	onInvesting: () => void,
	onStopInvesting: () => void
}

interface State {
}

const StatsTab: React.FunctionComponent<Props> = (props) => {
	const [isSubscribed, setIsSubscribed] = React.useState<boolean>(undefined);

	React.useEffect(() => {
		// declare the data fetching function
		const fetchData = async () => {
			const data = await api.signalSubscriptions.listSubscriptions();
			setIsSubscribed(data.some(x => x.strategyId === props.strategy.id));
		}
		if (props.strategy) {
			fetchData()
		}
	}, [props.strategy])

	const onSubscribe = async () => {
		if ((await api.signalSubscriptions.addSubscription({ strategyId: props.strategy.id })).errorCode == ErrorCode.Ok) {
			setIsSubscribed(true);
		}
	}

	const onUnsubscribe = async () => {
		if ((await api.signalSubscriptions.deleteSubscription({ strategyId: props.strategy.id })).errorCode == ErrorCode.Ok) {
			setIsSubscribed(false);
		}
	}

	return <Column className="mleft10 mright10">
		{props.strategy && <Description
			ownStrategy={props.ownStrategy}
			author={props.author}
			onStrategyDescriptionChanged={props.onStrategyDescriptionChanged}
			currentStrategy={props.strategy}
		/>}

		{props.investmentStatement &&
			<InvestmentStats
				onInvesting={props.onInvesting}
				onStopInvesting={props.onStopInvesting}
				investmentStatement={props.investmentStatement}
				transactions={props.transactions}
				openTradesNumber={props.positions.filter(d => d.status === PositionStatus.Open || d.status === PositionStatus.Closing).length} />}

		{props.backtestStatus &&
			<Row mainAxis="center">
				<Column className="mtop40">
					<div style={{ width: 150, height: 150 }} className="semibold">
						<CircularProgressbar
							value={props.backtestStatus.progressPercent}
							text={`${niceNumber(props.backtestStatus.progressPercent, 0)}%`}
							strokeWidth={6}
							styles={buildStyles({
								strokeLinecap: 'round',
								textSize: '22px',
								pathTransitionDuration: 0.5,
								pathColor: '#505865',
								textColor: '#505865',
								trailColor: '#ECF0F5',
								backgroundColor: '#f00',
							})}
						/>
					</div>
					<Row mainAxis="center" className="mtop16 font16 semibold">TEST IN PROGRESS</Row>
				</Column>
			</Row>
		}


		{!props.backtestStatus && props.demoStatement && !props.investmentStatement &&
			<DemoStats demoStatement={props.demoStatement} deals={props.positions}
				dealsStats={props.dealsStats}
				onInvesting={props.onInvesting}
				isSubscribed={isSubscribed}
				onSubscribe={onSubscribe}
				onUnsubscribe={onUnsubscribe} />}

		{props.strategy && !props.backtestStatus && !props.demoStatement && !props.investmentStatement &&
			<Row mainAxis="center" className="flex1 mtop70 font18 segoe">
				This strategy has not been tested yet
			</Row>}
	</Column>
}

export default StatsTab;