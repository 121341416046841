import * as React from "react";
import { Column, isMobile, Row } from '/src/components/Layout'
import { FormattedMessage, FormattedDate, injectIntl, } from 'react-intl';
import search from '/src/assets/search.svg';
import { LineModel, SectionModel, IndicatorToken, Parameter, IToken, ChartMode, TokenType } from '../EditorModels'
import close_cross from '/src/assets/close_cross.svg';
import { IndicatorsCache } from '/src/services/indicatorsCache';
import { IndicatorModel } from '/src/services/strategies'

interface Props {
	intl: any,
	selected: IndicatorToken,
	options: IToken[],
	onIndicatorSelected: (indicator: IndicatorToken) => void,
	onIndicatorUpdated: (indicator: IndicatorToken) => void
	onClose: () => void
}

const GetDefaultLineForIndicator = (indicator: IndicatorModel, options: IToken[]): IndicatorToken => {
	const parameters = indicator ? IndicatorsCache.getIndicator(indicator.name).parameters : [];
	const newParameterValues: Parameter[] = [];
	let o = options.length - 1;
	for (var p = parameters.length - 1; p >= 0; p--) {
		const parameterInfo = parameters[p];
		if (parameterInfo.type === "Integer") {
			newParameterValues.splice(0, 0, 12);
		}
		else {
			newParameterValues.splice(0, 0, options[o].name);
			o = Math.max(0, o - 1);
		}
	}

	let nameIndex = 1;
	let newName;
	do {
		newName = indicator.name + (nameIndex > 1 ? nameIndex : "");
		nameIndex++;
	}
	while (options.find(o => o.name === newName))

	const result: IndicatorToken = {
		name: newName,
		dataIndex: undefined,
		position: undefined,
		chart: ChartMode.Own,
		functionName: indicator.name,
		type: TokenType.Indicator,
		parameters: newParameterValues
	};
	return result;
}

const IndicatorSearchPanel: React.FunctionComponent<Props> = ({ intl, selected, onIndicatorSelected, onIndicatorUpdated, onClose, options }) => {

	const [inputValue, setInputValue] = React.useState("");

	return <Column className="segoe"  style={{height: "100%"}}>
		<Row style={{ fontSize: 14 }} className="bold mtop15" mainAxis="center">SELECT FUNCTION</Row>
		<Row style={{ marginTop: 20 }} className="bg-main">
			<img src={search} alt="search" style={{ height: 38, width: 38 }} />
			<input
				className="font16 segoe"
				type="text"
				autoFocus={!isMobile}
				placeholder={intl.formatMessage({ id: 'picker.indicator.search' })}
				style={{ lineHeight: "38px", width: "100%" }}
				value={inputValue}
				onChange={(event) => setInputValue(event.target.value)}
			/>
		</Row>
		<Column style={{ overflowY: "auto" }}>
			{
				IndicatorsCache.searchIndicators(inputValue).map(group => {
					return <Column key={group.name}>
						<Row crossAxis="center" className="bold font11 bg-button" style={{ paddingLeft: 15, height: 25 }}>{group.name.toUpperCase()}</Row>
						{
							group.indicators.map(indicator => {
								return <Row
									key={indicator.name}
									style={{ height: 46 }}
									crossAxis="center"
									className="pointer hoverable"
									onClick={() => {
										if (!selected) {
											onIndicatorSelected(GetDefaultLineForIndicator(indicator, options))
										}
										else {

											//todo: try to reuse parameters from old indicator?
											//const updatedIndicator: IndicatorLineModel = { ...selected };


											const updatedIndicatorLine = GetDefaultLineForIndicator(indicator, options);
											updatedIndicatorLine.index = selected.index;
											onIndicatorUpdated(updatedIndicatorLine)
										}
									}}>
									{/*<Row className="font16" style={{ width: 80, marginLeft: 15 }}>{indicator.name}</Row>*/}
									<div style={{ minWidth: 10 }} className="flex1 font15 left15 nowrapdots">{indicator.description}</div>
								</Row>
							})
						}
					</Column>
				})
			}
		</Column>
	</Column>
}

export default React.memo(injectIntl(IndicatorSearchPanel));