import * as React from "react";

//themes are based on css-variables. Context is used only to notify charts about changes of the variables

export const ThemeContext = React.createContext<{
	name: string,
	setTheme: (string) => void,
	cssVar: (string) => string //cssVar is a part of the context to force css-var consumers also consume ThemeContext and react to changes
}>({
	name: undefined,
	setTheme: undefined,
	cssVar: undefined
});