import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import { niceNumber, niceSignedNumber } from '/src/utils/numbers';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StrategyService from "/src/services/strategies";
import EventsService, { Activity } from "/src/services/events";
import { Transaction, TransactionStatus } from "/src/services/wallets";
import { Account, AccountStatus } from "/src/services/accounts";
import AuthenticationService from "../services/authentication";
import { Position, PositionStatus } from "/generated/cicada-client";


interface Props {
	intl: any,
	onPositionUpdate: (position: Position) => void
	onInvestedChanged: (invested: number) => void
}

interface State {
}

const accountSubscriptions = [];
const transactionSubscriptions = [];
const positionSubscriptions = [];

const context = {
	addPositionCallback: callback => {
		positionSubscriptions.push(callback)
	},
	removePositionCallback: callback => {
		var index = positionSubscriptions.indexOf(callback);
		if (index > -1) positionSubscriptions.splice(index, 1);
	},
	addTransactionCallback: callback => {
		transactionSubscriptions.push(callback)
	},
	removeTransactionCallback: callback => {
		var index = transactionSubscriptions.indexOf(callback);
		if (index > -1) transactionSubscriptions.splice(index, 1);
	},
	addAccountCallback: callback => {
		accountSubscriptions.push(callback)
	},
	removeAccountCallback: callback => {
		var index = accountSubscriptions.indexOf(callback);
		if (index > -1) accountSubscriptions.splice(index, 1);
	}
};
export const ActivityCallbacksContext = React.createContext<{
	addAccountCallback: (callback: (account: Account) => void) => void,
	removeAccountCallback: (callback: (account: Account) => void) => void,
	addTransactionCallback: (callback: (transaction: Transaction) => void) => void,
	removeTransactionCallback: (callback: (transaction: Transaction) => void) => void,
	addPositionCallback: (callback: (position: Position) => void) => void,
	removePositionCallback: (callback: (position: Position) => void) => void,
}>({
	addAccountCallback: undefined,
	removeAccountCallback: undefined,
	addTransactionCallback: undefined,
	removeTransactionCallback: undefined,
	addPositionCallback: undefined,
	removePositionCallback: undefined
})
export const ActivityCallbacksProvider = ActivityCallbacksContext.Provider


class ActivitiesNotifications extends React.Component<Props, State>  {
	constructor(props) {
		super(props);
	}

	sseActivitiesHandler: EventSource

	stopActivitiesSse = () => {
		if (this.sseActivitiesHandler) {
			this.sseActivitiesHandler.close();
			this.sseActivitiesHandler = undefined;
		}
	}
	async componentDidMount() {
		this.stopActivitiesSse();

		if (AuthenticationService.hasToken()) {
			return;
		}

		this.sseActivitiesHandler = await EventsService.subscribeForActivities(async (s) => {
			const event: Activity = JSON.parse(s);
			if (event.transaction) {

				if (event.transaction.amount >= 0) {
					if (event.transaction.status === TransactionStatus.Completed) {
						toast(this.props.intl.formatMessage({ id: "notification.depositCompleted" }, event.transaction), { theme: "dark" })
					}
					else if (event.transaction.status === TransactionStatus.Rejected) {
						toast(this.props.intl.formatMessage({ id: "notification.depositRejected" }, event.transaction), { theme: "dark" })
					}
				}
				else {
					if (event.transaction.status === TransactionStatus.Completed) {
						toast(this.props.intl.formatMessage({ id: "notification.withdrawalCompleted" }, event.transaction), { theme: "dark" })
					}
					else if (event.transaction.status === TransactionStatus.Rejected) {
						toast(this.props.intl.formatMessage({ id: "notification.withdrawalRejected" }, event.transaction), { theme: "dark" })
					}
				}
				transactionSubscriptions.forEach(callback => { callback(event.transaction) })
			}
			if (event.position) {
				const strategyInfo = await StrategyService.getStrategyInfo(event.position.strategyId);

				if (event.position.status == PositionStatus.Open) {
					const text = this.props.intl.formatMessage({ id: "notification.dealOpened" },
						{ strategyName: strategyInfo.strategy.name, volume: niceNumber(event.position.volume, 3), symbol: event.position.symbol, price: event.position.openPrice });
					toast(text, { theme: "dark" })
				}
				if (event.position.status == PositionStatus.Closed) {
					const text = this.props.intl.formatMessage({ id: "notification.dealClosed" },
						{
							strategyName: strategyInfo.strategy.name, volume: niceNumber(event.position.volume, 3),
							symbol: event.position.symbol, price: event.position.closePrice, profit: niceSignedNumber(event.position.profit, 2), currency: event.position.instrumentCurrency
						});
					toast(text, { theme: "dark" })
				}
				if (this.props.onPositionUpdate) {
					this.props.onPositionUpdate(event.position);
				}
				positionSubscriptions.forEach(callback => { callback(event.position) })
			}

			if (event.account) {
				if (event.account.status == AccountStatus.Active) {
					toast("Congratulation! Your brokerage account is active now.", { theme: "dark" })
				}
				else {
					toast("Your brokerage account has changed the status to " + event.account.status, { theme: "dark" })
				}
				accountSubscriptions.forEach(callback => { callback(event.account) })
			}

			if (event.invested !== undefined) {
				if (this.props.onInvestedChanged) {
					this.props.onInvestedChanged(event.invested)
				}
			}
		})
	}

	componentWillUnmount() {
		this.stopActivitiesSse();
	}

	render() {
		const props = this.props;
		return <Column className="flex1 anti-scroll-jump">
			<ActivityCallbacksProvider value={context}>
				{props.children}
			</ActivityCallbacksProvider>
			<ToastContainer
				limit={4}
				transition={Slide}
				position="bottom-center"
				autoClose={5000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover />
		</Column>
	}
}

export default injectIntl(ActivitiesNotifications);