import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import { withRouter } from 'react-router-dom'
import { SplashScreen } from '@capacitor/splash-screen';
import AuthenticationService from '/src/services/authentication'
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import LandingContainer from "../landing/LandingContainer";
import Api from "/generated/cicada-client";
import { serverUrl } from '/src/services/common'
import EmailInput from "/src/components/EmailInput";
import CodeInput from "/src/components/CodeInput";

const api = new Api(serverUrl, undefined);

interface EntrancePageProps {
	history: any;
	intl: any
}
interface EntrancePageState {
	wrongCode?: boolean;
	isEmailCorrect?: boolean;
	showCodeInput: boolean;
}

class EntrancePage extends React.Component<EntrancePageProps, EntrancePageState>  {
	constructor(props) {
		super(props);
		this.state = { isEmailCorrect: undefined, wrongCode: undefined, showCodeInput: false };
	}

	async componentDidMount() {
		setTimeout(async () => { await SplashScreen.hide(); }, 100);

		if (!AuthenticationService.hasToken()) {
			this.props.history.replace("/dashboard");
		}

		api.analytics.logAction({ actionName: "VisitEntrancePage", deviceId: AuthenticationService.getDeviceId() });
	}

	requestId: string
	codeAttempts: number

	onCode = async (code: string) => {
		this.setState({ wrongCode: false });

		const verificationResult = await AuthenticationService.verifyCode(this.requestId, code);
		if (!verificationResult || !verificationResult.entity || !verificationResult.entity.jwt) {
			this.codeAttempts++;
			if (this.codeAttempts < 3) {
				this.setState({ wrongCode: true });
			}
			else {
				this.requestId = undefined;
				this.codeAttempts = 0;
				setTimeout(() =>
					this.setState({ wrongCode: undefined, isEmailCorrect: undefined, showCodeInput: false })
					, 200);
			}
			return;
		}
		if (verificationResult.entity.jwt.roles && verificationResult.entity.jwt.roles.includes("User")) {
			this.props.history.replace("/dashboard");
		}
		else {
			this.props.history.replace("/initial");
		}
	}

	onEnter = async (email: string) => {
		this.setState({ isEmailCorrect: true });
		try {
			const response = await AuthenticationService.signIn({
				email: email,
				deviceId: AuthenticationService.getDeviceId()
			})
			if (response.deviceToken) {
				if (response.deviceToken.jwt.roles && response.deviceToken.jwt.roles.includes("User")) {
					this.props.history.replace("/dashboard");
				}
				else {
					this.props.history.replace("/initial");
				}
			}
			else if (response.wasSentToEmail) {
				this.requestId = response.requestId;
				this.codeAttempts = 0;
				this.setState({ showCodeInput: true });
			}
		}
		catch (e) {
			//this.setState({ isEmailCorrect: false })
		}
	}

	render() {
		document.title = "Cicada";

		return <LandingContainer>
			<div className="">
				<div style={{ lineHeight: "60px", fontSize: 25, fontWeight: "bold" }}>
					<FormattedMessage id="entrance.register_sign_in" />
				</div>

				<Row className="font16 segoe mtop20">
					<FormattedMessage id="entrance.enter_email" />
				</Row>
				<EmailInput onEmailEnter={this.onEnter} />

				{this.state.showCodeInput && <CodeInput onCodeEnter={this.onCode} />}
			</div>
		</LandingContainer>

	}
}

export default withRouter(injectIntl(EntrancePage));