import * as React from "react";
import { Column, isMobile, Row } from '/src/components/Layout'
import { InstrumentsCache, InstrumentGroup } from '/src/services/instrumentsCache';
import { FormattedMessage, FormattedDate, injectIntl, } from 'react-intl';
import search from '/src/assets/search.svg';
import { InstrumentModel } from "/src/services/instruments";
import { LineModel, SectionModel, InstrumentToken, InstrumentTokenToLineModel, BarField, DataSource, Timeframe, TokenType, IToken } from '../EditorModels'
import close_cross from '/src/assets/close_cross.svg';

interface Props {
	intl: any,
	selected: InstrumentToken,
	options: IToken[],
	onInstrumentAdded: (instrument: InstrumentToken) => void,
	onInstrumentUpdated: (instrument: InstrumentToken) => void
	onClose: () => void
}

const GetDefaultLineForInstrument = (instrument: InstrumentModel, options: IToken[]): InstrumentToken => {
	let nameIndex = 1;
	let newName;
	do {
		newName = instrument.symbol + (nameIndex > 1 ? nameIndex : "");
		nameIndex++;
	}
	while (options.find(o => o.name === newName));

	return {
		name: newName,
		dataIndex: undefined,
		position: undefined,
		exchange: instrument.exchange,
		symbol: instrument.symbol,
		source: instrument.exchange == "Forex" ? DataSource.Bid : DataSource.Price,
		timeframe: Timeframe.h1,
		type: TokenType.Instrument
	};
}

const InstrumentSearchPanel: React.FunctionComponent<Props> = ({ intl, selected, onInstrumentAdded, onInstrumentUpdated, onClose, options }) => {

	const [inputValue, setInputValue] = React.useState("");

	return <Column className="segoe" style={{height: "100%"}}>
		<Row style={{ fontSize: 14 }} className="bold mtop15" mainAxis="center">SELECT INSTRUMENT</Row>
		<Row style={{ marginTop: 20 }} className="">
			<img src={search} alt="search" style={{ height: 38, width: 38 }} />
			<input
				className="font16 segoe"
				type="text"
				autoFocus={!isMobile}
				placeholder={intl.formatMessage({ id: 'picker.datasource.search' })}
				style={{ lineHeight: "38px", width: "100%" }}
				value={inputValue}
				onChange={(event) => setInputValue(event.target.value)}
			/>
		</Row>
		<Column style={{ flexGrow: 0, overflowY: "scroll" }}>
			{
				InstrumentsCache.searchInstruments(inputValue).map(group => {
					return <Column key={group.name}>
						<Row crossAxis="center" className="bold bg-button" style={{ paddingLeft: 30, height: 25 }}>{group.name}</Row>
						{
							group.instruments.map(instrument => {
								return <Row
									key={instrument.symbol}
									style={{ height: 36 }}
									crossAxis="center"
									className="pointer hoverable"
									onClick={() => {
										if (!selected) {
											onInstrumentAdded(GetDefaultLineForInstrument(instrument, options))
										}
										else {
											const updatedInstrument: InstrumentToken = { ...selected };
											updatedInstrument.exchange = instrument.exchange;
											updatedInstrument.symbol = instrument.symbol;

											if (instrument.exchange === "Forex" && selected.source === DataSource.Price) {
												updatedInstrument.source = DataSource.Bid;
											}
											if (instrument.exchange === "IEX" && selected.source !== DataSource.Price) {
												updatedInstrument.source = DataSource.Price;
											}

											onInstrumentUpdated(updatedInstrument)
										}
									}}>
									<Row className="font16" style={{ width: 80, marginLeft: 15 }}>{instrument.symbol}</Row>
									<div style={{ minWidth: 10 }} className="flex1 font13 left15 nowrapdots">{instrument.description}</div>
								</Row>
							})
						}
					</Column>
				})
			}
		</Column>
	</Column>
}




export default React.memo(injectIntl(InstrumentSearchPanel));