import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { ChartMode, EditingCallbacks, IToken, IndicatorToken, InstrumentToken, MovingCallbacks, Parameter, TokenType, TradeRuleToken } from "../EditorModels";
import { IndicatorsCache } from "/src/services/indicatorsCache";
import { HiArrowDown, HiArrowUp, HiMiniEllipsisVertical, HiTrash } from "react-icons/hi2";
import ParameterInput from "../Panel/ParameterInput";
import NumberInput from "../Panel/NumberInput";
import FunctionEditingLine from "./FunctionEditingLine";
import InstrumentEditingLine from "./InstrumentEditingLine";
import ChartTypeMenu from "./ChartTypeMenu";
import TokenMenu from "./TokenMenu";
import AutoResizingInput from "./AutoResizingInput";
import FunctionCodeLine from "./FunctionCodeLine";
import InstrumentCodeLine from "./InstrumentCodeLine";
import TradeRuleEditingLine from "./TradeRuleEditingLine";
import TradeRuleCodeLine from "./TradeRuleCodeLine";
import BooleanCodeLine from "./BooleanCodeLine";
import BooleanEditingLine from "./BooleanEditingLine";

interface Props {
	isSelected: boolean,
	token: IToken,
	onClick: () => void,
	editable: boolean,
	value: number,
	chartMode: ChartMode,
	options: IToken[],
	instrumentOptions: InstrumentToken[],
	editingCallbacks: EditingCallbacks,
	movingCallbacks: MovingCallbacks,
	isBoolean?: boolean
}

class CodeLine extends React.Component<Props> {
	ref: any;
	constructor(props) {
		super(props);
		this.ref = React.createRef();
	}
	getSnapshotBeforeUpdate() {
		if (this.ref.current) {
			// first
			return this.ref.current.getBoundingClientRect();
		}
		return null;
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.ref.current && (prevProps.isSelected !== this.props.isSelected)) {
			const now = this.ref.current.getBoundingClientRect();
			const deltaX = now.width - snapshot.width;

			this.ref.current.animate([
				{ width: `${snapshot.width}px` },
				// play
				{ width: `${now.width}px` },
			], {
				duration: 150,
				easing: 'ease-out',
			});
		}
	}


	render() {
		const props = this.props;
		const editableClasses = props.isSelected ? "bg-button" : " pointer";

		const borderClass = props.chartMode != ChartMode.Hidden ? " border" : " border-dashed"
		const booleanClass = props.isBoolean ? "" : " mtop2";

		return <div
			ref={this.ref}
			className={"row " + editableClasses + borderClass + booleanClass} style={{
				position: "relative",
				boxSizing: "border-box",
				alignItems: "center",
				borderRadius: props.isBoolean ?
					props.isSelected ? "13px 13px 13px 0px" : "13px"
					:
					props.isSelected ? "17px 17px 17px 0px" : "17px",
				transition: "border-radius 75ms",
				marginRight: 5, height: props.isBoolean ? 27 : 35, paddingLeft: 20, paddingRight: 6
			}} onMouseDown={(e) => { /*do not do unselecting when other line is selected*/ e.stopPropagation(); }}
			onClick={e => props.onClick()}>
			<Row crossAxis="center" style={{ flexShrink: 0 }}>

				{props.token.type === TokenType.Instrument &&
					<InstrumentCodeLine value={props.value}
						movingCallbacks={props.movingCallbacks}
						editingCallbacks={props.editingCallbacks}
						instrument={props.token as InstrumentToken}
						isSelected={props.isSelected} />}

				{props.token.type === TokenType.Indicator && !props.isBoolean &&
					<FunctionCodeLine value={props.value}
						movingCallbacks={props.movingCallbacks}
						editingCallbacks={props.editingCallbacks}
						indicator={props.token as IndicatorToken}
						isSelected={props.isSelected} />}

				{props.token.type === TokenType.Indicator && props.isBoolean &&
					<BooleanCodeLine value={props.value}
						movingCallbacks={props.movingCallbacks}
						editingCallbacks={props.editingCallbacks}
						condition={props.token as IndicatorToken}
						isSelected={props.isSelected} />}


				{props.token.type === TokenType.TradeRule &&
					<TradeRuleCodeLine
						instrumentOptions={props.instrumentOptions}
						movingCallbacks={props.movingCallbacks}
						editingCallbacks={props.editingCallbacks}
						tradeRule={props.token as TradeRuleToken}
						isSelected={props.isSelected} />}

			</Row>
			{props.isSelected && (props.token.type === TokenType.Instrument) &&
				<Row style={{ position: "absolute", left: -1, top: 36, minWidth: 300, zIndex: 7, padding: 7, borderRadius: "0px 17px 17px 17px" }} className="bg-button border">
					<InstrumentEditingLine movingCallbacks={props.movingCallbacks} editingCallbacks={props.editingCallbacks} instrument={props.token as InstrumentToken} />
				</Row>}
			{props.isSelected && (props.token.type === TokenType.Indicator) && !props.isBoolean &&
				<Row style={{ position: "absolute", left: -1, top: 36, minWidth: 300, zIndex: 7, padding: 7, borderRadius: "0px 17px 17px 17px" }} className="bg-button border">
					<FunctionEditingLine movingCallbacks={props.movingCallbacks} editingCallbacks={props.editingCallbacks} indicator={props.token as IndicatorToken} options={props.options} />
				</Row>}
			{props.isSelected && (props.token.type === TokenType.Indicator) && props.isBoolean &&
				<Row style={{ position: "absolute", left: -1, top: 28, minWidth: 300, zIndex: 7, padding: 7, borderRadius: "0px 17px 17px 17px" }} className="bg-button border">
					<BooleanEditingLine movingCallbacks={props.movingCallbacks} editingCallbacks={props.editingCallbacks} indicator={props.token as IndicatorToken} options={props.options} />
				</Row>}
			{props.isSelected && (props.token.type === TokenType.TradeRule) &&
				<Row style={{ position: "absolute", left: -1, top: 36, minWidth: 500, zIndex: 7, padding: 7, borderRadius: "0px 17px 17px 17px" }} className="bg-button border">
					<TradeRuleEditingLine movingCallbacks={props.movingCallbacks} editingCallbacks={props.editingCallbacks} tradeRule={props.token as TradeRuleToken} allOptions={props.options} instrumentOptions={props.instrumentOptions} />
				</Row>}
		</div>
	}
}

export default CodeLine;