import * as React from "react";
import { Column, isDesktop, Row } from '/src/components/Layout'
import DemoResultChart from './DemoResultChart'
import { niceNumber, niceSignedNumber, signedPercent } from '/src/utils/numbers'
import { EquityHistory, TimeValue, StrategyState, StrategyStatement } from '/src/services/strategies'
import TradeBucketsChart from "./TradeBucketsChart";
import { Position, DealStats, RelativeProfitBucket } from "/src/services/trading";
import { FormattedMessage } from "react-intl";
import SvgMail from "/src/components/svg/SvgMail";
import SvgMailUnsubscribe from "/src/components/svg/SvgMailUnsubscribe";
import SubscribeButton from "./SubscribeButton";

const countDays = (values: TimeValue[]) => {
	if (values.length === 0) {
		return 0;
	}
	const timespan = new Date(values[values.length - 1].time).valueOf() - new Date(values[0].time).valueOf();
	const days = Math.round(timespan / (1000 * 60 * 60 * 24));
	return days;
}

interface Props {
	demoStatement?: StrategyStatement,
	deals: Position[],
	dealsStats: DealStats,
	isSubscribed: boolean,
	onInvesting: () => void,
	onSubscribe: () => Promise<void>,
	onUnsubscribe: () => Promise<void>,
}

const DemoEquityTile: React.FC<{ demoStatement: StrategyStatement, maxdd: number, className?: string }> = ({ demoStatement, maxdd, className }) =>
	<Column className={className}>
		<div>
			<DemoResultChart data={demoStatement.equityHistory.values} height={150} />
		</div>
		<Row className="mtop20">
			<Column className="flex1" mainAxis="center" crossAxis="center">
				<div className="semibold font30">{signedPercent(demoStatement.equityHistory.relativeChange, 1)}</div>
				<div className="font16 textCenter">
					<FormattedMessage id="strategy.in_days" values={{ count: countDays(demoStatement.equityHistory.values) }} />							</div>
			</Column>
			<Column className="flex1" mainAxis="center" crossAxis="center">
				<div className="semibold font30">{signedPercent(maxdd, 1)}</div>
				<div className="font16 textCenter left10">
					<FormattedMessage id="strategy.max_drawdown" />
				</div>
			</Column>
		</Row>
	</Column>


const DemoBucketsTile: React.FC<{ buckets: RelativeProfitBucket[], className?: string }> = ({ buckets, className }) =>
	<Column className={className}>
		<div>
			<TradeBucketsChart buckets={buckets} height={150} />
		</div>
		<Row className="mtop20">
			<Column className="flex1" mainAxis="center" crossAxis="center">
				<div className="semibold font30">{buckets.filter(b => b.bucket < 0).reduce((a, b) => a + b.count, 0)}</div>
				<div className="font16 textCenter">
					<FormattedMessage id="strategy.loss_trades" />
				</div>
			</Column>
			<Column className="flex1" mainAxis="center" crossAxis="center">
				<div className="semibold font30">{buckets.filter(b => b.bucket >= 0).reduce((a, b) => a + b.count, 0)}</div>
				<div className="font16 textCenter">
					<FormattedMessage id="strategy.profit_trades" />
				</div>
			</Column>
		</Row>
	</Column>

const DemoStats: React.FunctionComponent<Props> = (props) => {

	const { max, maxdd } = props.demoStatement ? props.demoStatement.equityHistory.values.reduce<{ max: number, maxdd: number }>((sum, value) => {
		if (value.value > sum.max) {
			sum.max = value.value;
		}
		const dd = value.value / sum.max;
		if (dd < sum.maxdd) {
			sum.maxdd = dd;
		}
		return sum;
	}, { max: 0, maxdd: 1 }) : { max: 0, maxdd: 0 }

	const relativeProfitBuckets = (props.dealsStats && props.dealsStats.relativeProfitBuckets) ? props.dealsStats.relativeProfitBuckets : [];

	return <Column>
		<Row mainAxis="center">
			<FormattedMessage id="strategy.demo_stats" />
		</Row>
		{isDesktop ?
			<Row mainAxis="center">
				<DemoEquityTile demoStatement={props.demoStatement} maxdd={maxdd} className="flex2 mleft20 mright20 mtop20 mbottom20" />
				<DemoBucketsTile buckets={relativeProfitBuckets} className="flex1 mleft20 mright20 mtop20 mbottom20" />
			</Row>
			:
			<Column>
				<DemoEquityTile demoStatement={props.demoStatement} maxdd={maxdd} />
				<DemoBucketsTile buckets={relativeProfitBuckets} className="mtop40" />
			</Column>}
		{props.isSubscribed !== undefined &&
			<SubscribeButton onClick={props.isSubscribed ? props.onUnsubscribe : props.onSubscribe} isSubscribed={props.isSubscribed} />}
	</Column>
}

export default DemoStats;