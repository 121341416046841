import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import Positions from './Positions'
import candlesticks from '/src/assets/candlesticks.svg';
import indicator from '/src/assets/indicator.svg';
import dollar from '/src/assets/dollar.svg';
import TradingService, { AllocationStats, Position, PositionStatus } from "/src/services/trading"
import { IndicatorsCache } from '/src/services/indicatorsCache';
import { FormattedMessage, FormattedDate, } from 'react-intl';
import search from '/src/assets/search.svg';
import { LineModel, Parameter, IndicatorToken, DataSource, BarField, Timeframe, IndicatorTokenToLineModel, ChartMode, IToken, MovingCallbacks } from '../EditorModels'
import edit from '/src/assets/edit.svg';
import delete_ from '/src/assets/delete.svg';
import arrow_up from '/src/assets/arrow_up.svg';
import arrow_down from '/src/assets/arrow_down.svg';
import SwitchParameterInput from './SwitchParameterInput'
import ParameterInput from './ParameterInput'
import ResultEquityChartSized from "/src/components/ResultEquityChart";
import NumberInput from "./NumberInput";
import DotsMenu from "../DotsMenu";

interface Props {
	indicator: IndicatorToken,
	onUpdated: (indicator: IndicatorToken) => void,
	onEdit: (indicator: IndicatorToken) => void,
	movingCallbacks: MovingCallbacks,
	options: IToken[]
}

const IndicatorPanel: React.FunctionComponent<Props> = ({ indicator, options, onUpdated, onEdit, movingCallbacks }) => {

	const [customName, setCustomName] = React.useState<string>(indicator.name);

	React.useEffect(() => {
		setCustomName(indicator.name)
	}, [indicator.name])

	const onRenamingFinished = () => {
		const updatedIndicator: IndicatorToken = { ...indicator };
		updatedIndicator.name = customName;
		onUpdated(updatedIndicator);
	}

	const onChartModeChanged = (mode: ChartMode) => {
		const updatedInstrument: IndicatorToken = { ...indicator };
		updatedInstrument.chart = mode;
		onUpdated(updatedInstrument);
	}

	const indicatorData = IndicatorsCache.getIndicator(indicator.functionName);

	return <Column className="segoe">
		<Row crossAxis="flex-end" className="bg-button" style={{padding: 20}}>
			<Row className="flex1 mleft10 mright10" style={{ position: "relative" }}>
				<input
					className="font20 semibold"
					type="text"
					placeholder=""
					style={{ borderBottom: "1px solid #CBD7E7", height: 35, width: "100%" }}
					value={customName}
					onChange={(event) => setCustomName(event.target.value)}
					onKeyDown={e => { if (e.key === 'Enter') { onRenamingFinished() } }}
					onBlur={e => onRenamingFinished()}
				/>
			</Row>
			<DotsMenu isWhite={false} items={[
				{ text: "Move up", action: () => movingCallbacks.onMovedUp(indicator), isEnabled: movingCallbacks.canBeMovedUp },
				{ text: "Move down", action: () => movingCallbacks.onMovedDown(indicator), isEnabled: movingCallbacks.canBeMovedDown },
				{ text: "Delete", action: () => movingCallbacks.onDeleted(indicator), isEnabled: movingCallbacks.canBeDeleted },
			]} />
		</Row>

		<Column style={{ padding: 20 }}>
			<Row className="mtop20 font12 mtop50 mbottom10">
				<div className="flex1"></div>
				<Row mainAxis="center" crossAxis="center" className="segoe bold font11 mright10">{indicatorData.description[0].toUpperCase()}</Row>
				<Row mainAxis="center" crossAxis="center" className="pointer hoverable" style={{ width: 25, height: 25, padding: 4 }}
					onClick={() => onEdit(indicator)}>
					<img style={{ width: 28 }} src={edit} alt="edit" />
				</Row>
			</Row>
			<Row className="bg-main" style={{ flexWrap: "wrap", border: "1px solid #50586550", padding: "10px 10px 0px 10px", borderRadius: 15 }} mainAxis="flex-end">
				{indicator.parameters.map((p, i) =>
					<Row className="mbottom10 mleft10">
						{indicatorData.parameters[i].prefix && <Row mainAxis="flex-end" crossAxis="center"
							className={"semibold flex1 mright10 " + (indicatorData.parameters[i].prefix && indicatorData.parameters[i].prefix.length > 3 ? "font14" : "font24")}>
							{indicatorData.parameters[i].prefix}
						</Row>}

						{indicatorData.parameters[i].type !== "Integer" ?
							<ParameterInput
								type={indicatorData.parameters[i].type}
								options={options.map(o => o.name)}
								value={p}
								onValueChange={(value: Parameter) => {
									const updatedInstrument: IndicatorToken = { ...indicator };
									updatedInstrument.parameters[i] = value;
									onUpdated(updatedInstrument);
								}} />
							:
							<NumberInput
								value={p}
								onValueChange={(value: Parameter) => {
									const updatedInstrument: IndicatorToken = { ...indicator };
									updatedInstrument.parameters[i] = value;
									onUpdated(updatedInstrument);
								}} />}
					</Row>)
				}
			</Row>

			<Row className="mtop50 font12">CHART</Row>
			<Row className="font16 mtop5" style={{ height: 45 }}>
				<Row mainAxis="center" crossAxis="center" className={indicator.chart === ChartMode.Own ? "flex1 bold" : "flex1 pointer "}
					onClick={() => onChartModeChanged(ChartMode.Own)}>NEW</Row>
				<Row mainAxis="center" crossAxis="center" className={indicator.chart === ChartMode.Shared ? "flex1 bold" : "flex1 pointer "} style={{ borderLeft: "1px solid #B4BAC3" }}
					onClick={() => onChartModeChanged(ChartMode.Shared)}>REUSE</Row>
				<Row mainAxis="center" crossAxis="center" className={indicator.chart === ChartMode.Hidden ? "flex1 bold" : "flex1 pointer "} style={{ borderLeft: "1px solid #B4BAC3" }}
					onClick={() => onChartModeChanged(ChartMode.Hidden)}>NONE</Row>
			</Row>
		</Column>
	</Column>
}




export default IndicatorPanel;