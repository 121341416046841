import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'

interface Props {
	onEmailEnter: (email: string) => void;
	onChange?: () => void;
	attempt?: number;
}
interface State {
	email?: string,
	isEmailCorrect?: boolean;
}

class EmailInput extends React.Component<Props, State>  {
	constructor(props) {
		super(props);
		this.state = { isEmailCorrect: undefined };
		this.emailInput = React.createRef();
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.attempt !== this.props.attempt) {
			this.setState({isEmailCorrect: false});
		}
	}

	emailInput: any

	validateEmail = (email) => {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	onEnter = async () => {
		if (this.validateEmail(this.state.email)) {
			this.emailInput.current.blur();
			this.setState({ isEmailCorrect: true });
			this.props.onEmailEnter(this.state.email);
		}
		else {
			this.setState({ isEmailCorrect: false });
		}
	}

	render() {
		return <Row className="mtop10 border-bottom" crossAxis="center" style={{ maxWidth: 380, height: 41 }}>
			<input
				ref={this.emailInput}
				style={{
					outline: "none", border: "none",
					fontFamily: 'Open Sans', fontSize: 25,
					textAlign: "left",
				}}
				className={" " + (this.state.isEmailCorrect === false ? "" : "")}
				type="text" required
				onKeyDown={e => { if (e.key == "Enter") { this.onEnter(); } }}
				onChange={e => {
					this.setState({ isEmailCorrect: undefined, email: e.target.value })
					this.props.onChange && this.props.onChange();
				}} />
			<Row style={{ height: 41, width: 41 }} className="pointer" mainAxis="center" crossAxis="center" onClick={this.onEnter}>

				<div className="pointer" style={{
					width: 26, height: 26, pointerEvents: "auto",
					animation:
						this.state.isEmailCorrect === true ? "fly-right 2s cubic-bezier(0.280, 0.840, 0.420, 1) forwards"
							: this.state.isEmailCorrect === false ? "shake 0.82s cubic-bezier(.36,.07,.19,.97) both" : undefined,
					transformOrigin: "50% 50%"
				}}>
					<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="var(--color-main)"><path d="M0 0h24v24H0z" fill="none" /><path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" /></svg>
				</div>
			</Row>
		</Row>

	}
}

export default EmailInput;