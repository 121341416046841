import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import DatePicker from 'react-date-picker/dist/entry.nostyle'
import { ValidatedField } from "../../pages/profile/LegalSections/GeneralLegalSection";

interface Props {
	value: ValidatedField<Date>,
	onValueChange: (value: Date) => void,
	label: string,
	disabled?: boolean,
	className: string
}

const DateInput: React.FunctionComponent<Props> = ({ label, value, onValueChange, className, disabled }) => {
	const [state, setState] = React.useState({ forceActiveDate: false });

	React.useEffect(() => { setState({ forceActiveDate: true }) }, [value]);

	/* DatePicker consists of 2 components - DateInput and Calendar. Calendar has 'active date' (what is seen) and 'value' (what is selected)
	   We want to change active date when a user types into the DateInput but also the Calendar must be able to change it
	   by the navigation buttons. */
	const resetForcingActiveDate = () => {
		setState(prevValues => {
			return { forceActiveDate: false }
		})
	};

	let initialValue = undefined;
	if (value && value.value) {
		const d = new Date(value.value);
		initialValue = new Date(d.getTime() + d.getTimezoneOffset()*60*1000); //DatePicker works with the current timezone only, convert input
	}

	return <Column className={"flex1 " + className} >
		<div className="font14">{label}</div>
		<DatePicker
			format="yyyy-MM-dd"
			onChange={v => {  // and convert output
				var vUtc = new Date(Date.UTC(v.getFullYear(), v.getMonth(), v.getDate(),
					v.getHours(), v.getMinutes(), v.getSeconds()));
				onValueChange(vUtc)
			}}
			value={initialValue}
			onActiveStartDateChange={resetForcingActiveDate}
			onViewChange={resetForcingActiveDate}
			calendarIcon={null}
			clearIcon={null}
			activeStartDate={state.forceActiveDate ? initialValue : undefined}
			minDate={new Date(1900, 12, 31)}
			maxDate={new Date(2050, 12, 31)}
			monthPlaceholder="mm"
			yearPlaceholder="yyyy"
			dayPlaceholder="dd"
			disabled={disabled}
		/>
		{value && value.error && !value.hideError && <Row mainAxis="flex-end" className="font11 italic semibold error-blink">{value.error}</Row>}
	</Column>
}


export default DateInput;