import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import { withRouter } from 'react-router-dom'
import PageContainer from '../components/PageContainer'
import { FormattedMessage, FormattedDate } from 'react-intl';
import { Link } from "react-router-dom";
import ActivitiesNotifications, { ActivityCallbacksContext } from '../components/ActivitiesNotifications'
import TransactionList from "/src/components/TransactionsPage/TransactionList";

interface Props {
	history: any
}

const TransactionsPage: React.FunctionComponent<Props> = (props) => {
	document.title = "Wallet " + String.fromCharCode(183) + " Cicada";

	return <PageContainer maxWidth={800} history={props.history} tab={1}>
		<Row mainAxis="center">
			<Column mainAxis="center" style={{ padding: "0px 4px", minHeight: "100vh", maxWidth: 800, flex: 1, position: "relative", minWidth: 350 }}>

				<Row mainAxis="center" className="font16">
					Wallets are not implemented yet.
				</Row>

			</Column>
		</Row >
	</PageContainer>
}


export default withRouter(TransactionsPage);