import * as React from "react";
import { Switch } from "/node_modules/@headlessui/react/dist/index";
import { ThemeContext } from "./ThemeProvider";
import Api, { ErrorCode } from "/generated/cicada-client";
import { serverUrl } from '/src/services/common'
import AuthenticationService from "../services/authentication";
const api = new Api(serverUrl, undefined, async () => { return await AuthenticationService.getAuthHeaderValue(); });

const ThemeSwitch: React.FunctionComponent<{}> = props => {
	const [colorTheme, setColorTheme] = React.useState<String>(document.documentElement.getAttribute('color-theme'));
	let newTheme = (colorTheme === 'light') ? 'dark' : 'light';

	return <ThemeContext.Consumer>
		{(theme) => (<div onClick={e => {
			localStorage.setItem('theme', newTheme);
			document.documentElement.setAttribute('color-theme', newTheme);
			setColorTheme(newTheme);
			theme.setTheme(newTheme);
			api.analytics.logAction({ actionName: "ChangeTheme", actionSubject: newTheme, deviceId: AuthenticationService.getDeviceId() });
		}} className="pointer mbottom15" style={{ padding: "5px 20px", borderRadius: 8 }}>
			{colorTheme}
		</div>
		)}
	</ThemeContext.Consumer>
}


export default ThemeSwitch;