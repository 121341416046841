import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import PageContainer from "/src/components/PageContainer";

import Api, { ErrorCode } from "/generated/cicada-client";
import { serverUrl } from '/src/services/common'
import Loader from "/src/components/Loader";
const api = new Api(serverUrl, undefined);

interface Props extends RouteComponentProps {
}

const FastUnsubscribePage: React.FunctionComponent<Props> = (props) => {
	document.title = "Wallet " + String.fromCharCode(183) + " Cicada";

	const [result, setResult] = React.useState<ErrorCode>(undefined);

	React.useEffect(() => {
		async function load() {
			const params = new URLSearchParams(props.location.search);
			const strategyId = params.get("strategy");
			const subscriptionId = params.get("subscription");
			try {
				const result = await api.signalSubscriptions.deleteSubscriptionById({ strategyId: strategyId, subsriptionId: subscriptionId })
				setResult(result.errorCode)
			}
			catch (e) {
				setResult(ErrorCode.GeneralError);
			}
		}
		load();
	}, []);

	return <Row mainAxis="center" className="font16">
		<Column mainAxis="center" style={{ height: "50vh" }}>
			{result === undefined ? <Loader/> :
				result === ErrorCode.Ok ? "You have successfully unsubscribed from the strategy." :
					result === ErrorCode.NotFound ? "You were already unsubscribed from the strategy." :
						"An error occurred"}
		</Column>
	</Row >
}


export default withRouter(FastUnsubscribePage);