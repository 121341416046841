import { Account, AccountField, AccountFieldGroup, AccountFieldType, AccountLegalData, UsaVisaType } from "/src/services/accounts";

export const sectionHasErrors = (section: any): boolean => {
	for (const f in section) {
		if (section[f].error !== undefined && section[f].error !== false) {
			return true;
		}
	}
	return false;
}

export interface ValidatedField<T> {
	value: T,
	error: string,
	hideError: boolean
}

export const initField = () => {
	return {
		value: undefined,
		error: undefined,
		hideError: true
	}
}

export const setField = (value) => {
	return {
		value,
		error: undefined,
		hideError: false
	}
}

export const extractFormValues = (data: any): any => {
	var result = {};
	for (var a in data) {
		if (data[a].value instanceof Date) {
			result[a] = data[a].value;
		}
		else {
			result[a] = data[a].value;
		}
	}
	return result;
}

export const setFormValues = (legal: any): any => {
	var result = {};
	for (var a in legal) {
		result[a] = setField(legal[a])
	}
	return result;
}

export const validateDate = (validator: string, value: Date) => {


	var d = new Date();
	var period = Number.parseFloat(validator.substring(2));
	if (validator[1] == 'd') {
		d.setDate(d.getDate() + period);
	}
	else if (validator[1] == 'y') {
		d.setFullYear(d.getFullYear() + period);
	}
	else {
		console.error("wrong date validator syntax (period): " + validator)
		return true;
	}

	if (validator[0] == '>') {
		return value > d;
	}
	else if (validator[0] == '<') {
		return value < d;
	}
	else {
		console.error("wrong date validator syntax (operation): " + validator)
		return true;
	}
}

export const isFieldHidden = (field: AccountField, values) => field.conditions.map(c =>
	(c.allowedValues && (!values[c.fieldName] || (values[c.fieldName].value === undefined) || !c.allowedValues.includes(values[c.fieldName].value + '')))
	|| (c.disallowedValues && (values[c.fieldName] && (values[c.fieldName].value !== undefined) && c.disallowedValues.includes(values[c.fieldName].value + '')))
).some(x => x === true)

export const validateField = (type: AccountFieldType, validator: string, errorText: string, value: any, allValues: any) => {
	if (type === AccountFieldType.String && validator !== undefined && validator.length > 0) {
		return (!value || !RegExp(validator).test(value)) && errorText;
	}
	if (type === AccountFieldType.Date && validator !== undefined && validator.length > 0) {
		return (!value || !validateDate(validator, new Date(value))) && errorText;
	}
	if (type === AccountFieldType.Country && validator !== undefined) {
		return (!value) && errorText;
	}
	if (type === AccountFieldType.File && validator !== undefined) {
		return (!value) && errorText;
	}
	if (type === AccountFieldType.Phone && validator !== undefined) {
		return (!value) && errorText;
	}
	if (type === AccountFieldType.UsaState && validator !== undefined) {
		return (!value) && errorText;
	}
	if (type === AccountFieldType.Select && validator !== undefined) {
		return (!value) && errorText;
	}
	if (type === AccountFieldType.MultiSelect && validator !== undefined) {
		return ((!value) || value.length == 0) && errorText;
	}
	if ((type === AccountFieldType.Boolean || type === AccountFieldType.Agreement) && validator !== undefined) {
		if (validator.indexOf("|") > 0) {
			const ors = validator.split("|").map(o => o[0].toLocaleLowerCase() + o.substring(1));
			const orValues = ors.map(o => allValues[o] && allValues[o].value);
			return !orValues.some(o => o === true) && errorText;
		}
		if (validator === "true") {
			return (!value) && errorText;
		}
		return undefined;
	}
	return undefined;
}

export const validateGroup = (groupedFields: AccountFieldGroup, newValues: { [key: string]: ValidatedField<any> }) => {
	if (!groupedFields) {
		return newValues;
	}
	let groupDisabledByFlag = false;
	groupedFields.fields.forEach(f => {
		if (f.disablesEntireSection && newValues[f.name] && newValues[f.name].value) {
			groupDisabledByFlag = true;
		}
	})

	groupedFields.fields.forEach(f => {
		if (newValues[f.name] === undefined) {
			newValues[f.name] = initField();
		}
		newValues[f.name].error = !groupDisabledByFlag && !isFieldHidden(f, newValues) && validateField(f.type, f.validator, f.errorText, newValues[f.name].value, newValues)
	})
	return newValues;
}

export const validateGroups = (groupedFields: AccountFieldGroup[], newValues: { [key: string]: ValidatedField<any> }) => {
	if (!groupedFields) {
		return newValues;
	}
	groupedFields.forEach(g => {
		validateGroup(g, newValues);
	})
	return newValues;
}