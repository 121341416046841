import rp from 'request-promise-native';
import AuthenticationService from './authentication';
import { serverUrl } from '/src/services/common'
import { Result } from '/src/utils/result';
export interface InvoiceModel {
	id: string
}

export enum TransactionStatus {
	Pending = 'Pending',
	Completed = 'Completed',
	Rejected = 'Rejected'
}

export interface Transaction {
	id: string;
	externalId: string;
	time: string;
	account: string;
	strategyId: string;
	address: string;
	amount: number;
	freeFundsAfter: number;
	provider: string;
	status: TransactionStatus;
	currency: string;
}

export interface DepositRequest {
	achId: string;
	amount: number;
}

const WalletsService = {
	getMyBalance: async () => {
		const response = await rp({
			method: 'GET',
			json: true,
			uri: serverUrl + '/wallets/balance',
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},
	requestWithdrawal: async (achId: string, amount: number): Promise<Result<Transaction>> => {
		const response = await rp({
			method: 'POST',
			json: true,
			uri: serverUrl + '/wallets/requestWithdrawal',
			body: { achId, amount },
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},
	createDepositInvoice: async (achId: string, amount: number): Promise<Result<Transaction>> => {
		const response = await rp({
			method: 'POST',
			json: true,
			uri: serverUrl + '/wallets/createDepositInvoice',
			body: { achId, amount },
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},
	transfer: async (amount) => {
		const response = await rp({
			method: 'POST',
			json: true,
			uri: serverUrl + '/wallets/transfer',
			body: { amount },
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},
	findTransactions: async (toTimestamp, count, strategyId): Promise<Transaction[]> => {
		const response = await rp({
			method: 'GET',
			json: true,
			uri: serverUrl + '/wallets/transactions?toTimestamp=' + toTimestamp + '&count=' + count + (strategyId ? ('&strategy=' + strategyId) : ""),
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	}
}

export default WalletsService;