import rp from 'request-promise-native';
import AuthenticationService from '/src/services/authentication';
import { serverUrl } from '/src/services/common'
import { Result } from '/src/utils/result';

export interface AgreementSignature {
	agreementName: string;
	signedAt: string;
	ipAddress: string;
}

export enum UsaVisaType {
	B1 = 'B1',
	B2 = 'B2',
	DACA = 'DACA',
	E1 = 'E1',
	E2 = 'E2',
	E3 = 'E3',
	F1 = 'F1',
	G4 = 'G4',
	H1B = 'H1B',
	J1 = 'J1',
	L1 = 'L1',
	OTHER = 'OTHER',
	O1 = 'O1',
	TN1 = 'TN1'
}

export enum EmploymentStatus {
	Unemployed = 'Unemployed',
	Employed = 'Employed',
	Student = 'Student',
	Retired = 'Retired'
}

export enum FundingSource {
	EmploymentIncome = 'EmploymentIncome',
	Investments = 'Investments',
	Inheritance = 'Inheritance',
	BusinessIncome = 'BusinessIncome',
	Savings = 'Savings',
	Family = 'Family'
}

export enum AccountFieldType {
	String = 'String',
	Date = 'Date',
	Select = 'Select',
	MultiSelect = 'MultiSelect',
	Phone = 'Phone',
	Country = 'Country',
	Boolean = 'Boolean',
	UsaState = 'UsaState',
	Agreement = 'Agreement',
	File = 'File'
}

export enum UsaResidency {
	Resident = 'Resident',
	Visa= 'Visa'
}

export interface AccountFieldOption {
	label: string;
	value: string;
}

export interface FieldCondition {
	fieldName: string;
	allowedValues: string[];
	disallowedValues: string[];
}

export interface AccountField {
	name: string;
	label: string;
	validator: string;
	errorText: string;
	options: AccountFieldOption[];
	type: AccountFieldType;
	canBeUpdated: boolean;
	showSeparately: boolean;
	conditions: FieldCondition[];
	disablesEntireSection: boolean;
	fieldDescription: string;
	title: string;
	titleDescription: string;
}

export interface AccountFieldGroup {
	label: string;
	fields: AccountField[];
}

export enum AssetsRange {
	UpTo20K = 'UpTo20K',
	UpTo50K = 'UpTo50K',
	UpTo100K = 'UpTo100K',
	UpTo500K = 'UpTo500K',
	UpTo1M = 'UpTo1M',
	UpTo10M = 'UpTo10M'
}

export interface AccountLegalData {
	firstName: string;
	middleName: string;
	lastName: string;
	phone: string;
	dateOfBirth: number;
	streetAddress: string;
	apt: string;
	state: string;
	postalCode: string;
	city: string;
	countryOfResidence: string;
	countryOfBirth: string;
	countryOfCitizenship: string;
	countryTaxResidence: string;
	usaResidency: UsaResidency;
	usaVisaType: UsaVisaType;
	usaVisaExpirationDate: number;
	usaVisaDepartureDate: number;
	employmentStatus: EmploymentStatus;
	liquidAssetsRange: AssetsRange;
	taxId: string;
	fundingSources: FundingSource[];
	employerName: string;
	employerAddress: string;
	employmentPosition: string;
	employerSkipped: boolean;
	isAffiliatedWithBroker: boolean;
	isControlPerson: boolean;
	isPoliticallyExposed: boolean;
	immediateFamilyExposed: boolean;
	nonOfDisclosuresApply: boolean;
	trustedContactSkipped: boolean;
	trustedContactGivenName: string;
	trustedContactFamilyName: string;
	trustedContactPhone: string;
	trustedContactEmail: string;
}

export enum AccountStatus {
	Pending = 'Pending',
	ActionRequired = 'ActionRequired',
	Active = 'Active',
	Disabled = 'Disabled',
	Closed = 'Closed'
}

export interface Account {
	userId: string;
	createdAt: string;
	accountId: string;
	accountNumber: string;
	provider: string;
	legalData: AccountLegalData;
	status: AccountStatus;
}

export interface CreateAccountRequest {
	legalData: AccountLegalData;
}

export interface UpdateAccountRequest {
	accountId: string;
	legalData: AccountLegalData;
}

export interface AccountResponse {
	createdAt: string;
	accountId: string;
	accountNumber: number;
}

export enum BankAccountType {
	Checking = 'Checking',
	Savings = 'Savings'
}

export interface AchRelationship {
	id: string;
	accountId: string;
	accountOwnerName: string;
	bankAccountType: BankAccountType;
	bankAccountNumber: string;
	bankRoutingNumber: string;
	nickname: string;
}

export enum BankIdentifierType {
	ABA = 'ABA',
	BIC = 'BIC'
}

export interface BankData {
	bankName: string;
	identifierType: BankIdentifierType;
	bankCode: string;
	accountNumber: string;
	country: string;
	state: string;
	postalCode: string;
	city: string;
	streetAddress: string;
}

const AccountsService = {
	create: async (account: CreateAccountRequest): Promise<Result<AccountResponse>> => {
		const response = await rp({
			method: 'POST',
			uri: serverUrl + '/accounts/',
			json: true,
			body: account,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	update: async (account: UpdateAccountRequest): Promise<Result<AccountResponse>> => {
		const response = await rp({
			method: 'POST',
			uri: serverUrl + '/accounts/update',
			json: true,
			body: account,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	getAccountCreationForm: async (): Promise<AccountFieldGroup[]> => {
		const response = await rp({
			method: 'GET',
			uri: serverUrl + '/accounts/account/form/create',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	getAccountUpdateForm: async (): Promise<AccountFieldGroup[]> => {
		const response = await rp({
			method: 'GET',
			uri: serverUrl + '/accounts/account/form/update',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	get: async (): Promise<Account> => {
		const response = await rp({
			method: 'GET',
			uri: serverUrl + '/accounts/details',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response ? response : null;
	},

	createAch: async (account: AchRelationship): Promise<Result<AchRelationship>> => {
		const response = await rp({
			method: 'POST',
			uri: serverUrl + '/accounts/ach',
			json: true,
			body: account,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	createBank: async (account: BankData): Promise<Result<BankData>> => {
		const response = await rp({
			method: 'POST',
			uri: serverUrl + '/accounts/bank',
			json: true,
			body: account,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	getAch: async (): Promise<AchRelationship> => {
		const response = await rp({
			method: 'GET',
			uri: serverUrl + '/accounts/ach',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	getBank: async (): Promise<BankData> => {
		const response = await rp({
			method: 'GET',
			uri: serverUrl + '/accounts/bank',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	deleteAch: async (): Promise<AchRelationship> => {
		const response = await rp({
			method: 'DELETE',
			uri: serverUrl + '/accounts/ach',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	deleteBank: async (): Promise<BankData> => {
		const response = await rp({
			method: 'DELETE',
			uri: serverUrl + '/accounts/bank',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	getBankCreationForm: async (): Promise<AccountFieldGroup[]> => {
		const response = await rp({
			method: 'GET',
			uri: serverUrl + '/accounts/bank/form/create',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},

	getAchCreationForm: async (): Promise<AccountFieldGroup[]> => {
		const response = await rp({
			method: 'GET',
			uri: serverUrl + '/accounts/ach/form/create',
			json: true,
			headers: await AuthenticationService.getAuthHeader()
		});
		return response;
	},
}

export default AccountsService;