import * as React from "react";

const SvgLogo: React.FunctionComponent<{}> = props =>
	<svg width="188" height="41" viewBox="0 0 188 41" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M60.3167 26.7565C58.7612 27.9424 56.859 28.5839 54.9027 28.5823C51.1887 28.5823 47.4451 26.5935 47.4451 22.0985C47.4451 17.6028 51.1887 15.551 54.8708 15.551C56.6273 15.5532 58.3481 16.0434 59.8416 16.9669L61.9493 14.5432C60.0303 13.1909 57.543 12.4355 54.8669 12.4355C49.5483 12.4355 44.1304 15.7403 44.1304 22.0348C44.1304 28.4248 49.5126 31.7614 54.8334 31.7614C57.6027 31.7614 60.1833 30.8787 62.1022 29.3059L60.3167 26.7565Z" fill="var(--color-main)" />
		<path d="M73.0013 13.0645V31.1569H76.0537V13.0645H73.0013Z" fill="var(--color-main)" />
		<path d="M102.682 26.7565C101.126 27.9424 99.2235 28.5839 97.2677 28.5823C93.5538 28.5823 89.808 26.5935 89.808 22.0985C89.808 17.6028 93.522 15.551 97.2359 15.551C98.9907 15.561 100.71 16.0507 102.207 16.9669L104.316 14.5432C102.395 13.1909 99.9103 12.4355 97.2337 12.4355C91.8838 12.4355 86.4977 15.7403 86.4977 22.0348C86.4977 28.4248 91.8799 31.7614 97.1979 31.7614C99.9678 31.7614 102.517 30.8787 104.469 29.3059L102.682 26.7565Z" fill="var(--color-main)" />
		<path d="M123.926 13.0645H121.659L120.526 15.142L112.313 31.1631H115.585C117.971 29.3379 121.816 28.2363 125.847 28.2363H128.3L129.749 31.1631H133.147L123.926 13.0645ZM125.815 25.3398C122.133 25.3398 119.866 26.1348 118.576 26.7574L117.757 27.2045L122.668 17.1341L126.815 25.3398H125.815Z" fill="var(--color-main)" />
		<path d="M178.564 13.0645H176.3L175.166 15.142L166.951 31.1631H170.223C172.61 29.3379 176.457 28.2363 180.485 28.2363H182.908L184.388 31.1631H187.788L178.564 13.0645ZM180.453 25.3398C176.771 25.3398 174.472 26.1348 173.214 26.7574L172.365 27.2045L177.274 17.1341L181.461 25.3476L180.453 25.3398Z" fill="var(--color-main)" />
		<path d="M150.554 13.0645H143.251V31.1569H150.554C156.121 31.1569 159.712 27.5368 159.712 21.9658C159.712 16.3943 156.282 13.0645 150.554 13.0645ZM150.554 28.0476H146.524V16.1497H150.554C154.393 16.1497 156.533 18.2574 156.533 22.035C156.533 25.8428 154.361 28.0476 150.554 28.0476Z" fill="var(--color-main)" />
		<path d="M20.6443 6.47997C19.5232 5.15284 18.3039 3.91168 16.9957 2.76823C16.2224 2.09043 15.467 1.48967 14.7512 0.960938C14.0371 1.48799 13.284 2.08651 12.5124 2.76431C11.2024 3.90943 9.98087 5.15116 8.85797 6.47997C3.99877 12.2463 -1.28191 22.4955 1.14568 38.6757L1.49363 41L3.35658 39.5684C7.40326 36.2956 10.9459 32.4448 13.8702 28.1401C14.1767 27.6806 14.4665 27.2211 14.7512 26.7622C15.0332 27.2245 15.3308 27.6885 15.644 28.1541C18.564 32.4537 22.1027 36.2989 26.1455 39.5662L28.0086 40.9977L28.3565 38.6735C30.788 22.4955 25.5034 12.2363 20.6443 6.47997ZM14.7747 20.658C12.8088 15.8129 12.1903 10.3352 14.7512 4.81393C17.0577 9.74504 17.0616 15.0497 14.7747 20.658ZM11.6038 26.5472C8.86395 30.6671 5.5834 33.9316 3.55937 35.7545C2.42608 24.9231 4.79605 15.833 10.6176 8.69316C9.71689 13.5483 10.5818 18.6899 13.1902 23.941C12.7134 24.8053 12.1843 25.674 11.6038 26.5472ZM25.9428 35.7545C23.5192 33.5559 19.282 29.2774 16.328 23.9108C18.9242 18.6737 19.7812 13.5405 18.8845 8.69708C24.7062 15.8347 27.074 24.9231 25.9428 35.7545Z" fill="var(--color-main)" />
	</svg>



export default SvgLogo;