import * as React from "react";
import { ChartCanvas, Chart, series } from "react-stockcharts";
import { LineSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { discontinuousTimeScaleProviderBuilder } from "react-stockcharts/lib/scale";
import { first, last } from "react-stockcharts/lib/utils";
import { fitWidth } from "react-stockcharts/lib/helper";
import { CrossHairCursor, EdgeIndicator, CurrentCoordinate, MouseCoordinateX, MouseCoordinateY } from "react-stockcharts/lib/coordinates";
import { TimeValue } from "/src/services/strategies";
import { ThemeContext } from "/src/components/ThemeProvider";

class DemoResultChart extends React.Component<{ type: any, width: any, height: any, ratio: any, data: TimeValue[], color: string }> {
	render() {

		const { type, width, ratio, height } = this.props;
		const xScaleProvider = discontinuousTimeScaleProviderBuilder()
			.inputDateAccessor(d => new Date(d.time));
		const {
			data,
			xScale,
			xAccessor,
			displayXAccessor,
		} = xScaleProvider(this.props.data);

		const start = xAccessor(last(data));
		const end = xAccessor(first(data));

		const xExtents = [start, end];

		const minmax = this.props.data.reduce((a, b) => { return { min: Math.min(a.min, b.value / 1000), max: Math.max(a.max, b.value / 1000) } }, { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER });
		const diff = (minmax.max - minmax.min);
		const padding = diff !== 0 ? diff * 0.05 : 1;

		return <ThemeContext.Consumer>
			{(theme) => <div style={{ height: height }}>
				<ChartCanvas metaId={1} ratio={ratio} width={width} height={height}
					type="svg"
					zoomEvent={false}
					useCrossHairStyleCursor={false}
					margin={{ left: 0, right: 60, top: 0, bottom: 20 }}
					seriesName="MSFT"
					xScale={xScale}
					xAccessor={xAccessor}
					displayXAccessor={displayXAccessor}
					xExtents={xExtents}
					disableInteraction={true}
					data={data}>
					<Chart id={0} yExtents={d => d.value / 1000} padding={2}>
						<XAxis
							zoomEnabled={false}
							axisAt="bottom"
							orient="bottom"
							fontFamily="Open Sans"
							fontSize={13}
							ticks={5} innerTickSize={-1000} tickStrokeOpacity={0.1}
							stroke={theme.cssVar('--bg-main')}
							tickStroke={theme.cssVar('--color-alt')} />
						<YAxis
							zoomEnabled={false}
							axisAt="right"
							orient="right"
							// tickInterval={5}
							// tickValues={[40, 60]}
							tickFormat={(x) => x + "\u2009%"}
							ticks={5} innerTickSize={-1000} tickStrokeOpacity={0.1}
							fontFamily="Open Sans"
							fontSize={13}
							stroke={theme.cssVar('--bg-main')}
							tickStroke={theme.cssVar('--color-alt')}
						/>
						<LineSeries
							yAccessor={d => d.value / 1000}
							strokeWidth={3}
							stroke={theme.cssVar('--color-main')}
						/>
					</Chart>
				</ChartCanvas>
			</div>}
		</ThemeContext.Consumer>
	}
}

const DemoResultChartSized = fitWidth(DemoResultChart);

export default DemoResultChartSized;