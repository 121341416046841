import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import AuthenticationService from '/src/services/authentication';
import { niceNumber, niceSignedNumber } from '/src/utils/numbers';
import UsersService, { UserModel } from '/src/services/users'
import ActivitiesNotifications from './ActivitiesNotifications'
import tab_strategies_white from '/src/assets/tab_strategies_white.svg';
import tab_wallet_white from '/src/assets/tab_wallet_white.svg';
import tab_profile_white from '/src/assets/tab_profile_white.svg';
import tab_strategies_inactive from '/src/assets/tab_strategies_inactive.svg';
import tab_wallet_inactive from '/src/assets/tab_wallet_inactive.svg';
import tab_profile_inactive from '/src/assets/tab_profile_inactive.svg';
import cicadatext from '/src/assets/cicadatext.svg';
import ThemeSwitch from "./ThemeSwitch";

interface Props {
	tab: number,
	history: any,
	intl: any
}

interface State {
}

class DesktopNavigationBar extends React.Component<Props, State>  {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const props = this.props;
		return <Column crossAxis="center" className="bg-panel white" style={{ width: 133, position: "fixed", left: 0, height: "100%", zIndex: 10000 }}>
			<img src={cicadatext} alt="cicada" style={{ marginTop: 28, width: 100, height: 21 }} />

			<Row style={{ marginTop: 120 }}>
				<Link replace to={{ pathname: "/dashboard", state: undefined }}>
					<Column mainAxis="center" crossAxis="center" className={"font14 segoe tab pointer anim " + (props.tab === 0 ? " " : "inactivetab")}
						style={{ height: 90, width: 90 }}>
						<img src={props.tab === 0 ? tab_strategies_white : tab_strategies_inactive} style={{ width: 38, height: 38 }} />
						<FormattedMessage id="home.tab.strategies" />
					</Column>
				</Link>
			</Row>
			{!AuthenticationService.isGuest() && <Row style={{ marginTop: 20 }}>
				<Link replace to={{ pathname: "/wallet/", state: undefined }}>
					<Column mainAxis="center" crossAxis="center" className={"font14 segoe tab pointer anim " + (props.tab === 1 ? " " : "inactivetab")}
						style={{ height: 90, width: 90 }}>
						<img src={props.tab === 1 ? tab_wallet_white : tab_wallet_inactive} style={{ width: 38, height: 38 }} />
						<FormattedMessage id="home.tab.wallet" />
					</Column>
				</Link>
			</Row>}
			{!AuthenticationService.isGuest() && <Row style={{ marginTop: 20 }}>
				<Link replace to={{ pathname: "/profile/general", state: undefined }}>
					<Column mainAxis="center" crossAxis="center" className={"font14 segoe tab pointer anim " + (props.tab === 2 ? " " : "inactivetab")}
						style={{ height: 90, width: 90 }}>
						<img src={props.tab === 2 ? tab_profile_white : tab_profile_inactive} style={{ width: 38, height: 38 }} />
						<FormattedMessage id="home.tab.profile" />
					</Column>
				</Link>
			</Row>}
			{AuthenticationService.isGuest() && <Row style={{ marginTop: 20 }}>
				<Link replace to={{ pathname: "/sign-up", state: undefined }}>
					<Column mainAxis="center" crossAxis="center" className={"font14 segoe tab pointer anim " + (props.tab === 3 ? " " : "inactivetab")}
						style={{ height: 90, width: 90 }}>
						<img src={props.tab === 3 ? tab_profile_white : tab_profile_inactive} style={{ width: 38, height: 38 }} />
						<FormattedMessage id="home.tab.sign_up" />
					</Column>
				</Link>
			</Row>}

			<div className="flex1"></div>
			<ThemeSwitch />
		</Column>
	}
}

export default injectIntl(DesktopNavigationBar);