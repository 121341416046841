import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Column, isDesktop, Row } from '/src/components/Layout'
import { niceNumber } from "/src/utils/numbers";
import cicadatext from '/src/assets/cicadatext.svg';

interface Props {
	balance: number,
	invested: number,
	hasAccount?: boolean
}

const DashboardHeader: React.FunctionComponent<Props> = (props) =>
	isDesktop
		? <Row className="backwhite" style={{ height: 76, borderBottom: "1px solid #505865" }}>
			{props.hasAccount === undefined ? null :
				props.hasAccount === false ? <Row mainAxis="flex-end" className="flex1 font13 segoe mright50" crossAxis="center">
					<Link to="/create-account">
						<Row className="bold mright5 bc2 pointer" style={{ padding: "5px 10px", borderRadius: 8 }} >OPEN AN ACCOUNT</Row>
					</Link>
					<Row className="font12">TO INVEST IN STRATEGIES</Row>
				</Row> : <Row mainAxis="center" className="flex1"><Row mainAxis="space-between" style={{ padding: "0px 10px", marginTop: 13, maxWidth: 500, flex: 1 }}>
					<Column crossAxis="flex-end" style={{ minWidth: 120 }}>
						<div className="font12 segoe"><FormattedMessage id="dashboard.freeFunds" /></div>
						<Row crossAxis="baseline" style={{ marginTop: 3 }}>
							<div className="font22 semibold">{niceNumber(props.balance, 2)}</div>
							<div className="font13 left5 semibold">USD</div>
						</Row>
					</Column>
					<Column crossAxis="flex-end" style={{ minWidth: 120 }}>
						<div className="font12 segoe"><FormattedMessage id="dashboard.invested" /></div>
						<Row crossAxis="baseline" style={{ marginTop: 3 }}>
							<div className="font22 semibold">{niceNumber(props.invested, 2)}</div>
							<div className="font13 left5 semibold">USD</div>
						</Row>
					</Column>
				</Row></Row>
			}
		</Row>
		: <Column className="white bg-muted" style={{ height: 120 }}>
			<Row mainAxis="center" style={{ marginTop: 17 }}>
				<img src={cicadatext} alt="cicada" style={{ width: 116, height: 25 }} />
			</Row>
			{props.hasAccount === undefined ? null :
				props.hasAccount === false ?
					<Row mainAxis="center" className="flex1 font12 segoe" crossAxis="center">
						<Link to="/create-account">
							<Row className="bold mright5 bc16 pointer" style={{ padding: "5px 10px", borderRadius: 8 }} >OPEN AN ACCOUNT</Row>
						</Link>
						<Row className="font12">TO INVEST IN STRATEGIES</Row>
					</Row>
					: <Row mainAxis="space-between" style={{ marginTop: 11, marginLeft: 24, marginRight: 24 }}>
						<Column>
							<div className="font12 segoe"><FormattedMessage id="dashboard.freeFunds" /></div>
							<Row crossAxis="baseline" style={{ marginTop: 5 }}>
								<div className="font22 semibold">{niceNumber(props.balance, 2)}</div>
								<div className="font13 left5 semibold">USD</div>
							</Row>
						</Column>
						<Column crossAxis="flex-end">
							<div className="font12 segoe"><FormattedMessage id="dashboard.invested" /></div>
							<Row crossAxis="baseline" style={{ marginTop: 5 }}>
								<div className="font22 semibold">{niceNumber(props.invested, 2)}</div>
								<div className="font13 left5 semibold">USD</div>
							</Row>
						</Column>
					</Row>}
		</Column>

export default DashboardHeader;