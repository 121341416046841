import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import { Link, withRouter } from 'react-router-dom'
import PageContainer from '../components/PageContainer'
import { FormattedMessage, FormattedDate } from 'react-intl';
import AccountsService, { AccountFieldGroup, AccountFieldType } from "/src/services/accounts";
import { initField, ValidatedField, validateGroups, extractFormValues } from "./profile/LegalSections/AllLegalData";
import { Slide, toast, ToastContainer } from "react-toastify/dist";
import LegalGeneralEditor from "./profile/LegalSections/LegalGeneralEditor";
import Loader from "/src/components/Loader";
import Button from "/src/components/Button";

interface Props {
	history: any
}

const CreateAccountPage: React.FunctionComponent<Props> = (props) => {
	document.title = "Open a brokerage account " + String.fromCharCode(183) + " Cicada";

	const [values, setValues] = React.useState(undefined);
	const [created, setCreated] = React.useState<boolean>(false);
	const [fieldGroups, setFieldGroups] = React.useState<AccountFieldGroup[]>(undefined);
	const groupRefs = React.useRef([]);

	React.useEffect(() => {
		async function load() {
			const fields = await AccountsService.getAccountCreationForm();
			setFieldGroups(fields)
			if (values === undefined) {
				const newValues = validateGroups(fields, {});
				setValues(newValues);
			}
		}
		if (fieldGroups === undefined) {
			load();
		}
	}, []);

	const getFirstFieldWithError = (values): string => {
		for (const a in values) {
			if (values[a].error !== undefined && values[a].error !== false) {
				return a;
			}
		}
		return null;
	}

	const copyWithErrors = (data: any): object => {
		const result = {};
		for (const f in data) {
			result[f] = { value: data[f].value, error: data[f].error, hideError: false };
		}
		return result;
	}

	const showAllErrors = () => {
		setValues(copyWithErrors(values))
	}


	return <PageContainer maxWidth={800} history={props.history} tab={undefined} hideHeader={true}>
		<Row mainAxis="center">
			{
				created ?
					<Column crossAxis="center" style={{ marginTop: 100, padding: "0px 4px", maxWidth: 800, flex: 1, position: "relative", minWidth: 350 }}>
						<Column className="font18">
							<Row>Your brokerage account has been created.</Row>
							<Row>Connect your bank account to make your first deposit.</Row>
						</Column>
						<Button className="mtop40 font14" label="Connect a bank account" link="/profile/banks/" />
					</Column> :
					<Column style={{ padding: "0px 4px", maxWidth: 800, flex: 1, position: "relative", minWidth: 350 }}>

						<Row mainAxis="center" className="font22 mtop50 bold">OPEN A BROKERAGE ACCOUNT</Row>

						{(fieldGroups && values) ? fieldGroups.map((group, i) => {
							return [<a ref={el => groupRefs.current[i] = el}></a>,
							<LegalGeneralEditor key={group.label} protectNonEditable={false} group={group} values={values} onChange={newValues => {
								const merged = { ...values, ...newValues };
								validateGroups(fieldGroups, merged);
								//console.log({ afterValidation: merged })
								setValues(merged);
							}} />]
						})
							:
							<Loader className="mtop50 show-after-delay" />
						}

						<Row mainAxis="center">
							<Button label="Submit" className="mtop40" onClick={async e => {
								const errorSection = getFirstFieldWithError(values);
								if (errorSection !== null) {
									const errorIndex = fieldGroups.findIndex(g => g.fields.some(f => f.name === errorSection))
									if (errorIndex > -1) {
										groupRefs.current[errorIndex].scrollIntoView({ behavior: 'smooth' })
									}
									showAllErrors();
								}
								else {
									var legalData = extractFormValues(values);
									var creationResult = await AccountsService.create({
										legalData: legalData
									});
									if (creationResult.error) {
										toast(creationResult.error, { theme: "dark" })
									}
									else {
										setCreated(true);
									}
								}
							}} />
						</Row>

						<div style={{ height: 200 }}></div>

						<ToastContainer
							limit={4}
							transition={Slide}
							position="bottom-center"
							autoClose={5000}
							hideProgressBar
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover />

					</Column>}
		</Row >
	</PageContainer>
}


export default withRouter(CreateAccountPage);