import * as React from "react";
import { FormattedMessage, FormattedDate } from 'react-intl';

export function shortNumber(num: number): string {
	const lookup = [
		{ value: 1e6, symbol: "M" },
		{ value: 1e3, symbol: "k" },
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	var item = lookup.find(function (item) {
		return num >= item.value;
	});
	return item ? (num / item.value).toPrecision(3).replace(rx, "$1") + item.symbol : (Math.round(num * 1000) / 1000).toString();
}

export function niceNumber(num: number, decimals: number): string {
	if (num === undefined) {
		return "---";
	}
	return num.toLocaleString("en", { minimumFractionDigits: decimals, maximumFractionDigits: decimals }).replace(/,/g, '\u200A'); //Thin space
}

export function niceSignedNumber(num: number, decimals: number): string {
	if (num === undefined) {
		return "---";
	}
	return (num > -0.00000001 ? "+" : "") + num.toLocaleString("en", { minimumFractionDigits: decimals, maximumFractionDigits: decimals }).replace(/,/g, '\u200A'); //Thin space
}

export function signedPercent(num: number, decimals: number): string {
	if (num === undefined)
		return "---";
	const result = (num - 1) * 100;
	if (result <= 0)
		return result.toFixed(decimals) + '\u200A' + "%";
	return "+" + result.toFixed(decimals) + '\u200A' + "%";
}

export const renderMonth = (time: string) => {
	const date = new Date(time);
	const thisYear = new Date().getFullYear();
	return <FormattedDate value={new Date(thisYear, date.getUTCMonth(), 1)} month="long" />;
}

function getPrecisionForDifference(string1: string, string2: string) {
	if (string1 == string2) {
		return 0;
	}
	let longer, shorter;
	if (string1.length >= string2.length) {
		longer = string1;
		shorter = string2;
	}
	else {
		longer = string2;
		shorter = string1;
	}
	for (let i = 0; i < longer.length; i++) {
		if (i >= shorter.length || longer[i] != shorter[i]) {
			return i + 1;
		}
	}
	return longer.length;
}

function getAfterDot(value: number) {
	const as_string = "" + value;
	const index = as_string.indexOf(".");
	if (index >= 0) {
		return as_string.substring(index + 1);
	}
	return "";
}

export function guessValuesPrecision(values: number[]) {
	const strings = values.map(v => getAfterDot(+v.toFixed(8)));

	const max_length = Math.max(0, ...strings.map(s => s.length));
	if (max_length <= 5) {
		//console.log({ strings, max_length })
		return max_length;
	}

	const precisions = strings.map((v, i) => {
		if (i == strings.length - 1) {
			return 0;
		}
		return getPrecisionForDifference(v, strings[strings.length - 1]);
	})
	const result = Math.max(...precisions);
	//console.log({ strings, max_length, precisions, result })
	return result;
}