import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import Positions from './Positions'

import TradingService, { AllocationStats, Position, PositionStatus } from "/src/services/trading"
import { StrategyState } from "/src/services/strategies";
import { PanelButtons } from "../EditorModels";
import Button from "/src/components/Button";
import test_tube from '/src/assets/testtube.svg';

interface Props {
	type: PanelButtons,
	positions: Position[],
	stats: AllocationStats,
	hasTradeRules: boolean,
	strategyState: StrategyState,
	onInstrumentAdding: () => void,
	onIndicatorAdding: () => void,
	onTradeRuleAdding: () => void,
	onBacktest: () => void,
	onTradeSelected: (trade: Position) => void,
	onInvesting: () => void,
	isInvested: boolean,
	isOwnStrategy: boolean,
	hasInstruments: boolean,
	onStopInvesting: () => void,
	onDelete: () => void,
	morePositions: boolean
}

const PositionsPanel: React.FunctionComponent<Props> = (props) => {
	return <Column crossAxis="center" style={{ padding: 10 }}>
		{
			props.strategyState === StrategyState.Backtested || props.strategyState === StrategyState.Ready
				? props.stats && <Positions morePositions={props.morePositions} positions={props.positions} stats={props.stats} onTradeSelected={props.onTradeSelected} isDemoDeals={!props.isInvested} />
				: props.hasTradeRules
					? <Column className="mtop50">
						<Row>Run test to see demo positions</Row>
						<Button className="mtop10" label="RUN TEST" icon={test_tube} onClick={props.onBacktest}/>
					</Column>
					: <div className="mtop50">Add a trade rule to be able to test the strategy</div>
		}
	</Column >
}


export default PositionsPanel;