import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import Particles from '/src/components/LandingPage/Particles'
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom'
import { SplashScreen } from '@capacitor/splash-screen';
import AuthenticationService from '/src/services/authentication'
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import landing_logo from '/src/assets/landing_logo.svg';
import landing_logo_light from '/src/assets/landing_logo_light.svg';
import background from '/src/assets/background.jpg';
import background2 from '/src/assets/background2.jpg';
import showcase from '/src/assets/showcase.png';
import showcase2 from '/src/assets/showcase2.png';
import showcase_mobile from '/src/assets/showcase_mobile.png';
import landing_manage_mobile from '/src/assets/landing_manage_mobile.png';
import white_back_mobile from '/src/assets/white_back_mobile.svg';
import google_play_badge from '/src/assets/google_play_badge.svg';
import appstore_badge from '/src/assets/appstore_badge.svg';
import scroll from '/src/assets/scroll.svg';
import LandingFeature from "./LandingFeature";
import LandingFooter from "/src/components/LandingFooter";
import LandingContainer from "./LandingContainer";
import Api from "/generated/cicada-client";
import { serverUrl } from '/src/services/common'

const api = new Api(serverUrl, undefined);

interface LandingPageProps {
	history: any;
	intl: any
}
interface LandingPageState {
}

class LandingPage extends React.Component<LandingPageProps, LandingPageState>  {
	constructor(props) {
		super(props);
		this.state = {};
	}

	async componentDidMount() {
		setTimeout(async () => { await SplashScreen.hide(); }, 100);

		if (!AuthenticationService.hasToken()) {
			this.props.history.replace("/dashboard");
		}

		const queryParams = new URLSearchParams(location.search)
		let source = queryParams.get('s');
		if (source == null && document.referrer && document.referrer.length > 0) {
			source = document.referrer;
		}

		/*if (queryParams.has('source')) {
			queryParams.delete('source')
			this.props.history.replace({
				search: queryParams.toString(),
			})
		}*/
		api.analytics.logAction({ actionName: "VisitLandingPage", deviceId: AuthenticationService.getDeviceId(), deviceSource: source });
	}

	tradersHeader: HTMLDivElement
	investorsHeader: HTMLDivElement


	onEnter = async () => {
		try {
			const response = await api.auth.signInGuest({ deviceId: AuthenticationService.getDeviceId() })
			if (response != undefined) {
				AuthenticationService.storeToken(response);
				if (response.jwt.roles && response.jwt.roles.includes("Guest")) {
					this.props.history.replace("/dashboard");
				}
			}
		}
		catch (e) {
			//this.setState({ isEmailCorrect: false })
		}
	}

	render() {
		document.title = "Cicada. Stick to your strategy.";

		return isDesktop ?
			<LandingContainer>
				<div className="mtop18 bold font50"><FormattedMessage id="landing.description" /></div>
				<div className="mtop25 font18 segoe" style={{ lineHeight: "158.7%" }}><FormattedMessage id="landing.longdescription" /></div>

				<Row crossAxis="center" className="mtop32">
					<Row className="pointer nowrap semibold font14 pleft18 pright18 bg-inverted color-inverted"
						onClick={this.onEnter}
						crossAxis="center" style={{ height: 40, borderRadius: 40 }}>
						<FormattedMessage id="landing.open_web_platform" />
					</Row>
				</Row>
			</LandingContainer>
			:
			<LandingContainer>
				<div className="mtop18 bold font35"><FormattedMessage id="landing.description" /></div>
				<div className="mtop25 font18 segoe" style={{ lineHeight: "158.7%" }}><FormattedMessage id="landing.longdescription" /></div>

				<Row crossAxis="center" className="mtop32">
					<Row className="pointer nowrap semibold font14 pleft18 pright18 bg-inverted color-inverted"
						onClick={this.onEnter} crossAxis="center" style={{ height: 40, borderRadius: 40 }}>
						<FormattedMessage id="landing.open_web_platform" />
					</Row>
				</Row>

				<Row className="mtop70" mainAxis="center">
					<img src={showcase} style={{ maxWidth: "80%" }} />
				</Row>
			</LandingContainer>
	}
}

export default withRouter(injectIntl(LandingPage));