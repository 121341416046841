import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import Select from 'react-select';
import { shallowEqual } from "/src/utils/tools";
import help_black from '/src/assets/help_black.svg';

export interface KeyValue {
	value: any,
	label: string
}

interface Props {
	options: KeyValue[],
	value?: any,
	onValueChange: (value: any) => void,
	label: string,
	isMulti?: boolean,
	className?: string,
	tip?: string,
	disabled?: boolean
}

const customStyles = {
	option: (provided, state) => {
		return ({
			...provided,
			color: 'black',
			fontWeight: state.isSelected ? 600 : 400,
			backgroundColor: state.isFocused ? '#F4F4F4' : 'white'
		})
	},
	singleValue: (provided, state) => ({
		...provided,
		color: state.isDisabled ? '#6E7684' : '#505865'
	}),
	control: (provided, state) => ({
		...provided,
		'&:hover': { borderColor: '#dddddd' },
		boxShadow: undefined,
		borderColor: '#dddddd',
		borderRadius: '0px',
		fontSize: '15px',
		fontFamily:"'Open Sans',sans-serif",
		fontWeight: 600,
		minHeight: 37,
		height: 37,
		backgroundColor: state.isDisabled ? '#F9FAFB' : 'white',
	})
}

const SelectInput: React.FunctionComponent<Props> = ({ value, onValueChange, label, options, isMulti, className, tip, disabled }) => {
	const def = Array.isArray(value.value)
		? options.filter(o => value.value.includes(o.value))
		: options.find(o => o.value === value.value || (typeof value.value === 'object' && shallowEqual(o.value, value.value)))

	return <Column className={"flex1 " + className}>
		<Row>
			<div className="font14" title={tip}>{label}</div>
			{tip && <img className="mleft5" style={{ width: 16 }} src={help_black} alt="help_black" title={tip} />}
		</Row>
		<Select
			isDisabled={disabled}
			styles={customStyles}
			value={def}
			isSearchable={true}
			name="color"
			isMulti={isMulti}
			options={options}
			onChange={v => {
				onValueChange(Array.isArray(v) ? v.map(v => v.value) : v.value);
			}}
		/>
		{value.error && !value.hideError && <Row mainAxis="flex-end" className="font11 italic semibold error-blink">{value.error}</Row>}
	</Column>
}


export default SelectInput;